import React, { Component } from 'react';
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  getVendorLabel,
  getPalletImageElement,
} from "./itemListingUtilities";
import { MaskPrice } from '../../common/MaskPrice';
import { getEventRedirectUrl } from './eventRedirectUtilities';
import utilities from "../../../utils/utilities";
import classnames from 'classnames';
import CollapsiblePalletTable from './CollapsiblePalletTable';
import _ from 'lodash';
import history from '../../../history';
import { getShipWeekQty, getPalletItems, getDropShipPalletItems, getDropShipWeekQty } from './palletUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import MultistoreToCartModal from '../../Cart/MultistoreToCartModal';
 class PalletGridView extends Component {

  constructor(props) {
    super(props);
    const { flowType, product } = props;
    this.state = {
      expandDescription: false,
      showSpec: false,
      showSpecItem: '',
      pallet: product,
      palletItems: [],
      palletItemsLoading: false,
      flowType,
      totalItemLength: 0,
      isGridView: true
    };
    this.palletPage = 1;
    this.getPalletItems = getPalletItems.bind(this);
    this.getDropShipPalletItems = getDropShipPalletItems.bind(this);
  }

  getItemField = (item, index, product,flowType) => {
    const className = item.className ? item.className : "First_level_details";
    const field =
      <Grid columns={2}>
        <Grid.Column className={item.label ==='Max Qty Per Ship Window:' ? 'max_red_color': ''}>
          <span className={item.labelClass
            ? item.labelClass : 'dc_card_name'}>
            {item.label}
          </span>
        </Grid.Column>
        <Grid.Column className={item.label ==='Max Qty Per Ship Window:' ? 'max_red_color': ''}>
          <b className={className}>
          {item.val}
          </b>
        </Grid.Column>
      </Grid>
    return field;
  }

  toggleDiv = e => {
    const val = e.target.checked
    if(val){
      document.querySelectorAll('.showSpec').forEach(cb => {
        cb.checked = false;
      });
      e.target.checked = true
    }
    this.setState({ showSpec: val})
    if (_.isEmpty(this.state.palletItems)) {
      if(this.state.flowType === "5"){
        this.getDropShipPalletItems();
      } else {
        this.getPalletItems();
      }
    }
  };
  getPalletItemListing = (e) => {
    const { eventId, shoppingAreaName, promoName, shoppingAreaId, shoppingAreaMetaId, relayNbr, relayYear, flowType, vendorName, fromReunionHome,allItems } = this.props;
    const { pallet } = this.state;
    this.props.setBackButtonPath();
    const keyParams = {
      eventId: eventId,
      shoppingAreaId: shoppingAreaId,
      shoppingAreaMetaId: shoppingAreaMetaId,
      shoppingAreaName: shoppingAreaName,
      flowType: flowType,
      vendorId: pallet.vendorId,
      vendorName: pallet.vendorName,
      relayNbr: pallet.relayNbr,
      relayYear: pallet.relayYear,
      fromReunionHome,
      promoName,
      palletNumber: pallet.palletNbr,
      pageName: "PalletItemListing"
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr,
        relayYear,
        promoName,
        promoName,
        allItems:allItems,
        palletNumber: pallet.palletNbr,
        shoppingAreaName,
        shoppingAreaId,
        shoppingAreaMetaId,
        flowType: flowType,
        vendorId: pallet.vendorId,
        vendorName: vendorName,
        fromReunionHome
      }
    });
  }

  getDropShipPalletItemListing = (e) => {
    const { eventId, shoppingAreaName, promoName, shoppingAreaId,shoppingAreaMetaId, flowType, vendorName, fromReunionHome } = this.props;
    const { pallet } = this.state;
    this.props.setBackButtonPath();
    const keyParams = {
      eventId: eventId,
      shoppingAreaId,
      shoppingAreaMetaId,
      shoppingAreaName,
      flowType,
      vendorId:pallet.vendorId,
      relayNbr: pallet.relayNbr,
      relayYear: pallet.relayYear,
      fromReunionHome,
      promoName,
      palletNumber: pallet.palletNbr,
      vendorName: pallet.vendorName,
      palletId: pallet.dsPalletId,
      eventSupplierSelectionID: pallet.EventSupplierSelectionID,
      pageName: "DropShipPalletItemListing"
    };
    history.push({
      pathname: getEventRedirectUrl({ keyParams }),
      state: {
        relayNbr: pallet.relayNbr,
        relayYear: pallet.relayYear,
        promoNbr: pallet.promoNbr,
        promoName,
        palletNumber: pallet.palletNbr,
        shoppingAreaName,
        shoppingAreaId,
        shoppingAreaMetaId,
        flowType,
        vendorId: pallet.vendorId,
        vendorName: vendorName ? vendorName : pallet.vendorName,
        fromReunionHome
      }
    });
  }
  
  fetchMoreData = () => {
    this.palletPage++;
    if(this.state.flowType === "5"){
      this.getDropShipPalletItems();
    } else {
      this.getPalletItems();
    } 
  };
  multistoreHandleCarts= (product) => {
    this.setState({
      productSelectedForModal: product,
      isMultistoreModalOpen: true
    });
  };

  closeMultistoreModal=()=>{
    this.props.closeMultistoreModal();
    this.setState({ isMultistoreModalOpen: false });
  }
  render() {
    const {
      cartParams,
      itemParams,
      itemListParams,
      imgStyle,
      product,
      flowType,
      relayNbr
    } = this.props;
    const { pallet, showSpec, palletItems, palletItemsLoading, totalItemLength, showSpecItem, isGridView } = this.state;
    let msCheck = utilities.msExist(this.props.multiStoreDetails); 
    const itemlistingHandler = this.state.flowType === "5" ?this.getDropShipPalletItemListing:this.getPalletItemListing;
    const isPDPlinkReqd = !((flowType==="4" || flowType==="5") && product.palletNbr==="0");
    const imageId = flowType ==="5" ? product.imageId : product.palletNbr;
    return (
      <>
        <MultistoreToCartModal
        isMultistoreModalOpen={this.state.isMultistoreModalOpen}
        shoppingAreaName={this.props.shoppingAreaName}
        shoppingAreaMetaId = {this.props.shoppingAreaMetaId}
        quantitiesMs={this.props.quantitiesMs}
        indexCheck={this.props.indexCheck}
        quantitiesMsDrop={this.props.quantitiesMsDrop}
        flowType={this.props.flowType}
        assortmentId={this.props.assortmentId}
        closeMultistoreModal={this.closeMultistoreModal}
        multiStoreCartavailable={this.props.multiStoreCartavailable}
        product={this.state.productSelectedForModal}
        relayNbr={relayNbr}
        />
        <div className="column dc-expand">
          <div className=' ui segment card_item' id='grid_segment_assort'>
            <div className="card_img">
              {getPalletImageElement(imageId, itemlistingHandler, imgStyle, isPDPlinkReqd)}
            </div>
            <br />
            <p className="t-center vendor-span black_color  no_decoration">
              {product.vendorName}
            </p>
            <p className="t-center product-name-span vendor-span Cursor"
              onClick={itemlistingHandler}>
              {product.shortDesc}
            </p>
            <br />
            <div className="card_wrap" style={{ paddingBottom: '0.2rem' }}>
              <div className="card_fields gridbold">
                {itemParams.fieldSet.map((item, index) => {
                  return this.getItemField(item, index, itemParams, this.state.flowType);
                })}
              </div>
              <div>
                {this.state.flowType === "5" ?
                  <div className='shipWeekGrid'>
                    <Grid.Row>
                      <span className='dc_ship_weeks'>Ship Months</span>
                    </Grid.Row>
                    <br />
                    {getDropShipWeekQty(product.shipDate1, this.props.qtyList?.[product.shipDate1], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship', true)}
                    {getDropShipWeekQty(product.shipDate2, this.props.qtyList?.[product.shipDate2], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship', true)}
                    {getDropShipWeekQty(product.shipDate3, this.props.qtyList?.[product.shipDate3], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship', true)}
                    {getDropShipWeekQty(product.shipDate4, this.props.qtyList?.[product.shipDate4], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1Dropship', true)}
                  </div>
                  :
                  <>
                    <div className='shipWeekGrid'>
                      <Grid.Row>
                        <span className='dc_ship_weeks'>Ship Weeks</span>
                      </Grid.Row>
                      <br />
                      {getShipWeekQty(product.shipDate1, this.props.quantities[0], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty1', true)}
                      {getShipWeekQty(product.shipDate2, this.props.quantities[1], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty2', true)}
                      {getShipWeekQty(product.shipDate3, this.props.quantities[2], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty3', true)}
                      {getShipWeekQty(product.shipDate4, this.props.quantities[3], product, itemListParams.handleQtyChange, itemParams.itemIndex, 'qty4', true)}
                    </div>

                  </>
                }

              </div>
            </div>
            
            <div className='show_expand'>
              <label className='dc_expand_label'>
                <input type='checkbox' className="showSpec" onChange={this.toggleDiv} />
                <i className="icon"></i>
                {showSpec &&
                <InfiniteScroll
                dataLength={palletItems.length <= totalItemLength ? palletItems.length : 0}
                next={this.fetchMoreData}
                hasMore={palletItems.length < totalItemLength}
                loader={<h4>Loading...</h4>}
                scrollThreshold="14%"
                height="auto"
                className="show_expand"
                >
                    <CollapsiblePalletTable
                      products={palletItems}
                      palletItemsLoading={palletItemsLoading}
                      isGridView={isGridView}
                      shoppingAreaMetaId ={this.props.shoppingAreaMetaId}
                    />
                </InfiniteScroll>
                }
              </label>
              
            </div>
          </div>



          <div className='AddTOCartPalletGrid'>
            {itemListParams.isAddToCartRequired && (
              <div className='grid-bottom'>
                {msCheck && msCheck > 1 ?
                  <button
                    className="ui red button" id="button_gridPallet"
                    onClick={() =>
                      this.multistoreHandleCarts(product)
                    }
                  >
                    Add to Store
                  </button>
                  :
                  <button
                    className="ui red button" id="button_gridPallet"
                    onClick={() => cartParams.addToCart(product.palletNbr, itemParams.itemIndex, null, product.pack, product.maxQtyItemRestriction, product.vendorId, null, product.model, product.maxQtyPerReunion)}
                  >
                    Add to Cart
                  </button>
                }
              </div>
            )}
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const carts = state.cart.orders.allCarts;
  return {
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    rdc: state.SessionReducer.rdc
  };
};

export default connect(mapStateToProps, null)(PalletGridView);
