// menu
export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';
export const SETWPMENU = 'SETWPMENU'
export const SET_BT_MENU = 'SET_BT_MENU'

export const openMenu = () => ({
  type: MENU_OPEN
});

export const closeMenu = () => ({
  type: MENU_CLOSE
});

// search input
export const SEARCH_OPEN = 'SEARCH_OPEN';
export const SEARCH_CLOSE = 'SEARCH_CLOSE';
export const SWITCH_VIEW = 'SWITCH_VIEW';

export const switchView = viewName => ({
  type: SWITCH_VIEW,
  payload: viewName
});

export const openSearch = () => ({
  type: SEARCH_OPEN
});

export const closeSearch = () => ({
  type: SEARCH_CLOSE
});

//focus change
export const FOCUS_CHANGE = 'FOCUS_CHANGE';
 
export const focusChange = ()=>({
  type: FOCUS_CHANGE
})

export const setWpMenu = ((menu)=> {
  return {
   type: SETWPMENU,
   payload: menu
 }
 })
 export const setBussinessToolMenu = ((menu)=> {
  return {
   type: SET_BT_MENU,
   payload: menu
 }
 })