import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CartProductsTable from './CartProductsTable';
import { connect } from 'react-redux';
import history from '../../history';
import { bindActionCreators } from 'redux';
import { placeOrder, deleteCart, removeProduct,updateShipTo,checkoutOrderValidation, getCartInfo, getViewAllCarts, checkout, notifyCarts } from './actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Loader, Checkbox, Grid, Responsive } from 'semantic-ui-react';
import { utilities, shouldHideCostForPreference } from '../../utils/utilities';
import {
  s2CNonRetrofitShippingMethods,
  s2CRetrofitShippingmethods,
  emergencyShippingMethods,
  s2SShippingMethods,
  monthlySpecialShippingmethods,
  s2cRetrofitLimitedQty,
  orderNames,
} from './constants';
import { restUtils } from '../../utils/restUtils';
import { placeOrderMainrameUrl, RESTOCKINGURL, RESTOCKINGORDER, RA_PLACE_ORDER_URL ,RE_PLACE_ORDER_URL, EDIT_ORDER_ITEM_DETAILS, CHECKOUT_ORDER_VALIDATION } from '../../urlConstants';
import { MaskPrice } from '../common/MaskPrice';
import { findTotal, totalCost } from './cartUtils';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class Checkout extends Component {
  constructor(props) {
    super(props);
    const checkedoutCart = props.carts.find(cart => {
      return parseInt(cart.cartId) === parseInt(props.match.params.cartName);
    });
    const {isMultiStore,multiStoreCarts} = props?.location?.state;
    const itemGrouping = _.get(props, 'location.state.itemGrouping', {});
    let cartProducts = [];
    Object.values(itemGrouping[0]).forEach(({final_group})=>{
      cartProducts = [...cartProducts,...final_group];
    })

    const customerAddress = _.get(
      props,
      'location.state.customerAddress',
      null
    );
    this.tierLevels = {
      1: false,
      2: false,
      3: false,
      4: false
    };
    const poNum = _.get(props, 'location.state.poNum', '');
    const forEdit = checkedoutCart?.forEdit;
    const isPONumEditable = utilities.isEmptyOrNullString(checkedoutCart?.editPoNumber);
    const isDOMCart = checkedoutCart?.TypeId===orderNames["DOM"]?.infoType;
    const isOrderPadCart = checkedoutCart?.cartName && checkedoutCart?.cartName.toLowerCase() === 'orderpad';

    this.state = {
      cartName: _.get(props, 'match.params.cartName', ''),
      poNum: poNum && Object.keys(poNum).length > 0 ?
                (isOrderPadCart ? {} : poNum)
                  :!isPONumEditable
                      ?checkedoutCart.editPoNumber
                      :isDOMCart?
                        utilities.replaceAll(checkedoutCart.cartName,' ','')
                        :{},
      isPONumEditable,
      forEdit,
      cartProducts,
      itemGrouping,
      selectedGroup: _.get(props,'location.state.selectedGroup',null),
      cart: checkedoutCart,
      isLoading: false,
      isValidationLoading: false,
      isError: false,
      errorMsg: '',
      shipTo: customerAddress ? customerAddress.name : 'store',
      customerAddress: customerAddress,
      showPoError: false,
      isRestockingTime : false,
      isRestockingOrder : false, 
      PostRestocking : [],
      shipLaters : false,
      shippingMethod: '',
      tstmsg:'',
      centralshipBy: !_.isEmpty(cartProducts) && cartProducts[0].source === "WH",
      disablePlaceorderbtn: this.props.address.viewOrders,
      cartSelected:[],      
      quantites:{},      isMultiStore : isMultiStore,
      multiStoreCarts : multiStoreCarts,
      multiStoreData: [],
      CheckoutValidateState: false,
      checkoutResponseData: this.props?.checkoutResponseData || []
    };
    this.shippingMethods=[];
  }

  getTotalWeight=(products)=>{
    if(!products || products.length===0){
      return 0.00;
    }
    return parseFloat(products.reduce(
        (wt1, { Ext_weight, Qty }) => parseFloat(wt1) +  (utilities.isBinLabel(Qty) ? 0 :parseFloat(Ext_weight)),0)
      ).toFixed(2);
  }

  getSubTotalExtCost=(products)=>{
    let subTotal = 0;
    products.map((item) => {
      const fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4
      ];
      const tieredCost = this.getTieredCost(item);
      subTotal = (parseFloat(subTotal) + parseFloat((item.Qty ? utilities.isBinLabel(item.Qty)? 0 : item.Qty : findTotal(fieldEnum)) * tieredCost)).toFixed(2);
      return item;
  })
    return subTotal;
  }

  componentDidMount() {
   if(this.state.cart && this.state.cart.TypeId !== 4) {
      this.GetRestocking();
      this.PostRestockingOrder();
    }
    const isLoadingAddressFailed = _.get(
      this.props,
      'location.state.isLoadingAddressFailed',
      false
    );
    if (isLoadingAddressFailed) {
      utilities.showToast('Something went wrong. Please try again', true);
    }
    if(this.props.address.viewOrders === true){
      utilities.showToast(`Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.`, true);
    }
  }

  componentWillReceiveProps(nextProps){
           this.setState({
             quantites:this.transformCarts(nextProps?.carts),
             carts:this.transformCarts(nextProps?.carts),
             multiStoreCarts:nextProps?.location?.state?.multiStoreCarts
          })
         if(nextProps?.history?.location?.state?.itemGrouping!==this.props.location?.state?.itemGrouping){
           this.setState({
            itemGrouping:nextProps?.history?.location?.state?.itemGrouping        
          })
         }
         if(nextProps?.checkoutResponseData){
           this.setState({
             checkoutResponseData: nextProps?.checkoutResponseData
         })
         }
    
     }

  transformCarts = (carts) => {
    return carts.reduce((acc, cart) => {
        acc[cart.parentId] = acc[cart.parentId] || []; // Initialize if it doesn't exist
        acc[cart.parentId].push(cart); // Push the cart into the array
        return acc;
    }, {});
  };

 GetRestocking = () => {
    restUtils
      .getDataWithoutToken(RESTOCKINGURL)
      .then(response => {
        this.setState({ isRestockingTime: response.data.Status });
      }).catch(err => {
        console.log(err)
      })
  }

  PostRestockingOrder = () => {
    let RestockingItems = [];
    let i = 0;
    const PostRestocking = [];

    for(let index in this.state.cart.items){
      let ItemList = this.state.cart.items[index];
      PostRestocking[i] = ItemList;
      RestockingItems = Object.keys(ItemList).reduce((obj, key) => {
        if(key ==="item_nbr") {
          obj["itemNbr"] = ItemList["item_nbr"]
        }
        return obj
      }, {})
      PostRestocking[i] = RestockingItems;
      i++;
    }
    const payload = PostRestocking;

    restUtils
      .postDataWithoutToken(RESTOCKINGORDER, payload)
      .then(response => {
        this.setState ({
          isRestockingOrder : response.data.Status
        })
      })
      .catch(err => {
        console.log(err);
      })
  }

  getTierLevel = cartProducts => {
    const tierLevelArr = [];
    cartProducts.forEach(product => {
      const tierLevel = utilities.getTierLevelForQty(product, parseFloat(product.itemQty?product.itemQty:product.Qty));
      tierLevelArr.push(tierLevel)
    })
    return tierLevelArr;
  };

  shipLatersValue = (e) => {    
    const {group} = e.target.dataset;
    let index = group.split(',');
    const {itemGrouping} = this.state;
    itemGrouping[index[1]][index[0]].shipLater = e.target.checked;
    this.setState({itemGrouping});
  }

  handleshippingMethod = (e,index) => {
    const group = e.target.dataset.group;
    let {itemGrouping} = this.state;
    const shipValue = e.target.value;
    itemGrouping[index][group].shipBy = shipValue.split('@')[0];
    itemGrouping[index][group].shipByText = shipValue.split('@')[1];
    this.setState({itemGrouping})
  }

  getTieredCost = product => {
    return utilities.getCostForQtyPlpNew(product, parseFloat(product.itemQty?product.itemQty:product.Qty && product.Qty !== 0 ? product.Qty : product.userQty ));
  };

  toastDelay=(centretoast)=>{
    this.setState({ tstmsg: centretoast });
    setTimeout(() => {
      this.setState({ tstmsg: '' });
    }, 5000);
  }

  placeREOrder = () => {
    let alphanumeric = /[^A-Za-z0-9]/g;
    if (utilities.isEmptyOrNullString(this.state.poNum)) {
      this.setState({ showPoError: true });
      const centretoast = utilities.centreshowToast(`Please enter Purchase Order#`, true);
      this.toastDelay(centretoast)
      return;
    } else if ( this.state.poNum.toLowerCase() === 'stockup') {
      const centretoast = utilities.centreshowToast(`Stockup is reserved word`, true);
      this.toastDelay(centretoast)
      return;
    } else if (this.state.poNum.length > 10 ) {
      this.setState({ showPoError: true });
      const centretoast = utilities.centreshowToast`Purchase Order# must be less than 10 digits`;
      this.toastDelay(centretoast)
      return;
    } else if((alphanumeric).test(this.state.poNum)) {
      this.setState({ showPoError: true });
      const centretoast = utilities.centreshowToast(`PO#: Special characters are not allowed`, true);
      this.toastDelay(centretoast)
      return;
    }
    this.setState({ isLoading: true });
    const { cart, itemGrouping } = this.state;
    const { totalCost } = cart;
    const { storeId, address } = this.props;
    const { rdc } = address;
    const url = RE_PLACE_ORDER_URL;
    let itemVal = [];
    Object.values(itemGrouping).forEach(({ final_group }) => {
      itemVal = final_group.map((item) => {
        return {
          vendorID: item.vendorID,
          itemNum: item.ItemNum,
          userQty: item.Qty,
          promoCost: item.promoCost !== null ? item.promoCost : 0,
          overrideRetail: item.OverRideDetails,
          packType: item.Pack_type,
          packQty: item.Pack,
          source: item.promoNbr
        }
      })
    });

    const payload = {
      poNbr: this.state.poNum,
      user: {
        userID: cart.userId,
        storeId: storeId,
        primaryWarehouseNum: rdc,
        checkDigit: 1,
        molUserName: cart.molUserName,
        cartID: cart.cartId
      }, items: itemVal
    };
    restUtils
      .postData(url, payload)
      .then((response) => {
        if (response) {
          this.setState({ disablePlaceorderbtn: false, isLoading: false });
          if (response.error && (response.error !== null || response.error !== "")) {
            utilities.showToast(response.error, true)
          } else {
            history.push({
              pathname: `/orderConfirmation/${this.state.cartName}`,
              state: {
                cart,
                ...this.state.cart,
                customerAddress: this.state.customerAddress,
                poNum: this.state.poNum,
                orderTotal: totalCost,
                itemGrouping: itemGrouping
              }
            });
          }
        }
      }
      ).catch((err) => {
        console.log(err);
        utilities.showToast(`Failed to Submit order. Please try later`, true);
        this.setState({ disablePlaceorderbtn: false, isLoading: false, tstmsg: '' });
      });
  }
  checkoutValidation = (multiStoreCarts = [], isMultiStore) => {
    const checkDimShippingMethod  = ['UND','URG','USD','ECO'];
      const { cart,itemGrouping} = this.state; 
      let output = {};
      let countData = 0;
      if(!isMultiStore){
        multiStoreCarts = [cart];
      }
      let itemGroupName = [];
      multiStoreCarts.forEach(cart=>{
        itemGrouping.map(itemGroupNames => {
          const order = itemGroupNames[0];
          const products = order.final_group;
          if(products[0].cartId === cart.cartId){
          itemGroupName = [...itemGroupName, itemGroupNames];
          }
        }) 
       })     
      itemGroupName.forEach((innerObject,index) => {
        let arrayIndex = index;
      Object.entries(innerObject).forEach(([key, group],index) => {
          const storeId = group.final_group[0].storeId;
          let shipTo = group.shipTo;
           shipTo = utilities.isEmptyOrNullString(group.shipTo) || group.shipTo === 'store' ? 'store' : shipTo;
      const shippingMethods = this.getShippingMethods(shipTo,group.ShipToCustomerOptions);
      const shipByValue = utilities.isEmptyOrNullString(group.shipBy) ? shippingMethods[0].value : group.shipBy;
      const shipByTxt = utilities.isEmptyOrNullString(group.shipByText) ? shippingMethods[0].text : group.shipByText;
      itemGrouping[arrayIndex][index].shipByOrder = shipByTxt;
        
          const fulfillmentType = shipTo === "store" ? "S2S" : "S2C";
          // const fulfillmentType = shipTo;
          const shippingMethod = shipByValue; 
          if(checkDimShippingMethod.includes(shippingMethod)){
             if (!output[storeId]) {
              output[storeId] = [];
          }
            countData++;
          const rdcItems = {};  
          group.final_group.forEach(item => {
              const rdc = item.RDC;
              if (!rdcItems[rdc]) {
                  rdcItems[rdc] = [];
              }
              if (item.ItemNum) {
                  rdcItems[rdc].push(item.ItemNum);
              }
          });  
          output[storeId].push({
              shippingMethod,
              fulfillmentType,
              rdcItems
          });
        }        
      });
      });
      if(countData  === 0) {
        this.placeOrder(multiStoreCarts, isMultiStore);
      }
      else {
      let payload =  { "Orders" : output,
          "validationTypes": [
            "DimensionCheck",
            "HazmatCheck"
          ],
          "scenario":"checkout"
        } 
        this.setState({
          isValidationLoading:true
        })
    restUtils
      .postDataWithBearer(CHECKOUT_ORDER_VALIDATION, payload)
      .then(response => {
        if (response) {
          let allValid  = this.allCheckoutValidations(response.data);          
          this.setState({isValidationLoading:false,CheckoutValidateState:allValid})
          if(allValid){
            this.placeOrder(multiStoreCarts, isMultiStore);
          }else {
           
          this.props.checkoutOrderValidation(response.data);
          this.toastDelay(utilities.centreshowToast('Please review the validations before resubmitting the order.',true));
      return;
          }
         }});
        }
  }
  allCheckoutValidations = (data) => {
    return Object.values(data).every(array =>
      array.every(item =>
        Object.values(item.rdcItemValidations).every(validations =>
          validations.every(validation => {
            // Extract validation keys dynamically from the first validation object
            const keys = Object.keys(validation.validations);
            return keys.every(key => validation.validations[key]?.valid);
        })
        )
      )
    );
  };
  placeOrderCheck = (multiStoreCarts = [], isMultiStore) => {
    const { cart } = this.state;
    if(cart.TypeId === 2){
      this.placeOrder(multiStoreCarts, isMultiStore)
      } else  {
        this.checkoutValidation(multiStoreCarts, true)
      }
  }
  placeOrder = (multiStoreCarts = [], isMultiStore ) => {
    const { cart, isRestockingOrder,  centralshipBy, itemGrouping } = this.state;    
    if(cart.TypeId === 2) {
      this.placeREOrder ();
      return;
    }
    const { totalCost } = cart;
    const { userName, storeId, address, multiStoreDetails } = this.props;
    const { stateCode, rdc } = address;
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }
    let RestockingOrder;
    if(isRestockingOrder === 'true') {
      RestockingOrder = 'Y'
    } else {
      RestockingOrder = 'N'
    }
    let alphanumeric = /[^A-Za-z0-9]/g;
    let centretoast = '';
    let itemGroupName = []
    multiStoreCarts.forEach(cart => {  
      if(cart.items.length > 0){ 
      let poNumber = this.state.poNum[cart.storeId];
      if (utilities.isEmptyOrNullString(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`Please enter Purchase Order#`, true);
        return;
      } else if ( poNumber.toLowerCase() === 'stockup') {
        centretoast = utilities.centreshowToast(`Stockup is reserved word`, true);
        return;
      } else if (poNumber.length > 10 ) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast`Purchase Order# must be less than 10 digits`;
        return;
      } else if((alphanumeric).test(poNumber)) {
        this.setState({ showPoError: true });
        centretoast = utilities.centreshowToast(`PO#: Special characters are not allowed`, true);       
        return;
      }         
    } 
    })
    if(centretoast !== ''){
      this.toastDelay(centretoast)
      return;
    }      
    this.setState({disablePlaceorderbtn: true, isLoading: true})
    const url = `${placeOrderMainrameUrl}/Submit`;
    let payload = [];
    let rdcTypeNew = ''
    itemGrouping.forEach((group,index) => {
      let arrayIndex = index;
      Object.values(group).forEach(({final_group,group_rdc,shipTo,customerAddress,shipLater,shipBy,shipByText,emergencyChecked, orderFromRetrofittedRDCs, ShipToCustomerOptions},index)=>{
      let subTotal = 0;
      let cartId = 0;
      let storeNum;
      const itemNums = final_group.map((item) => {
        const tieredCost = this.getTieredCost(item);
        const actualQty = item.Qty && item.Qty !== 0 ? item.Qty : item.userQty;
        const qty = item.Qty && item.Qty !== 0 ? utilities.isBinLabel(item.Qty) ? 0 : item.Qty : utilities.isBinLabel(item.userQty) ? 0 : item.userQty;
        subTotal = (parseFloat(subTotal) + parseFloat(qty * tieredCost)).toFixed(2);
        cartId = item.cartId;
        storeNum = item.storeId;
        rdcTypeNew = item.rdcType
        return {
          ItemNum: item.ItemNum,
          Qty: actualQty,
          OverRideDetails: item.OverRideDetails,
          RDC: item.RDC,
          Source: item.source,
          rdcType: item.rdcType,
          centralShipRDCNum: item.centralShipRDCNum,
          monthlyspecial: item.MonthlySpecial
        };
      });
      
      itemGrouping[arrayIndex][index].subTotal = subTotal;
      shipTo = utilities.isEmptyOrNullString(shipTo) || shipTo === 'store' ? 'store' : shipTo;
      const orderFromRetrofittedRDCsValue = orderFromRetrofittedRDCs ==='Y' ? true: false
      const shippingMethods = this.getShippingMethods(shipTo,ShipToCustomerOptions);
      const shipByValue = utilities.isEmptyOrNullString(shipBy) ? shippingMethods[0].value : shipBy; 
      const shipByTxt = utilities.isEmptyOrNullString(shipByText) ? shippingMethods[0].text : shipByText; 
      itemGrouping[arrayIndex][index].shipByOrder = shipByTxt;
      const storeRdcNum = storeNum && Object.keys(multiStoreDetails?.childStoreDetails || {}).length > 0 ? 
      multiStoreDetails.childStoreDetails[storeNum] : '';
      payload =[ ...payload, {
        shipBy: shipByValue,
        ApplicationType: 'NW',
        user: {
          molUserName: userName,
          primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : rdc,
          PrimaryWarehouseType: storeRdcNum && storeRdcNum.PrimaryWarehouseType ? storeRdcNum.PrimaryWarehouseType : multiStoreDetails.PrimaryWarehouseType,
          storeID: storeNum ? storeNum : storeId,
          shoppingCartID: cartId ? cartId : cart && cart.cartId,
          retrofittedRDCs: this.props.location?.state?.retrofittedRDCs
        },
        consumerNum: customerAddress ? customerAddress.customerNbr : "",                          
        CentralshipBy: centralshipBy && rdcTypeNew === 'C' ? 'R' : '',                            
        willcall: '',                                                        
        shipLater: shipLater ? 'N' : '',                                    
        poNum:  this.state.poNum[storeNum],                                           
        OrderDueDate: '',                                                   
        items: itemNums,
        shipFromRDC: itemNums[0].RDC,                                              
        datingTerms: '',                                                  
        validState: 'Y',                                                  
        shippingAddress: customerAddress ? customerAddress.locationId : shipTo,    
        Source: 'WH',                                                      
        stockup: '',                                                       
        actionType: '',                                                    
        orderType: cart.editOrderType,                                     
        editValue: false,                                                  
        value: true,                                                       
        RestockingOrderCheckBox: RestockingOrder ,  
        stateCode: stateCode,
        offshoreFlag: '0'
      }];
    });
  });

  if(isMultiStore){
    let multiStoreCartsId=[];
    multiStoreCartsId = multiStoreCarts.map(res=>{
      return res.storeId
    })
    multiStoreCarts=multiStoreCarts && multiStoreCarts.filter(msFilter=>{
      return msFilter.items.length > 0
    })
    payload = payload.filter(cartFilter=>multiStoreCartsId.includes(cartFilter?.user?.storeID))
   }
   multiStoreCarts.forEach(cart=>{
    itemGrouping.map(itemGroupNames => {
      const order = itemGroupNames[0];
      const products = order.final_group;
      if(products[0].cartId === cart.cartId){
      itemGroupName = [...itemGroupName, itemGroupNames];
      }
    }) 
   })
    restUtils
      .postDataWithoutToken(url, payload)
      .then(response => {
        if (response) {
          this.setState({disablePlaceorderbtn: false, isLoading: false})
          if(response.data && response.data.error && (response.data.error !== null || response.data.error !== "")) {
            utilities.showToast(response.data.error, true)
          }else{
            this.props.placeOrder(cart)
            history.push({
              pathname: `/orderConfirmation/${this.state.cartName}`,
              state: {
                cart: multiStoreCarts,
                ...this.state.cart,
                customerAddress: this.state.customerAddress,
                poNum: this.state.poNum,
                orderTotal: totalCost,
                itemGrouping: itemGroupName,
                isMultiStore:this.state.isMultiStore
              }
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
        utilities.showToast(`Failed to Submit order. Please try later`, true);
        this.setState({
          tstmsg: '',
          disablePlaceorderbtn: false,
          isLoading: false
        })
      }); 
  };

  updateStatus = () => {
    const { cart } = this.state;
      const url = `${EDIT_ORDER_ITEM_DETAILS}/updateOrderStatus`;
      const payload = {
        rdcNum: cart?.editRdcNumber,
        orderType: cart?.editOrderType,
        orderNum: cart?.editOrderNbr,
        shoppingCartID: cart?.cartId,
        userId: cart.UserId,
        oldCartName:'',
        storeId: this.props.storeId,
        molUserName: this.props.userName,
        isCancelOrder: true,
        status : 'F'
      };  
      restUtils
        .postData(url, payload)
        .then(response => {
        })
        .catch(err => {
          console.log(err);
        });
    }

  cancelOrder = (cartId, forEdit) => {
    if(forEdit && forEdit == 'Y'){
      this.updateStatus()
    }
    this.props.deleteCart(cartId, '', '', true);
    this.props.notifyCarts();
  };

  placeOrderAll = () => {
    const { multiStoreCarts, cart } = this.state;
    if(cart.TypeId === 2){
      this.placeOrder(multiStoreCarts, true)
      } else  {
        this.checkoutValidation(multiStoreCarts, true)
      }
  }

  placeOrderSelected = () => {
    let { multiStoreCarts , cart} = this.state;
    let cartSelected = this.state.cartSelected;
    if(cartSelected && cartSelected.length > 0){
      multiStoreCarts = multiStoreCarts && multiStoreCarts.filter(cart =>
                                      cartSelected.includes(cart.storeId))
      if(cart.TypeId === 2){
        this.placeOrder(multiStoreCarts, true)
        } else  {
          this.checkoutValidation(multiStoreCarts, true)
        }
    }else{
      this.toastDelay(utilities.centreshowToast('Please select atleast one order to submit',true));
      return;
    }
  }

  handleStoreCheck= (storeId) => {
    let {cartSelected} = this.state
    if(cartSelected.includes(storeId)){
      cartSelected = cartSelected.filter(item => item !== storeId)
    }else{
      cartSelected.push(storeId)
    }
     this.setState({
      cartSelected
     })
  }

  handlePONumChange = (e, storeId) => {
    let {poNum} = this.state;
    poNum[storeId] = e.target.value;
    this.setState({ poNum});
  };

  selectAddress = (address, poNum) => {
    this.setState({
      poNum: poNum,
      shipTo: address.name,
      customerAddress: address
    });
    this.props.updateShipTo(this.state.cart.cartId, address);
  };

  addressBook = (e,arrayIndex) => {
    const { group } = e.target.dataset;
    const orderFromRetrofittedRDCs =
    this.props.location.state &&
    this.props.location.state.orderFromRetrofittedRDCs
      ? this.props.location.state.orderFromRetrofittedRDCs !== 'N'
      : false;
      history.push({
      pathname: '/AddressBook',
      state: {
        cartName: this.state.cartName,
        cart: this.state.cart,
        cartProducts: this.state.cartProducts,
        itemGrouping:this.state.itemGrouping,
        group: group,
        index: arrayIndex,
        poNum: this.state.poNum,
        isCheckout: true,
        orderFromRetrofittedRDCs,
        source:'main',
        isMultiStore:this.state.isMultiStore,
        multiStoreCarts:this.state.multiStoreCarts
      }
    }); 
  };

  selectedDropdownVal = (e,index) => {
    const group = e.target.dataset.group;
    let {itemGrouping} = this.state;
    itemGrouping[index][group].shipTo = e.target.value;
    this.setState({ itemGrouping});
  };

  getShippingMethods=(shipTo,shipToCustomerOptions)=>{
    shipTo = shipTo?shipTo:'store';
    return shipTo !== 'store'? shipToCustomerOptions : s2SShippingMethods    
  }

  handleEmergencyChange = (e) => {
    const {group} = e.target.dataset;
    let index = group.split(',');
    const {itemGrouping} = this.state;
    itemGrouping[index[1]][index[0]].emergencyChecked = e.target.checked;
    this.setState({itemGrouping});
  }

  getItemGroupNames = (cartId) => {
    let itemGroupName = {};
    const {itemGrouping} = this.state; 
    itemGrouping.map(itemGroupNames => {
      Object.keys(itemGroupNames).map(group => {
        const order = itemGroupNames[group];
        const products = order.final_group;
        if(products[0].cartId === cartId)
        itemGroupName = itemGroupNames;
      })
    })
    let groups = Object.keys(itemGroupName).map(group => { return itemGroupName[group]});
    return groups;
  }

  getTotalGroupWeight = (cartId) => {
    let itemGrouping = this.getItemGroupNames(cartId);
    let cartProducts = [];
    itemGrouping.forEach(order=>{
      cartProducts = [...cartProducts,...order.final_group];
    })
    const totalWeight = cartProducts
      ? parseFloat(
          cartProducts.reduce(
            (wt1, { Ext_weight, Qty }) => parseFloat(wt1) + (utilities.isBinLabel(Qty) ? 0 : parseFloat(Ext_weight)),0)
        ).toFixed(2)
      : 0.0;
    return totalWeight;
  }

  render() {
    const { poNum, isRestockingTime, cart,isPONumEditable , forEdit, isMultiStore} = this.state;
    let {multiStoreCarts,itemGrouping} = this.state;
    if(!isMultiStore){  
      multiStoreCarts = [cart];
    }  
    
    const primaryWarehouseType = this.props.multiStoreDetails.PrimaryWarehouseType;
    const isRACart = cart && cart.TypeId === 4;
    const isPlanogramCart = cart && cart.TypeId === 5;
    const isDOMCart = cart && cart.TypeId === orderNames["DOM"].infoType;
    this.shippingMethods = [];
   const  getCheckoutPrefer = this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView
   : 'c1'
   const  hideTotalExtendCost = shouldHideCostForPreference(this.props.viewType,getCheckoutPrefer);
   const getPrefer = this.props.getprefer &&
    this.props.getprefer.customerView
    ? this.props.getprefer.customerView
    : 'c1';
    return (
      <div className='page-wrap'>
        <div className='page-header'>Checkout {cart ? `${cart.cartName}-${cart.cartId}`: ''}</div>
        
        { this.state.disablePlaceorderbtn && <div className='Note-Message'>Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.</div>}
        {!cart ? (
          <h3>
            Order already placed!{' '}
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </h3>
        ) : this.state.isError ? (
          <div className='checkoutErrorBacttoCart'>
            <h3>
              {this.state.errorMsg ? this.state.errorMsg : 'Checkout failed!'}
            </h3>
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </div>
        ) : (
          <>
            <div className='checkout-table'>

            {this.state.isLoading && (
              <div className="loaderWrapper">
              <Loader active />
              <p>Please wait while we process your order... <br/> Please don’t press the Back Button while your order is processing </p>
              </div>
            )}
            {this.state.isValidationLoading && (
              <div className="loaderWrapper">
              <Loader active />
              <p>Please review the validations before resubmitting the order.</p>
              </div>
            )}

            
             {isMultiStore &&
                  <>
                    <div className='t-right'> 
                      <Link to='/cart' className='multi-store-back-to-cart'>
                        Back to Cart
                      </Link>
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderSelected()} disabled={this.state.disablePlaceorderbtn}>
                        Place Selected Orders
                      </button>
                      <button className='ui red button checkout-btn' onClick={() => this.placeOrderAll()} disabled={this.state.disablePlaceorderbtn}>
                        Place All Orders
                      </button>
                    </div>    
                    <br/>                  
                  </>
            }
            {multiStoreCarts.map((cart, index) => {

              let arrayIndex = index;
              return (<>
                {isMultiStore &&  cart?.items?.length > 0 &&
                  <>
                    <div className='cart-accordion accordion-header t-center'>
                      <span>
                        {'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, cart.userId)}
                      </span>
                      <span className='Multi-cart-accordion-store-select'>
                      {'Select Store '} <input
                        type='checkbox'
                        name = {'storeCheck'+cart.userId}
                        onChange={() => this.handleStoreCheck(cart.storeId)}
                      />
                      </span>
                    </div>
                  </>
                  }
                 
                  {cart?.items?.length > 0 &&
                  <>
                  <Responsive minWidth = '1025'>
                    <div className={(!isRACart && !isPlanogramCart) ? 'place-order' : 'place-orderRA'}>
                      {(!isRACart && !isPlanogramCart) ?
                      <>
                        <div className='po-div'>
                          <label className='po-label'>Purchase Order # *</label>
                          <input
                            type='text'
                            className='cart-po-input'
                            value={poNum[cart.storeId]}
                            disabled={!isPONumEditable}
                            onChange={(e) => this.handlePONumChange(e,cart.storeId)}
                            placeholder='Enter PO#...'
                            maxLength={10}
                          ></input>
                          <span className='disclaimer-span'>(Max 10 characters)</span>
                          <p style={{fontSize:'10px'}}>( PO number is mandatory, Special characters and spaces are not allowed. )</p>
                        </div>
                      
                      <div className="top-extended">
                        <div className="extweight">
                          
                          <div className='store-combo-div'>
                            <span>
                              TOTAL EXTENDED WEIGHT{': '}
                            </span>
                            <span className='cost_red_color'>
                              {this.getTotalGroupWeight(cart.cartId)}{' '}lbs
                            </span>
                          </div> 
                        </div>
                        <br/>
                        { hideTotalExtendCost ? 
                        <div className={(!isRACart && !isPlanogramCart) ? 'store-combo-div' : 'store-combo-divRA'}>
                          {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                          {parseFloat(totalCost(cart)) === 0.00 ? '-' :this.props.multiStoreDetails.access !== 1  && 
                          <MaskPrice
                            getprefer={this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                            viewType={this.props.viewType}
                            fieldVal = {totalCost(cart)}
                            field='cart-totals'
                          />}
                        </div> : ""
                                              }                      </div></> : ''}
                      <div>
                        {!isMultiStore && <Link to='/cart' className='back-to-cart'>
                          Back to Cart
                        </Link>}
                        <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId, forEdit)}>
                          Cancel Order
                        </button>
                        {(( this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                            || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                            ||this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales 
                            || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk 
                            || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental 
                            || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral )
                            && !this.props.hq && this.props.allowedorder) 
                            ? !this.props.isProspectStore ?
                            <button className='ui red button place-order-btn' onClick={()=>this.placeOrderCheck([multiStoreCarts.find(carts=> parseInt(carts.cartId)===parseInt(cart.cartId))],true)} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                              :<> 
                                <button className='ui red button place-order-btn' disabled> Place Order </button>
                                <p className="disabledMessage">*Ordering restricted</p>
                                </>  
                        : ''
                        }
                      </div>
                    </div>
                  </Responsive>
                  <Responsive maxWidth='1024'>
                    {(!isRACart && !isPlanogramCart) ?
                    <>
                      <div className='po-div'>
                        <label className='po-label'>PO # *</label>
                        <input
                          type='text'
                          className='cart-po-input'
                          value={poNum[cart.storeId]}
                          onChange={(e) => this.handlePONumChange(e,cart.storeId)}
                          placeholder='Enter PO#...'
                          maxLength={10}
                        ></input>
                        <span className='disclaimer-span'>(Max 10 characters)</span>
                        <p style={{fontSize:'9.7px'}}>( PO number is mandatory, Special characters and spaces are not allowed. )</p>
                      </div>
                    <br />
                    <div className="top-extended mobileWeight">
                      <div className="extweight">
                        <div className='store-combo-div'>
                          <span>TOTAL EXTENDED WEIGHT{': '}</span>
                          <span className='cost_red_color'>
                            {this.getTotalGroupWeight(cart.cartId)}{' '}lbs
                          </span>
                        </div>
                      </div>
                      { hideTotalExtendCost ? 
                      <div className='store-combo-div'>
                        {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                        {parseFloat(totalCost(cart)) === 0.00 ? '-' :this.props.multiStoreDetails.access !== 1  && <MaskPrice
                          getprefer={this.props.getprefer &&
                            this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
                          }
                          viewType={this.props.viewType}
                          fieldVal = {totalCost(cart)}
                          field='cart-totals'
                        />
                        }
                      </div> : "" }
                    </div> 
                    </>
                    :'' }
                    <br />
                    <div className='place-orderRA'>
                      <Link to='/cart' className='back-to-cart'> Back to Cart </Link>
                      <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId, forEdit)}> Cancel Order </button>
              
                      {(( this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                          || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                          ||this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales 
                          || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk 
                          || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental 
                          || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral )
                          && !this.props.hq && this.props.allowedorder) 
                          ? !this.props.isProspectStore ?
                          <button className='ui red button place-order-btn' onClick={()=>this.placeOrderCheck([multiStoreCarts.find(carts=> parseInt(carts.cartId)===parseInt(cart.cartId))],true)}  disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                            :<> 
                              <button className='ui red button place-order-btn' disabled> Place Order </button>
                              <p className="disabledMessage">*Ordering restricted</p>
                              </>
                      : ''
                      }
                    </div>
                  </Responsive>
                  </>
                  }
                  {this.getItemGroupNames(cart.cartId).map((order,index)=>{
                    const group = index;
                    const products = order.final_group;
                    const customerAddress = order.customerAddress;
                    const shipTo = order.shipTo;
                    const emergencyChecked = this.props.mainRDCUnderEmergency || order.emergencyChecked;
                    const shipLaterChecked = order.shipLater;
                    const orderFromRetrofittedRDCs = order.orderFromRetrofittedRDCs ==='Y' ? true: false
                    const shippingMethods = this.getShippingMethods(shipTo,order?.ShipToCustomerOptions);
                    let isCentralShipped = false
                    let monthlySales = false
                    let TVProduct = false
                    products && products.map(data => {
                      if(data.rdcType === 'C'){
                        isCentralShipped = true
                      }
                      if(data.monthlySales){
                        monthlySales = true
                      }
                      if(data.source === 'WH'){
                        TVProduct = true
                      }
                    })
                    const tierLevels = this.getTierLevel(products);
                    let groupIndex = arrayIndex+''+group;
                    return(
                      <>
                        <CartProductsTable
                          cart={this.state.cart}
                          cartTotalcost={this.props.location.cartTotalcost}
                          totalWeight={this.getTotalGroupWeight(cart.cartId)}
                          products={products}
                          removeItem={this.props.removeProduct}
                          checkout = {this.props.checkout}
                          updatedCart = {this.state.quantites}
                          multiStoreData = {this.state.multiStoreData}
                          checkoutItemGroup = {this.state.itemGrouping}
                          checkoutResponseData = {this.state.checkoutResponseData}
                          isCheckout={true}
                          showPoError={this.state.showPoError}
                          poNum={poNum[cart.storeId]}
                          userId = {cart.userId}
                        />
                      {cart?.items?.length > 0 &&
                      <>
                        <Responsive minWidth='1025'>
                          <Grid columns={3} id='shippingBlock'>
                            {(isRACart || isPlanogramCart || isDOMCart) ?
                              <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                                {isPlanogramCart && 
                                  <div>
                                    <span>Purchase Order #:</span>
                                    <span className='Planogram_PO'>{cart && cart.cartName}</span>
                                  </div>
                                } 
                                <div>
                                    <span>Ship to:</span>
                                    <span className='RAshipping'>Store</span>
                                </div>
                                <div>
                                  <span>Shipping Method: &ensp;&ensp;</span>
                                  <span className='RAdelivery'>Regular Delivery </span>
                                </div>
                              </Grid.Column> 
                              :
                              <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                                {/* <span className='very-imp-info displayFlex'>
                                  <input type='checkbox' id={`em-${groupIndex}`} className={this.props.mainRDCUnderEmergency && 'styled-cb'}
                                      defaultChecked={emergencyChecked}
                                      disabled={!this.props.mainRDCUnderEmergency}
                                      data-group = {group+','+arrayIndex}
                                      onChange={this.handleEmergencyChange}
                                    />
                                    <label htmlFor={`em-${groupIndex}`}/>
                                    &nbsp;&nbsp;Please check if this is an EMERGENCY RESPONSE ORDER.
                                </span> */}
                                <span className='DonotShip displayFlex'>
                                  <input type='checkbox' id={`shiplater-${groupIndex}`}
                                  defaultChecked={shipLaterChecked}
                                  className='styled-cb'
                                  data-group = {group+','+arrayIndex}
                                  onChange={this.shipLatersValue}/>
                                  <label htmlFor={`shiplater-${groupIndex}`}/>
                                  &nbsp;&nbsp;DO NOT INCLUDE SHIP LATERS WHEN PROCESSING THIS ORDER
                                </span> 
                                <span className='ship-to-div'>
                                  <label className='ship-to-label'> Ship To</label>
                                  <select
                                    className='ship-to-combo'
                                    value={shipTo}
                                    data-group = {group}
                                    onChange={(e) => this.selectedDropdownVal(e,arrayIndex)}
                                  >
                                    {customerAddress && (
                                      <option value={'Customer'}> Customer </option>
                                    )}
                                    <option value='store'>Store</option>
                                  </select>
                                  <button className="addressbook-btn addresscustomer" data-group={group} onClick={(e)=>this.addressBook(e,arrayIndex)}>Address Book</button>
                                </span>
                                <div className='shipping-method-combo-div'>
                                  <label className='ship-method-label'>Shipping Method</label>
                                  <select className='shipping-method-combo' 
                                    data-group={group} 
                                    onChange={(e) => this.handleshippingMethod(e,arrayIndex)}>
                                    {shippingMethods &&
                                      shippingMethods.map(method => {
                                        const value=`${method.value}@${method.text}`;
                                        return (
                                          <option value={value} text={method.text}>{method.text}</option>
                                        );
                                    })}
                                  </select>
                                </div>
                                {customerAddress && shipTo === 'Customer' && (
                                  <span className='details_bold'>
                                    <p>Ship To:</p>
                                    <div className='shipping_address'>
                                    <span>{customerAddress.name}</span>
                                    <br />
                                    <span>{customerAddress.customerName}</span>
                                    <br />
                                    <span>{customerAddress.streetAddress}</span>
                                    <br />
                                    {customerAddress.addressLine2 && <> <span>{customerAddress.addressLine2}</span>
                                    <br />
                                    </>}                                    
                                    <span>{customerAddress.city},{customerAddress.state}</span>
                                    <br />
                                    <span>{customerAddress.zipCode}</span>
                                    <br />
                                    </div>
                                  </span>
                                )}
                                {isCentralShipped && (
                                <>
                                {!(this.state.itemGrouping[0][index]?.shipTo && this.state.itemGrouping[0][index]?.shipTo !== "store") && (
                                  <div className='central-ship details_bold'>
                                    <p>Central Ship:Shipping method from RDC 41:</p>
                                    {monthlySales ? '' :
                                      TVProduct && 
                                        <span>
                                          <input type='radio' defaultChecked={true}/>
                                          {(primaryWarehouseType === "S" || primaryWarehouseType === "H") ? 
                                          "Wait until my order release date**": "Small Parcel: Wait until my order release date**"}
                                        </span>
                                    }
                                    <br />
                                    <br />
                                    <div>
                                      <span>
                                        **Store may select store address from the address book
                                        to utilize additional small parcel shipping options
                                        available
                                      </span>
                                      <div>-OR-</div>
                                      <span>
                                        The store may contact Central Ship (RDC41) at
                                        888-881-7447, option 3 between the hours of 8:00 AM
                                        and 4:00 PM CST Monday-Friday, for available shipping
                                        options.
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </>
                              )}
                              <br/>
                              </Grid.Column>
                            } 
                            {(!isRACart && !isPlanogramCart) &&
                            <Grid.Column className='tirePricing-checkout bgColor1'>
                              <span rowSpan={1}>
                                {isRestockingTime === true ? 
                                    <p>Restocking Order :  &nbsp;
                                    <Checkbox name='RestockingOrderCheckBox' checked = {this.state.isRestockingOrder}/> </p> 
                                  : this.state.isRestockingOrder === true ? 
                                    <span> At this time the restocking program is not available. 
                                      Please check the start and end dates for this program.  
                                      Please remove item 283333 from your order. </span> : ''
                                } 
                                <br/>   
                                <span className='info-text'>
                                  NOTE: If you ordered items from multiple distribution centers, you will not see all of your items above.
                                  Additional confirmation pages will be shown to you.
                                    {tierLevels && tierLevels.includes(1) && (
                                    <p className='no_margin'>
                                      T1 - Each Unit Cost
                                    </p>
                                  )}
                                  {tierLevels && tierLevels.includes(2) && (
                                    <p className='no_margin'>
                                      T2 - Full Carton Cost
                                    </p>
                                  )}
                                  {tierLevels && tierLevels.includes(3) && (
                                    <p className='no_margin'>
                                      T3 - Volume Discount
                                    </p>
                                  )}
                                  {tierLevels && tierLevels.includes(4) && (
                                    <p className='no_margin'>
                                      T4 - Volume Discount
                                    </p>
                                  )}
                                </span>
                                {order?.final_group?.length > 0 && order.final_group.some((data) => data?.hazMat === "Y") &&
                                 <div className='info-text'>
                                    Off-shore Retailers and Hazmat: Off-shore retailer order quantities will be increased to full shelf pack quantities to comply with international shipping regulations for Hazardous Materials.
                                </div>}
                              </span>
                            </Grid.Column>
                            }
                            <Grid.Column className={(!isRACart && !isPlanogramCart) ? 't-right placeOrderCheckout bgColor1' : ''} id={(!isRACart && !isPlanogramCart) ? '' : 'RA'}>
                              <div className={(!isRACart && !isPlanogramCart) ? '': 'column RAmessage t-right'}>
                                <p >
                                  {' '}
                                  Prices are subject to change. Items will be priced
                                  at the time the order is fulfilled, not at the
                                  time of order placement.
                                </p>
                              </div>
                              <br/>
                              
                              <div className="extended">
                              {(!isRACart && !isPlanogramCart) &&
                                <div className='store-combo-div'>
                                  <span> TOTAL EXTENDED WEIGHT{': '}</span>
                                  <span className='cost_red_color'>{this.getTotalWeight(products)}{' '}lbs </span>
                                </div>}
                                <br/>
                                { hideTotalExtendCost ? 
                                <div className='store-combo-div totalCost'>
                                  {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                                  {parseFloat(totalCost(cart)) === 0.00 ? '-' :this.props.multiStoreDetails.access !== 1  && <MaskPrice
                                    getprefer={this.props.getprefer &&
                                      this.props.getprefer.customerView
                                      ? this.props.getprefer.customerView
                                      : 'c1'
                                    }
                                    viewType={this.props.viewType}
                                    fieldVal={this.state.cart.TypeId !== 4 ? this.getSubTotalExtCost(products) : totalCost(this.state.cart)}
                                    field='cart-totals'
                                  />}
                                </div>: "" }
                              </div>
                            </Grid.Column>
                          </Grid>
                        </Responsive>
                        <Responsive maxWidth='1024'>
                        <Grid id='shippingBlock'> 
                          {(isRACart || isPlanogramCart || isDOMCart) ?
                             <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin'>
                             <div>
                                 <span>Ship to:</span>
                                 <span className='RAshipping'>Store</span>
                             </div>
                             <div>
                               <span>Shipping Method: &ensp;&ensp;</span>
                               <span className='RAdelivery'>Regular Delivery </span>
                             </div>
                           </Grid.Column> 
                           : 
                            <Grid.Column className='displayInlineFlex checkoutPage bgColor'>
                              {/* <span className='very-imp-info displayFlex'>
                                <input type='checkbox' id={`em-${groupIndex}`} className='styled-cb'
                                    defaultChecked={emergencyChecked}
                                    disabled={!this.props.mainRDCUnderEmergency}
                                    data-group = {group+','+arrayIndex}
                                    onChange={this.handleEmergencyChange}
                                  /><label htmlFor={`em-${groupIndex}`}/>
                                  &nbsp;&nbsp;Please check if this is an EMERGENCY RESPONSE ORDER.
                              </span> */}
                              <span className='DonotShip displayFlex'>
                                <input type='checkbox' id={`shiplater-${groupIndex}`}
                                defaultChecked={shipLaterChecked}
                                className='styled-cb'
                                data-group = {group+','+arrayIndex}
                                onChange={this.shipLatersValue}/>
                                <label htmlFor={`shiplater-${groupIndex}`}/>
                                &nbsp;&nbsp;DO NOT INCLUDE SHIP LATERS WHEN PROCESSING THIS ORDER
                              </span> 
                              <span className='ship-to-div'>
                                <label className='ship-to-label'> Ship To</label>
                                <select
                                  className='ship-to-combo'
                                  value={shipTo}
                                  data-group = {group}
                                  onChange={(e) => this.selectedDropdownVal(e,arrayIndex)}
                                >
                                  {customerAddress && (
                                    <option value={'Customer'}> Customer </option>
                                  )}
                                  <option value='store'>Store</option>
                                </select>
                                <button className="addressbook-btn addresscustomer" data-group={group} onClick={(e)=>this.addressBook(e,arrayIndex)}>Address Book</button>
                              </span>
                              <div className='shipping-method-combo-div'>
                                <label className='ship-method-label'>Shipping Method</label>
                                <select className='shipping-method-combo' 
                                data-group={group} 
                                onChange={(e) => this.handleshippingMethod(e,arrayIndex)}>
                                  {shippingMethods &&
                                    shippingMethods.map(method => {
                                      const value=`${method.value}@${method.text}`;
                                      return (
                                        <option value={value} text={method.text}>{method.text}</option>
                                      );
                                  })}
                                </select>
                              </div>
                              {customerAddress && shipTo === 'Customer' && (
                                <span className='details_bold'>
                                  <p>Ship To:</p>
                                  <div className='shipping_address'>
                                  <span>{customerAddress.name}</span>
                                  <br />
                                  <span>{customerAddress.streetAddress}</span>
                                  <br />
                                  <span>{customerAddress.city},{customerAddress.state}</span>
                                  <br />
                                  <span>{customerAddress.zipCode}</span>
                                  <br />
                                  </div>
                                </span>
                              )}
                              {isCentralShipped && (
                              <>
                              {!(this.state.itemGrouping[0][index]?.shipTo && this.state.itemGrouping[0][index]?.shipTo !== "store") && (
                                <div className='central-ship details_bold'>
                                  <p>Central Ship:Shipping method from RDC 41:</p>
                                  {monthlySales ? '' :
                                    TVProduct && 
                                      <span>
                                        <input type='radio' defaultChecked={true}/>
                                        {(primaryWarehouseType === "S" || primaryWarehouseType === "H") ? 
                                        "Wait until my order release date**": "Small Parcel: Wait until my order release date**"}
                                      </span>
                                  }
                                  <br />
                                  <br />
                                  <div>
                                    <span>
                                      **Store may select store address from the address book
                                      to utilize additional small parcel shipping options
                                      available
                                    </span>
                                    <div>-OR-</div>
                                    <span>
                                      The store may contact Central Ship (RDC41) at
                                      888-881-7447, option 3 between the hours of 8:00 AM
                                      and 4:00 PM CST Monday-Friday, for available shipping
                                      options.
                                    </span>
                                  </div>
                                </div>
                              )}
                            </>
                            )}
                            <br/>
                            </Grid.Column>
                          }
                          {(!isRACart  && !isPlanogramCart) &&
                          <Grid.Column className='tirePricing-checkout bgColor1'>
                            <span rowSpan={1}>
                              {isRestockingTime === true ? 
                                  <p>Restocking Order :  &nbsp;
                                  <Checkbox name='RestockingOrderCheckBox' checked = {this.state.isRestockingOrder}/> </p> 
                                : this.state.isRestockingOrder === true ? 
                                  <span> At this time the restocking program is not available. 
                                    Please check the start and end dates for this program.  
                                    Please remove item 283333 from your order. </span> : ''
                              } 
                              <br/>   
                              <span className='info-text'>
                                NOTE: If you ordered items from multiple distribution centers, you will not see all of your items above.
                                Additional confirmation pages will be shown to you.
                                  {tierLevels && tierLevels.includes(1) && (
                                  <p className='no_margin'>
                                    T1 - Each Unit Cost
                                  </p>
                                )}
                                {tierLevels && tierLevels.includes(2) && (
                                  <p className='no_margin'>
                                    T2 - Full Carton Cost
                                  </p>
                                )}
                                {tierLevels && tierLevels.includes(3) && (
                                  <p className='no_margin'>
                                    T3 - Volume Discount
                                  </p>
                                )}
                                {tierLevels && tierLevels.includes(4) && (
                                  <p className='no_margin'>
                                    T4 - Volume Discount
                                  </p>
                                )}
                              </span>
                              {order?.final_group?.length > 0 && order.final_group.some((data) => data?.hazMat === "Y") &&
                                 <div className='info-text'>
                                    Off-shore Retailers and Hazmat: Off-shore retailer order quantities will be increased to full shelf pack quantities to comply with international shipping regulations for Hazardous Materials.
                                </div>}
                            </span>
                          </Grid.Column>}
                          <Grid.Column className={(isRACart || isPlanogramCart) ? 'Ramobile_checkout': 'placeOrderCheckout bgColor1'}>
                            <div>
                              <p> {' '}
                                Prices are subject to change. Items will be priced
                                at the time the order is fulfilled, not at the
                                time of order placement.
                              </p>
                            </div>
                            <br/>
                            <div className="extended">
                              {this.state.cart.TypeId === 1 ?
                                <div className='store-combo-div'>
                                  <span> TOTAL EXTENDED WEIGHT{': '}</span>
                                  <span className='cost_red_color'>{this.getTotalWeight(products)}{' '}lbs </span>
                                </div> 
                              : ''}
                               { hideTotalExtendCost ? 
                              <div className='store-combo-div totalCost'>
                                {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                                {parseFloat(totalCost(cart)) === 0.00 ? '-' :this.props.multiStoreDetails.access !== 1  && <MaskPrice
                                  getprefer={this.props.getprefer &&
                                    this.props.getprefer.customerView
                                    ? this.props.getprefer.customerView
                                    : 'c1'
                                  }
                                  viewType={this.props.viewType}
                                  fieldVal = {totalCost(this.state.cart)} /*need to map */
                                  field='cart-totals'
                                />}
                              </div> : "" }
                            </div>
                          </Grid.Column>
                        </Grid>
                        </Responsive>
                        </>
                      }
                      </>
                    )
                  })}
                  </>
                )
              })}
              <div id="centresnackbar" className= {this.state.tstmsg && `show`} >{this.state.tstmsg}</div>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  let carts = state.cart.orders.allCarts;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    carts,
    userName: state.SessionReducer.userName,
    getprefer:state.preference.listData,
    address: state.SessionReducer.address,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    mainRDCUnderEmergency: state.SessionReducer.mainRDCUnderEmergency,
    currentCart: state.cart.orders.currentCart,
    storeRDC: state.SessionReducer.rdc,
    allowedorder:state.SessionReducer.address.allowedorder,
    hq:state.SessionReducer.address.hq,
    checkDigit: state.SessionReducer.address.checkDigit,
    fullMemberNbr: state.SessionReducer.address.fullMemberNbr,
    isProspectStore : state.SessionReducer.address.isProspectStore,
    checkoutResponseData : state.cart.orders?.checkoutResponseData
  };

};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { placeOrder, deleteCart, removeProduct,checkoutOrderValidation, updateShipTo, checkout, notifyCarts,getCartInfo, getViewAllCarts },
      dispatch
    )
  );
};
Checkout.propTypes = {
  carts:PropTypes.any.isRequired,
  location:PropTypes.any.isRequired,
  history:PropTypes.any.isRequired,
  match:PropTypes.any.isRequired,
  checkoutResponseData:PropTypes.array,
  address:PropTypes.array,
  checkoutOrderValidation:PropTypes.any,
  getprefer:PropTypes.any,
  removeProduct:PropTypes.any,
  checkout: PropTypes.any
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
