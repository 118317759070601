import restUtils from "../../utils/restUtils";
import {
  orderNames,
  infoTypes,
  WH_ShoppingCartId,
  isShoppingAreaCart,
  shoppingAreaFlowTypes,
} from "./constants";
import { Event } from "../../history";
import history from '../../history';
import utilities from "../../utils/utilities";
import {
  addProductUrl,
  addManyProductsUrl,
  createNAddManyUrl,
  createNAddCartUrl,
  addProductOrderPadUrl,
  addItemstoCartPlanogramUrl,
  createNAddCartOrderPadUrl,
  addReunionCartProductUrl,
  deleteCartUrl,
  viewAllCartsUrl,
  updateQuantityUrl,
  updateOrUrl,
  notifyUrl,
  createCartUrl,
  editCartNameUrl,
  msMonthlyspecials,
  removeProductUrl,
  addRACartProductUrl,
  msMonthlyspecialsReunion,
  MONTHLYSPECIAL_ADDTOCART,
  EVENT_ENABLE,
  GET_CONFIG,
  msWarehouseCart,
  msPlanogram,
  MARKET_ADD_TO_CART,
  GET_CART_INFO
} from "../../urlConstants";
import { cartExcelExport } from "../ReunionEvent/AllReunion/viewCartFields";
import { promoItemReducer } from "./reducer";
import _ from "lodash";
import { SWITCH_VIEW } from "../../components/NavBar/actions";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ERROR = "ERROR";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const SET_QUANTITY = "SET_QUANTITY";
export const CREATE_CART = "CREATE_CART";
export const CHOOSE_CART = "CHOOSE_CART";
export const PLACE_ORDER = "PLACE_ORDER";
export const UPDATE_QUANTITY = "UPDATE_QUANTITY";
export const DELETE_CART = "DELETE_CART";
export const UPDATE_SHIP_TO = "UPDATE_SHIP_TO";
export const VIEW_ALL_CARTS = "VIEW_ALL_CARTS";
export const ADD_MANY_PRODUCTS = "ADD_MANY_PRODUCTS";
export const CHECKOUT = "CHECKOUT";
export const UPDATE_CART_NAME = "UPDATE_CART_NAME";
export const NOTIFY_CART = "NOTIFY_CART";
export const MARKET_ENABLE = "MARKET_ENABLE";
export const IS_FETCHING_DATA = "IS_FETCHING_DATA";
export const REUNION_ORDERING_ENABLE = 'REUNION_ORDERING_ENABLE';
export const MS_WHDD_PRODUCT = "MS_WHDD_PRODUCT";
export const UPDATE_QTY = "UPDATE_QTY";
export const UPDATE_TOTALCOST = "UPDATE_TOTALCOST"
export const GET_VIEW_ALL_CARTS = "GET_VIEW_ALL_CARTS"
export const SET_CURRENT_CART = "SET_CURRENT_CART"
export const CHECKOUT_ORDER_VALIDATION = "CHECKOUT_ORDER_VALIDATION"

export const orderTypes = {
  1: "REGULAR",
  2: "DOM",
  4: "RetailAssortments",
  5: "PlanogramsCart",
  6: "AutoShip",
  7: "M4Success",
  8: "CloseOut",
  10: "MonthlySpecialCart",
};

export const addMsWarehouseAction = (
  msSubmitCart,
  cartId,
  product,
  rdcNumb
) => {
  return (dispatch, getState) => {
    let itemList = [];
    itemList =
      msSubmitCart &&
      msSubmitCart.map((msStore) => {
        const storeRdcNum = getState().SessionReducer?.multiStoreDetails?.childStoreDetails !== {} ?
          getState().SessionReducer.multiStoreDetails.childStoreDetails[msStore.storeID] : '';
        return {
          UserId: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          CartId: parseInt(cartId),
          ItemNumber: product.Item_Number || product?.pricing?.ItemNumber,
          userName: getState().SessionReducer.address.userName,
          ShoppingCartInfoTypeId: orderNames["REGULAR"].infoType,
          ItemQty: msStore.qty,
          OverrideRetail: msStore.overRideretail ? msStore.overRideretail : "",
          RDC: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : ( rdcNumb ? rdcNumb : getState().SessionReducer.rdc),
          ItemSource: "WH",
          TotalQtyInOrder: msStore.qty,
          MolUserName: getState().SessionReducer.userName,
          storeId: msStore.storeID,
          primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : ''
        };
      });
    utilities.setLoader(true);
    restUtils
      .postData(msWarehouseCart, itemList)
      .then((response) => {
        dispatch(getCartInfo(cartId));
        utilities.setLoader(false);
        let stockFlag = false;
        if(!_.isEmpty(response?.data?.itemList)){
          response.data.itemList.forEach((stock) => {
            if (stock.itemStockedAtRdc === true) {
              stockFlag = true;
            }
            return stock; // added this so the linter doesn't throw any errors.
          });
          if (stockFlag === false) {
            const toastMsg = response?.data?.Message && response.data.Message !== '' ? response.data.Message : "Item is NOT STOCKED. Add item from other warehouses"
            utilities.showToast(toastMsg);
            return;
          }
        }        
        utilities.showToast("Product Added to Cart");
        return dispatch({
          type: MS_WHDD_PRODUCT,
          payload: {
            items: itemList,
            cartid: cartId,
            cartInfo: infoTypes[parseInt(orderNames["REGULAR"].infoType)],
            typeInfo: orderNames["REGULAR"].infoType,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        utilities.setLoader(false);
      });
  };
};

export const createMsWarehouseAction = (
  cartName,
  poNum = "",
  ShoppingCartInfoTypeId = WH_ShoppingCartId,
  msSubmitCart,
  product,
  rdcNumb
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId: userId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId,
    };
    let cartId = "";
    let createCartPromise = restUtils
      .postData(createCartUrl, postObj)
      .then((response) => {
        utilities.showToast("Cart has been succesfully created!", false);
        dispatch(updateQty(true))
        return dispatch({
          type: CREATE_CART,
          payload: {
            cartId: response.data.ShoppingCartId,
            cartName,
            orderType: infoTypes[parseInt(ShoppingCartInfoTypeId)],
            poNum,
            TypeId: ShoppingCartInfoTypeId,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
    console.log(createCartPromise);
    createCartPromise.then((res) => {
      dispatch(
        addMsWarehouseAction(msSubmitCart, res.payload.cartId, product, rdcNumb)
      );
    });
  };
};

export const addMsImpulse = (msSubmitCart, cartId, itemListMap) => {
  return (dispatch, getState) => {
    itemListMap = itemListMap.map((res) => {
      return { ...res, CartId: cartId };
    });
    utilities.setLoader(true);
    restUtils
      .postData(msWarehouseCart, itemListMap)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Product Added to Cart");
        return dispatch({
          type: MS_WHDD_PRODUCT,
          payload: {
            items: itemListMap,
            cartid: cartId,
            cartInfo:
              infoTypes[parseInt(orderNames["ImpulseMerchandising"].infoType)],
            typeInfo: orderNames["ImpulseMerchandising"].infoType,
          },
        });
      })
      .catch((error) => {
        utilities.setLoader(false);
        console.log(error);
      });
  };
};

export const createMsImpulse = (
  cartName,
  poNum = "",
  ShoppingCartInfoTypeId = orderNames["ImpulseMerchandising"].infoType,
  msSubmitCart,
  itemListMap,
  rdcNumb
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId: userId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId,
    };
    let cartId = "";
    utilities.setLoader(true);
    let createCartPromise = restUtils
      .postData(createCartUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Cart has been succesfully created!", false);
        return dispatch({
          type: CREATE_CART,
          payload: {
            cartId: response.data.ShoppingCartId,
            cartName,
            orderType: infoTypes[parseInt(ShoppingCartInfoTypeId)],
            poNum,
            TypeId: ShoppingCartInfoTypeId,
          },
        });
      })
      .catch((error) => {
        utilities.setLoader(false);
        console.log(error);
      });
    console.log(createCartPromise);
    createCartPromise.then((res) => {
      dispatch(addMsImpulse(msSubmitCart, res.payload.cartId, itemListMap));
    });
  };
};

export const msSpecislMonthly = (
  msSubmitCart,
  product,
  rdc,
  flowType,
  shoppingAreaName,
  eventId,
  relayNbr,
  shoppingAreaMetaId
) => {
  shoppingAreaMetaId = parseInt(shoppingAreaMetaId) ? shoppingAreaMetaId : ''
  return (dispatch, getState) => {
    let itemList = [];
    if (parseInt(flowType) === 1) {
      itemList = msSubmitCart.map((msStore) => {
        return {
          userID: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          itemNum: product.Item_Number || product.itemNbr,
          molUserName: getState().SessionReducer.address.userName,
          userQty: msStore?.qty?.shipdate1,
          overRideretail: msStore.overRideretail ? msStore.overRideretail : "",
          userQty2: msStore?.qty?.shipdate2,
          userQty3: msStore?.qty?.shipdate3,
          userQty4: msStore?.qty?.shipdate4,
          vendorID: product.vendorID || product.vendorId,
          multiStoreParentUserId: getState().SessionReducer.UserId,
          RelayNbr: product.RelayNbr || product.relayNbr,
          promoNbr: product.PromoNbr || product.promoNbr,
          rdcNbr: rdc,
          shoppingName: shoppingAreaName,
          flowType: parseInt(flowType),
          shoppingIdMeta:shoppingAreaMetaId
        };
      });
    } else if (parseInt(flowType) === 2) {
      itemList = msSubmitCart.map((msStore) => {
        let msMonthQty =
          !_.isEmpty(msStore?.qty) &&
          Object.keys(msStore?.qty).map((key) => {
            return { month: key, quantity: msStore.qty[key] };
          });
        return {
          userId: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          molUserName: getState().SessionReducer.address.userName,
          itemNum: product && product.asstmtNbr,
          userQty: msStore?.qty?.shipdate1,
          type: "LR",
          promoNbr: parseInt(eventId),
          quantityList: msMonthQty,
          userAffiliate: true,
          multiStoreParentUserId: getState().SessionReducer.UserId,
          rdcNbr: rdc,
          shoppingName: shoppingAreaName,
          shoppingIdMeta:shoppingAreaMetaId,
          flowType: parseInt(flowType),
        };
      });
    } else if (parseInt(flowType) === 3) {
      itemList = msSubmitCart.map((msStore) => {
        return {
          userID: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          itemNum: product.Item_Number || product.itemNbr,
          molUserName: getState().SessionReducer.address.userName,
          userQty: msStore?.qty?.shipdate1,
          itemNum: product.palletNbr,
          userQty2: msStore?.qty?.shipdate2,
          userQty3: msStore?.qty?.shipdate3,
          userQty4: msStore?.qty?.shipdate4,
          vendorID: product.vendorID || product.vendorId,
          multiStoreParentUserId: getState().SessionReducer.UserId,
          RelayNbr: product.RelayNbr || product.relayNbr,
          promoNbr: product.PromoNbr || product.promoNbr,
          rdcNbr: rdc,
          shoppingName: shoppingAreaName,
          flowType: parseInt(flowType),
          shoppingIdMeta:shoppingAreaMetaId
        };
      });
    } else if (parseInt(flowType) === 4) {
      itemList = msSubmitCart.map((msStore) => {
        return {
          userID: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          itemNum: product.Item_Number || product.itemNbr,
          userQty: msStore?.qty,
          userQty2: "",
          userQty3: "",
          userQty4: "",
          molUserName: getState().SessionReducer.address.userName,
          modelNum: product.model,
          overRideretail: msStore.overRideretail ? msStore.overRideretail : "",
          vendorID: product.vendorID || product.vendorId,
          multiStoreParentUserId: getState().SessionReducer.UserId,
          RelayNbr: relayNbr,
          promoNbr: product.PromoNbr || product.promoNbr,
          rdcNbr: rdc,
          shoppingName: shoppingAreaName,
          flowType: parseInt(flowType),
          shoppingIdMeta:shoppingAreaMetaId
        };
      });
    } else if (parseInt(flowType) === 5) {
      itemList = msSubmitCart.map((msStore) => {
        let msMonthQty =
          !_.isEmpty(msStore?.qty) &&
          Object.keys(msStore?.qty).map((key) => {
            return { month: key, quantity: msStore.qty[key] };
          });
        return {
          userId: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          molUserName: getState().SessionReducer.address.userName,
          quantityList: msMonthQty,
          itemNum: product.palletNbr,
          vendorID: product.vendorID || product.vendorId,
          multiStoreParentUserId: getState().SessionReducer.UserId,
          modelNum: product.model,
          promoNbr: product.PromoNbr || product.promoNbr,
          rdcNbr: rdc,
          type: product.PromoNbr || product.promoNbr,
          shoppingName: shoppingAreaName,
          flowType: parseInt(flowType),
          relayNbr: relayNbr,
          shoppingIdMeta:shoppingAreaMetaId
        };
      });
    } else {
      itemList = msSubmitCart.map((msStore) => {
        return {
          userID: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          itemNum: product.Item_Number || product.itemNbr,
          molUserName: getState().SessionReducer.address.userName,
          userQty: msStore?.qty?.shipdate1,
          userQty2: msStore?.qty?.shipdate2,
          userQty3: msStore?.qty?.shipdate3,
          userQty4: msStore?.qty?.shipdate4,
          overRideretail: msStore.overRideretail ? msStore.overRideretail : "",
          vendorID: product.vendorID || product.vendorId,
          multiStoreParentUserId: getState().SessionReducer.UserId,
          RelayNbr: product.RelayNbr || product.relayNbr,
          promoNbr: product.PromoNbr || product.promoNbr,
          rdcNbr: rdc,
        };
      });
    }
    utilities.setLoader(true);
    restUtils
      .postData(
        parseInt(flowType) === 1 ||
          parseInt(flowType) === 3 ||
          parseInt(flowType) === 2 ||
          parseInt(flowType) === 4 ||
          parseInt(flowType) === 5
          ? msMonthlyspecialsReunion
          : msMonthlyspecials,
        itemList
      )
      .then((response) => {
        utilities.setLoader(false);
        dispatch(updateQty(true))
        utilities.showToast("Product Added to Cart");
        let { cartID } = response?.data[0];
        if (cartID) {
          return dispatch(viewAllCarts(cartID));
        }
      })
      .catch((error) => {
        utilities.setLoader(false);
      });
  };
};

export const updateQty =(val)=> ({type: UPDATE_QTY,payload: {updateQty: val}})

export const addMsPlanogram = (
  msSubmitCart,
  cartId = "",
  itemListMap,
  assortmentId
) => {
  return (dispatch, getState) => {
    let itemList = [];
    itemList =
      msSubmitCart &&
      msSubmitCart.map((msStore) => {
        return {
          UserId: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          multiStoreParentUserId: getState().SessionReducer.UserId,
          planogramNumber: assortmentId,
          primaryWarehouseNumber: getState().SessionReducer.rdc,
          MolUserName: getState().SessionReducer.address.userName,
          storeId: msStore.storeID,
          addItems: itemListMap,
        };
      });
    utilities.setLoader(true);
    restUtils
      .postData(msPlanogram, itemList)
      .then((response) => {
        utilities.setLoader(false);
        dispatch(updateQty(true))
        utilities.showToast("Product Added to Cart");
        const { data = {} } = response;
        const {
          shoppingCartId: cartId,
          planogramNumber: cartName,
          itemList,
        } = data[0];
        return dispatch({
          type: MS_WHDD_PRODUCT,
          payload: {
            items: itemList,
            cartid: cartId,
            cartName: cartName,
            cartInfo: "Planogram",
            typeInfo: orderNames["PLANOGRAM"].infoType,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        utilities.setLoader(false);
      });
  };
};

export const createMsPlanogram = (
  cartName,
  poNum = "",
  ShoppingCartInfoTypeId = orderNames["PLANOGRAM"].infoType,
  msSubmitCart,
  product,
  assortmentId
) => {
  return (dispatch, getState) => {
    dispatch(addMsPlanogram(msSubmitCart, "", product, assortmentId));
  };
};

export const addcartRE = (msSubmitCart, cartId, product) => {
  return (dispatch, getState) => {
    let itemList = [];
    itemList =
      msSubmitCart &&
      msSubmitCart.map((msStore) => {
        const storeRdcNum = getState().SessionReducer?.multiStoreDetails?.childStoreDetails !== {} ?
          getState().SessionReducer.multiStoreDetails.childStoreDetails[msStore.storeID] : '';
        return {
          UserId: utilities.getChildStoreUserId(
            getState().SessionReducer.multiStoreDetails,
            utilities.padWithZeros(msStore.storeID, 5)
          ),
          MolUserName: getState().SessionReducer.address.userName,
          userName: getState().SessionReducer.address.userName,
          ShoppingCartInfoTypeId: orderNames["DOM"].infoType,
          ItemNumber: product.Item_Number,
          ItemQty: msStore.qty,
          promo_nbr: product.promo_nbr,
          ItemSource: product.promo_nbr,
          cartId: cartId,
          OverrideRetail: msStore.overRideretail ? msStore.overRideretail : "",
          storeId: msStore.storeID,
          RDC: getState().SessionReducer.rdc,
          TotalQtyInOrder: msStore.qty,
          primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : ''
        };
      });
    utilities.setLoader(true);
    restUtils
      .postData(msWarehouseCart, itemList)
      .then((response) => {
        dispatch(getCartInfo(cartId));
        utilities.setLoader(false);
        utilities.showToast("Product Added to Cart");
        return dispatch({
          type: MS_WHDD_PRODUCT,
          payload: {
            items: itemList,
            cartid: cartId,
            cartInfo: infoTypes[parseInt(orderNames["DOM"].infoType)],
            typeInfo: orderNames["DOM"].infoType,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        utilities.setLoader(false);
      });
  };
};

export const createMsEvent = (
  cartName,
  poNum = "",
  ShoppingCartInfoTypeId = orderNames["DOM"].infoType,
  msSubmitCart,
  product,
  rdcNumb
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId: userId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId,
    };
    let cartId = "";
    utilities.setLoader(true);
    let createCartPromise = restUtils
      .postData(createCartUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Cart has been succesfully created!", false);
        return dispatch({
          type: CREATE_CART,
          payload: {
            cartId: response.data.ShoppingCartId,
            cartName,
            orderType: infoTypes[parseInt(ShoppingCartInfoTypeId)],
            poNum,
            TypeId: ShoppingCartInfoTypeId,
          },
        });
      })
      .catch((error) => {
        utilities.setLoader(false);
        console.log(error);
      });
    console.log(createCartPromise);
    createCartPromise.then((res) => {
      dispatch(addcartRE(msSubmitCart, res.payload.cartId, product));
    });
  };
};

export const addProduct = (
  cartId,
  itemno,
  qty,
  overrideretail = "",
  cost = "",
  orderType = "REGULAR",
  cartName = "",
  rdcNum = "",
  storeId,
  promoNbr
) => {
  return (dispatch, getState) => {
    let currentCart =
      getState().cart.orders.allCarts &&
      getState().cart.orders.allCarts.length &&
      getState().cart.orders.allCarts.find((cart) => {
        return (
          parseInt(getState().cart.orders.currentCart.cartId) ===
          parseInt(cart.cartId)
        );
      });
    let cartCount = currentCart ? currentCart.cartCount : 0;
    if (cartCount >= 999) {
      utilities.showToast("Cart items should not exceed 999");
      return;
    }
    storeId = getState().SessionReducer.address.StoreId;
    const storeRdcNum = getState().SessionReducer?.multiStoreDetails?.childStoreDetails !== {} ?
          getState().SessionReducer.multiStoreDetails.childStoreDetails[storeId] : '';
    const postObj = [
      {
        CartId: cartId,
        ItemNumber: itemno,
        ItemQty: qty,
        OverrideRetail: overrideretail,
        RDC: utilities.isEmptyOrNullString(rdcNum)
          ? getState().SessionReducer.rdc
          : rdcNum,
        ItemSource: orderType !== "DOM" ? orderNames[orderType].key : promoNbr,
        TotalQtyInOrder: qty,
        userName: getState().SessionReducer.address.userName,
        storeId,
        primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : ''
      },
    ]; //passing item in an array since api accepts array of items
    utilities.setLoader(true);
    restUtils
      .postData(addProductUrl, postObj)
      .then((response) => {
        let data = response.data;
        utilities.setLoader(false);
        if (data.orderAlreadySubmitted) {
          utilities.showToast(
            "Cart you are trying to add is already submitted, Please create a new cart"
          );
          return;
        } else if (data.itemList[0].itemStockedAtRdc !== true) {
          const toastMsg = data.Message && data.Message !== '' ? data.Message : "Item is NOT STOCKED. Add item from other warehouses"
          utilities.showToast(toastMsg);
          return;
        } else if (data.allItemsAddedToCart) {
          utilities.showToast("Product added to cart");
          return dispatch({
            type: ADD_PRODUCT,
            payload: {
              itemno,
              ItemQty: qty,
              itemQty: qty,
              cost,
              orderType,
              cartName,
              cartId,
              rdcNum,
              storeId,
              or: overrideretail,
            },
          });
        }
      })
      .catch((err) => {
        console.log("Error", err);
        utilities.setLoader(false);
      });
  };
};
export const classicAddProduct = (postObj, cartId, cartName = '', listType) => {
  let payload = postObj && postObj.map((ele, index) => ele[0])
  if(cartId) {
    payload = payload.map((ele) => {
       ele.CartId = cartId;
       return ele
    })
  }
  return (dispatch, getState) => {
    let currentCart =
      getState().cart.orders.allCarts &&
      getState().cart.orders.allCarts.length &&
      getState().cart.orders.allCarts.find((cart) => {
        return (
          parseInt(getState().cart.orders.currentCart.cartId) ===
          parseInt(cart.cartId)
        );
      });
    let cartCount = currentCart ? currentCart.cartCount : 0;
    if (cartCount >= 999) {
      utilities.showToast("Cart items should not exceed 999");
      return;
    }
    let storeId = getState().SessionReducer.address.StoreId;
    utilities.setLoader(true);
    restUtils
      .postData(addProductUrl, payload)
      .then((response) => {
        let data = response.data;
        utilities.setLoader(false);
        if (data.orderAlreadySubmitted) {
          utilities.showToast(
            "Cart you are trying to add is already submitted, Please create a new cart"
          );
          return;
        } else if (data.itemList[0].itemStockedAtRdc !== true) {
          const toastMsg = data.Message && data.Message !== '' ? data.Message : "Item is NOT STOCKED. Add item from other warehouses"
          utilities.showToast(toastMsg);
          return;
        } else if (data.allItemsAddedToCart) {
          utilities.showToast("Product added to cart");
          return postObj && postObj.map(ele => dispatch({
            type: ADD_PRODUCT,
            payload: {
              itemno: ele[0].ItemNumber,
              ItemQty: ele[0].ItemQty,
              itemQty: ele[0].ItemQty,
              cost: listType && listType == 'DOM' ?ele[1].product.spc_cost: ele[1].product.Member_Cost,
              orderType: infoTypes[1],
              cartName,
              cartId: ele[0].CartId,
              rdcNum: ele[0].RDC,
              storeId,
              or: ele[0].OverrideRetail,
            },
          }))
        }
      })
      .catch((err) => {
        console.log("Error", err);
        utilities.setLoader(false);
      });
  };
  };
export const addManyProducts = (cartId, cost = [], itemsList = []) => {
  return (dispatch, getState) => {
    let currentCart =
      getState().cart.orders.allCarts &&
      getState().cart.orders.allCarts.length &&
      getState().cart.orders.allCarts.find((cart) => {
        return (
          parseInt(getState().cart.orders.currentCart.cartId) ===
          parseInt(cart.cartId)
        );
      });
    let cartCount = currentCart ? currentCart.cartCount : 0;
    if (cartCount >= 999) {
      utilities.showToast("Cart items should not exceed 999");
      return;
    }
    utilities.setLoader(true);
    restUtils
      .postData(addManyProductsUrl, itemsList)
      .then((response) => {
        utilities.setLoader(false);
        let data = response.data;
        if (data.orderAlreadySubmitted) {
          utilities.showToast(
            "Cart you are trying to add is already submitted, Please create a new cart"
          );
          return;
        } else if (data.itemList) {
          let stockFlag = false;
          data.itemList.map((stock) => {
            if (stock.itemStockedAtRdc === true) {
              stockFlag = true;
            }
            return stock; // added this so the linter doesn't throw any errors.
          });
          if (stockFlag === false) {
            const toastMsg = data.Message && data.Message !== '' ? data.Message : "Item is NOT STOCKED. Add item from other warehouses"
            utilities.showToast(toastMsg);
            return;
          } else {
            utilities.showToast("Product(s) added to cart!");
            const items = itemsList.map((item, i) => {
              return {
                itemno: item.ItemNumber,
                itemQty: item.ItemQty,
                rdc: item.RDC,
                item_nbr: item.ItemNumber,
                rdcNum: item.RDC,
                cost: cost[i],
              };
            });
            return dispatch({
              type: ADD_MANY_PRODUCTS,
              payload: { cartId, items },
            });
          }
        }
      })
      .catch((err) => {
        console.log("error", err);
        utilities.setLoader(false);
      });
  };
};

export const createNAddMany = (
  cost = [],
  itemsList = [],
  ShoppingCartInfoTypeId = WH_ShoppingCartId,
  cartName
) => {
  const Cartname = cartName
    ? cartName
    : ShoppingCartInfoTypeId === WH_ShoppingCartId
    ? "Default"
    : orderTypes[parseInt(ShoppingCartInfoTypeId)];
  return (dispatch, getState) => {
    const UserId = getState().SessionReducer.UserId;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId,
      MolUserName: userName,
      userName,
      Cartname,
      ShoppingCartInfoTypeId,
    };
    let cartId = "";
    utilities.setLoader(true);
    let createCartPromise = restUtils
      .postData(createNAddManyUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Cart created successfully");
        cartId = response.data.ShoppingCartId;
        return dispatch({
          type: CREATE_CART,
          payload: {
            cartId,
            cartName: Cartname,
            orderType: infoTypes[parseInt(ShoppingCartInfoTypeId)],
            poNum: "",
            TypeId: ShoppingCartInfoTypeId,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        utilities.setLoader(false);
      });
    createCartPromise.then(() => {
      itemsList = itemsList.map((item) => {
        return { ...item, CartId: cartId };
      });
      dispatch(addManyProducts(cartId, cost, itemsList));
    });
  };
};

export const createNAddCartMonthly = (
  itemno,
  qty,
  overrideretail,
  rdc = "",
  qty2,
  qty3,
  qty4,
  RelayNbr,
  promoNbr,
  vendorID,
  cartID
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const RDC = utilities.isEmptyOrNullString(rdc)
      ? getState().SessionReducer.rdc
      : rdc;
    const userName = getState().SessionReducer.address.userName;
    dispatch(
      addMonthlySpecialCart({
        userId,
        itemNum: itemno,
        userQty: qty,
        userQty2: qty2,
        userQty3: qty3,
        userQty4: qty4,
        vendorID,
        RelayNbr,
        promoNbr,
        overrideRetail: overrideretail,
        RDC,
        userName,
        cartID,
      })
    );
  };
};

export const createNAddCart = (
  itemno,
  qty,
  overrideretail = "",
  cost = "",
  orderType = "REGULAR",
  ShoppingCartInfoTypeId = WH_ShoppingCartId,
  cartName = "Default",
  rdc = "",
  qty2,
  qty3,
  qty4,
  relay_nbr,
  Vendor_Id,
  promoNbr
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const RDC = utilities.isEmptyOrNullString(rdc)
      ? getState().SessionReducer.rdc
      : rdc;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId: userId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId,
      userName: userName,
    };
    let cartId = "";
    utilities.setLoader(true);
    let createCartPromise = restUtils
      .postData(createNAddCartUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Cart created successfully");
        dispatch(updateQty(true))
        cartId = response.data.ShoppingCartId;
        return dispatch({
          type: CREATE_CART,
          payload: {
            cartId,
            cartName,
            orderType,
            poNum: "",
            TypeId: ShoppingCartInfoTypeId,
          },
        });
      })
      .catch((error) => {
        utilities.setLoader(false);
        console.log(error);
      });
    createCartPromise.then(() => {
      if (parseInt(ShoppingCartInfoTypeId) === 9) {
        dispatch(
          addReunionCartProduct({
            CartId: parseInt(cartId),
            ItemNumber: itemno,
            ItemQty: qty,
            ItemQty2: qty2,
            ItemQty3: qty3,
            ItemQty4: qty4,
            ItemSource: promoNbr,
            Vendor_Id,
            relay_nbr,
            OverrideRetail: overrideretail,
            RDC,
            userName,
          })
        );
      } else {
        dispatch(
          addProduct(
            cartId,
            itemno,
            qty,
            overrideretail,
            cost,
            orderType,
            cartName,
            RDC,
            "",
            relay_nbr
          )
        );
      }
    });
  };
};

export const classicCreateNAddCart = (
  products,
  orderType = "REGULAR",
  ShoppingCartInfoTypeId = WH_ShoppingCartId,
  cartName = "Default",
  listType = null
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId: userId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId,
      userName: userName,
    };
    let cartId = "";
    utilities.setLoader(true);
    let createCartPromise = restUtils
      .postData(createNAddCartUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Cart created successfully");
        cartId = response.data.ShoppingCartId;
        return dispatch({
          type: CREATE_CART,
          payload: {
            cartId,
            cartName,
            orderType,
            poNum: "",
            TypeId: ShoppingCartInfoTypeId,
          },
        });
      })
      .catch((error) => {
        utilities.setLoader(false);
        console.log(error);
      });
    createCartPromise.then(() => {
      if (parseInt(ShoppingCartInfoTypeId) === 9) {
      } else {
        dispatch(
          classicAddProduct(products,cartId,cartName,listType)
         
        );
      }
    });
  };
  };

export const addProductOrderPad = (postObjOrderPad, costs) => {
  return (dispatch) => {
    utilities.setLoader(true);
    restUtils
      .postData(addProductOrderPadUrl, postObjOrderPad)
      .then((response) => {
        utilities.setLoader(false);
        utilities.showToast("Product(s) added to cart");
        const items = postObjOrderPad.map((product) => {
          return {
            ...product,
            item_nbr: product.ItemNumber,
            itemQty: product.ItemQty,
            rdcNum: product.RDC,
            cost: product.retailCost,
          };
        });
        return dispatch({
          type: ADD_MANY_PRODUCTS,
          payload: { cartId: postObjOrderPad[0].CartId, items },
        });
      })
      .catch((err) => {
        console.log(err);
        utilities.setLoader(false);
        return dispatch({
          type: ERROR,
        });
      });
  };
};

export const addItemstoCartPlanogram = (postObjOrderPad, costs) => {
  return (dispatch) => {
    utilities.setLoader(true);
    restUtils
      .postData(addItemstoCartPlanogramUrl, [postObjOrderPad])
      .then((response) => {
        utilities.setLoader(false);
        dispatch(updateQty(true))
        Event("CART", "Planogram cart created and planogram added to cart");
        utilities.showToast("Planogram added to cart");
        const data = response?.data?.[0];
        const {
          shoppingCartId: cartId,
          planogramNumber: cartName,
          itemList,
        } = data;
        dispatch({
          type: ADD_MANY_PRODUCTS,
          payload: {
            cartId,
            cartName,
            items: itemList.map((item) => {
              return {
                item_nbr: item.Item_Number,
                qty: item.itemQty,
                cost: item.Member_Cost,
              };
            }),
          },
        });
        return dispatch(viewAllCarts(cartId));
      })
      .catch((err) => {
        utilities.setLoader(false);
        console.log(err);
        return dispatch({
          type: ERROR,
        });
      });
  };
};

export const createNAddCartOrderPad = (
  orderType = "REGULAR",
  ShoppingCartInfoTypeId = WH_ShoppingCartId,
  cartName = 'OrderPad'
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId: userId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId,
      userName: userName,
    };
    let cartId = "";
    utilities.setLoader(true);
    return restUtils.postData(createNAddCartOrderPadUrl, postObj).then((response) => {
      utilities.setLoader(false);
      cartId = response.data.ShoppingCartId;
      return dispatch({
        type: CREATE_CART,
        payload: {
          cartId,
          cartName: cartName,
          orderType,
          poNum: "",
          TypeId: ShoppingCartInfoTypeId,
          userName,
        },
      });
    }).catch(error=>{
      utilities.setLoader(false);
    });
  };
};

export const addAssortmentProduct = (
  itemno,
  qty,
  orderType,
  RDC,
  userName,
  userId,
  storeId,
  RAcost,
  ctbAssortment,
  serialNumber,
  missing
) => {
  return (dispatch) => {
    let quantityList = [];
    if (qty.length !== 0 && typeof qty === "object") {
      quantityList = qty.map((key) => ({
        month: key.month,
        ship_date: key.ship_date,
        quantity: parseInt(key.qty),
        ...(ctbAssortment ? {"isMisingSelected": [0, 2].includes(parseInt(missing)) ? true: false}:null)
      }));
    } else {
      quantityList = [
        {
          month: "",
          quantity: parseInt(qty)
        },
      ]
    }
    const postObj = {
      storeId: storeId,
      primaryWarehouseNbr: RDC,
      assortment: {
        number: ctbAssortment?parseInt(serialNumber):parseInt(itemno),
        type: orderType,
      },
      quantityList: quantityList,
      userId: parseInt(userId),
      molUserName: userName,
      userAffiliate: true,
      ...(ctbAssortment ? {type:'CTB'}: null)
    };
    utilities.setLoader(true);
    restUtils
      .postData(addRACartProductUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        const {
          shoppingCartId: cartId,
          shoppingCartName: cartName,
          assortmentsInCart,
        } = response.data;
        utilities.showToast("Assortment added to cart");

        return dispatch({
          type: ADD_MANY_PRODUCTS,
          payload: {
            cartId,
            cartName,
            items: assortmentsInCart.map((item) => {
              return {
                ItemNumber: item.number,
                qty: item.userQty,
                RDC: RDC,
                rdcNum: RDC,
                item_nbr: item.number,
                itemQty: item.userQty,
                cost: RAcost,
              };
            }),
          },
        });
      })
      .catch((error) => {
        console.log(error);
        utilities.setLoader(false);
      });
  };
};

export const addReunionCartProduct = (itemDetails) => {
  return (dispatch) => {
    utilities.setLoader(true);
    restUtils
      .postData(addReunionCartProductUrl, [itemDetails])
      .then((response) => {
        utilities.setLoader(false);
        Event("CART", "Items added to cart");
        utilities.showToast("Product added to cart");
        return dispatch({
          type: ADD_PRODUCT,
          payload: {
            itemno: itemDetails.ItemNumber,
            userQty: itemDetails.ItemQty,
            userQty2: itemDetails.ItemQty2,
            userQty3: itemDetails.ItemQty3,
            userQty4: itemDetails.ItemQty4,
            cost: "",
            orderType: itemDetails.ItemSource,
            cartName: "Reunion",
            cartId: itemDetails.CartId,
            rdcNum: itemDetails.RDC,
            or: itemDetails.Overrideretail,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        utilities.setLoader(false);
      });
  };
};

export const addMonthlySpecialCart = (itemDetails, costs) => {
  return (dispatch, getState) => {
    utilities.setLoader(true);
    restUtils
      .postData(MONTHLYSPECIAL_ADDTOCART, itemDetails)
      .then((response) => {
        utilities.setLoader(false);
        Event("CART", "Monthly Specials cart Created");
        utilities.showToast("Product added to cart");
        dispatch({
          type: ADD_PRODUCT,
          payload: {
            itemno: itemDetails.ItemNumber,
            userQty: itemDetails.ItemQty,
            userQty2: itemDetails.ItemQty2,
            userQty3: itemDetails.ItemQty3,
            userQty4: itemDetails.ItemQty4,
            cost: "",
            orderType: itemDetails.ItemSource,
            cartName: "Monthly Specials",
            cartId: response.data.cartID,
            rdcNum: itemDetails.RDC,
            overrideRetail: itemDetails.Overrideretail,
          },
        });
        return dispatch(viewAllCarts(response.data.cartID));
      })
      .catch((error) => {
        console.log(error);
        utilities.setLoader(false);
      });
  };
};

export const chooseCart = (cart) => ({
  type: CHOOSE_CART,
  payload: cart,
});

export const checkout = (cartId, checkoutDetails) => ({
  type: CHECKOUT,
  payload: { cartId, checkoutDetails },
});

export const deleteCart = (cartId, parentId=0, isExist, checkout=false) => {
  return (dispatch) => {
    let postObj = {
      ShoppingCartId: cartId,
    };
    if (isExist) {
      if (cartId !== parentId) {
        postObj = {
          ...postObj,
          isChildOnly: true,
          parentId:
            !parentId || parentId === -1 || parentId === "" ? 0 : parentId,
        };
      } else {
        postObj = {
          ...postObj,
          parentId:
            !parentId || parentId === -1 || parentId === "" ? 0 : parentId,
        };
      }
    } else {
      postObj = {
        ...postObj,
        parentId:
          !parentId || parentId === -1 || parentId === "" ? 0 : parentId,
      };
    }
    utilities.setLoader(true);
    restUtils
      .deleteData(deleteCartUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        Event("CART", "Cart Deleted");
        utilities.showToast(`Cart deleted successfully!`);
        dispatch({
          type: DELETE_CART,
          payload: { cartId, parentId },
        });
        // if(checkout){
        //   history.push('/cart'); 
        // }
        return dispatch(viewAllCarts());
      })
      .catch((err) => {
        utilities.setLoader(false);
        console.log(err);
      }).finally(()=>{
        if(checkout){
          history.push('/cart'); 
        }
        });
  };
};

export const removeProduct = (
  cartId,
  itemno,
  RDC,
  month,
  model,
  vendorId,
  cartTypeId,
  cart
) => {
  return (dispatch,getState) => {
    const postObj = {
      CartId: cartId,
      ItemNumber: itemno,
      RDC,
      month: month ? month : "",
      modelNum: model ? model : "",
      Vendor_Id: vendorId ? vendorId : "",
      cartTypeId: cartTypeId ? cartTypeId : "",
    };
    const allCarts = getState().cart.orders.allCarts;
    utilities.setLoader(true);
    restUtils
      .deleteData(removeProductUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        Event("CART", "Items removed from cart :" + itemno);
        dispatch({
          type: REMOVE_PRODUCT,
          payload: { cartId, itemno, RDC, month, model, vendorId, cartTypeId },
        });
        if(cart.TypeId === 201) {
          const reunionAssortmentCart = allCarts.filter(ele=>ele.parentId === cart.parentId)
          const getCartIds = reunionAssortmentCart.map(item => item.cartId)
          return dispatch(getViewAllCarts(getCartIds));
        }
        return dispatch({
          type:UPDATE_TOTALCOST,
          payload:{
            cartId: cartId,
            itemno,
            totalCost:
            cart.TypeId === 2
              ? computePromoTotalCost(cart.items)
              : isShoppingAreaCart(cart.cartInfoName) || cart.TypeId === 10
              ? computeShipweekTotalCost(cart.items)
              : computeTotalCost(cart.items),
          }
        }) 
       // return dispatch(viewAllCarts());
      })
      .catch((err) => {
        utilities.setLoader(false);
        console.log(err);
      });
  };
};

export const updateQuantity = (
  cartId,
  or,
  itemno,
  qty,
  RDC,
  ItemSource,
  fieldName = "ItemQty",
  otherQties,
  relay_nbr,
  Vendor_Id,
  modelNum,
  cartTypeId,
  month,
  cart,
  getprefer
) => {
  return (dispatch, getState) => {
    const storeId = getState()?.SessionReducer?.address?.StoreId;
    const storeRdcNum = getState().SessionReducer?.multiStoreDetails?.childStoreDetails !== {} ?
          getState().SessionReducer.multiStoreDetails.childStoreDetails[storeId] : '';
    const viewType = getprefer;
    let postObj = {
      CartId: cartId,
      ItemNumber: itemno,
      [fieldName]: utilities.zeroForNaN(qty),
      OverrideRetail: or,
      RDC,
      ItemSource,
      TotalQtyInOrder: qty,
      userName: getState().SessionReducer.address.userName,
      modelNum,
      cartTypeId,
      month,
      primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : '',
    };
    if (relay_nbr) {
      postObj = { ...postObj, relay_nbr };
    }
    if (Vendor_Id) {
      postObj = { ...postObj, Vendor_Id };
    }
    if (otherQties) {
      postObj = { ...postObj, ...otherQties };
    }
    utilities.setLoader(true);
    restUtils
      .postData(updateQuantityUrl, [postObj])
      .then((response) => {
        utilities.setLoader(false);
        Event("CART", "Items quantity updated");
        dispatch({
          type: UPDATE_QUANTITY,
          payload: { cartId, itemno, [fieldName]: utilities.zeroForNaN(qty), or, wh: cartTypeId > 100 || cart.TypeId === 10 ? 'N' : 'Y',month, updateQty:true },
        });
        dispatch({
          type: SWITCH_VIEW,
          payload:viewType
        })
        return (
          dispatch({
            type:UPDATE_TOTALCOST,
            payload:{
              cartId: cart.cartId,
              itemno,
              totalCost:
              cart.TypeId === 2
                ? computePromoTotalCost(cart.items)
                : isShoppingAreaCart(cart.cartInfoName) || cart.TypeId === 10
                ? computeShipweekTotalCost(cart.items)
                : computeTotalCost(cart.items),
            }
          }) 
        )
      })
      .catch((err) => {
        utilities.setLoader(false);
        console.log(err);
      });
  };
};

export const updateCloseOutCornerQuantity = (
  cartId,
  itemno,
  qty,
  RDC,
  relay_nbr,
  Vendor_Id,
  eventId,
  modelNum,
  shoppingAreaMetaId,
  or
) => {
  shoppingAreaMetaId = parseInt(shoppingAreaMetaId) ? shoppingAreaMetaId : ''
  return (dispatch, getState) => {
    let postObj = {
      userId: getState()?.SessionReducer.UserId,
      itemNum: itemno.toString(),
      userQty: qty,
      userQty2: '',
      userQty3: '',
      userQty4: '',
      vendorID: Vendor_Id,
      RelayNbr: relay_nbr,
      promoNbr: eventId,
      rdcNbr: RDC,
      molUserName: getState().SessionReducer.address.userName,
      flowType: 1,
      shoppingName: 'Closeout',
      shoppingIdMeta:shoppingAreaMetaId,
      modelNum: modelNum,
      overrideRetail: or
    };
    utilities.setLoader(true);
    restUtils
      .postData(MARKET_ADD_TO_CART, postObj)
      .then((response) => {
        utilities.setLoader(false);
        Event("CART", "Items quantity updated");
        dispatch({
          type: UPDATE_QUANTITY,
          payload: { cartId, itemno, itemQty: qty, or },
        });
        return dispatch(viewAllCarts());
      })
      .catch((err) => {
        utilities.setLoader(false);
        console.log(err);
      });
  };
};

export const updateOr = (cartId, itemQty, itemno, or, RDC, ItemSource) => {
  return (dispatch, getState) => {
    const storeId = getState()?.SessionReducer?.address?.StoreId;
    const storeRdcNum = getState().SessionReducer?.multiStoreDetails?.childStoreDetails !== {} ?
          getState().SessionReducer.multiStoreDetails.childStoreDetails[storeId] : '';
    const postObj = [
      {
        CartId: cartId,
        ItemNumber: itemno,
        ItemQty: parseInt(itemQty),
        OverrideRetail: or,
        RDC,
        ItemSource,
        TotalQtyInOrder: itemQty,
        userName: getState().SessionReducer.address.userName,
        primaryWarehouseNum: storeRdcNum && storeRdcNum.primaryWarehouseNum ? storeRdcNum.primaryWarehouseNum : '',
      },
    ];
    utilities.setLoader(true);
    restUtils
      .postData(updateOrUrl, postObj)
      .then((response) => {
        utilities.setLoader(false);
        Event("CART", "Items override retail updated");
        return dispatch({
          type: UPDATE_QUANTITY,
          payload: { cartId: cartId, itemno, itemQty, or },
        });
      })
      .catch((err) => {
        utilities.setLoader(false);
        console.log(err);
      });
  };
};

export const updateShipTo = (cartId, shipTo = "store") => ({
  type: UPDATE_SHIP_TO,
  payload: { cartId, shipTo },
});

export const setQuantity = (cartName, item) => ({
  type: SET_QUANTITY,
  payload: { cartName: cartName, itemno: item },
});

export const setLoader = (isFetchingData) => ({
  type: IS_FETCHING_DATA,
  payload: isFetchingData,
});
export const computePromoTotalCost = (items) => {
  return parseFloat(items.reduce(promoItemReducer, 0)).toFixed(2);
};
export const computeTotalCost = (items) => {
  return parseFloat(
    items.reduce(
      (item1, item2) =>
        parseFloat(item1) +
        parseFloat(item2.itemQty ? utilities.isBinLabel(item2.itemQty) ? 0 : item2.itemQty : utilities.isBinLabel(item2.userQty) ? 0 : item2.userQty ) *
          parseFloat(
            utilities.getCostForQtyPlpNew(
              item2,
              item2.itemQty ? utilities.isBinLabel(item2.itemQty) ? 0 : item2.itemQty : utilities.isBinLabel(item2.userQty) ? 0 : item2.userQty 
            )
          ),
      0
    )
  ).toFixed(2);
};
export const computeShipweekTotalCost = (items, cartInfoName = '') => {
  const flowType = shoppingAreaFlowTypes?.[cartInfoName];
  // For reuion retail assortment (flowtype = 2) we need to caluclated the total based on cost attribute - NWE-2601
  return parseFloat(
    items.reduce((item1, item2) => {
      const qty =
        utilities.zeroForNull(item2.userQty) +
        utilities.zeroForNull(item2.userQty2) +
        utilities.zeroForNull(item2.userQty3) +
        utilities.zeroForNull(item2.userQty4);
      return parseFloat(item1) + parseFloat(qty) * parseFloat(flowType && flowType === 2 ? item2.cost : item2.promoCost);
    }, 0)
  ).toFixed(2);
};

export const computeAssortmentTotalCost = (items) => {
  return parseFloat(
    items.reduce((item1, item2) => {
      const qty = utilities.zeroForNull(item2.userQty)
      return parseFloat(item1) + parseFloat(utilities.isBinLabel(qty) ? 0 : qty) * parseFloat(item2.cost);
    }, 0)
  ).toFixed(2);
};

export const getCartInfo = (cartId) => {
  return (dispatch, getState) => {
    let userId = getState()?.SessionReducer.UserId;
    const multiStoreDetails = getState()?.SessionReducer?.multiStoreDetails;
    if (multiStoreDetails?.childStoreList && Object.keys(multiStoreDetails?.childStoreList).length > 1) {
      let userIds = Object.keys(multiStoreDetails?.childStoreDetails).map(
        (key) => {
          return multiStoreDetails.childStoreDetails[key].userID;
        }
      );
      userId = userIds.join(",");
    }
    restUtils
      .getData(
        GET_CART_INFO+userId
      )
      .then((response) => {
        const allCarts = response.data;
        dispatch({
          type: VIEW_ALL_CARTS,
          payload: {
            setCurrentCart: cartId,
            carts: allCarts.map((cart) => {
              let newCart = cart
              let createArr = []
              for(let i = 0; i < cart.itemCount; i++) {
                  createArr.push({})
              }
              newCart = {
                ...cart,
                items: createArr
              }
              return {
                ...newCart,
                shipTo: "Store",
                cartCount: cart.itemCount,
                isLoad: true
              };
            }),
          },
        });
        if(multiStoreDetails?.childStoreList && Object.keys(multiStoreDetails?.childStoreList).length > 1) {
          dispatch(updateQty(true))
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: IS_FETCHING_DATA,
          payload: false,
          });
      });
  };
};

export const viewAllCarts = (cartId) => {
  return (dispatch, getState) => {
    let userId = getState()?.SessionReducer.UserId;
    const multiStoreDetails = getState()?.SessionReducer?.multiStoreDetails;
    if (multiStoreDetails?.childStoreList && Object.keys(multiStoreDetails?.childStoreList).length > 1) {
      let userIds = Object.keys(multiStoreDetails?.childStoreDetails).map(
        (key) => {
          return multiStoreDetails.childStoreDetails[key].userID;
        }
      );
      userId = userIds.join(",");
    }
    restUtils
      .getData(
        GET_CART_INFO+userId
      )
      .then((response) => {
        const allCarts = response.data;
        dispatch({
          type: VIEW_ALL_CARTS,
          payload: {
            setCurrentCart: cartId,
            carts: allCarts.map((cart) => {
              let newCart = cart
              let createArr = []
              for(let i = 0; i < cart.itemCount; i++) {
                  createArr.push({})
              }
              newCart = {
                ...cart,
                items: createArr
              }
              return {
                ...newCart,
                shipTo: "Store",
                cartCount: cart.itemCount,
                isLoad: true
              };
            }),
          },
        });
        if(multiStoreDetails?.childStoreList && Object.keys(multiStoreDetails?.childStoreList).length > 1) {
          dispatch(updateQty(true))
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: IS_FETCHING_DATA,
          payload: false,
          });
      });
  };
};

export const getViewAllCarts = (cartId, exportCart) => {
  return (dispatch, getState) => {
    let userId = getState()?.SessionReducer.UserId;
    const storeId = getState()?.SessionReducer.storeId;
    const multiStoreDetails = getState()?.SessionReducer?.multiStoreDetails;
    const rdc = getState()?.SessionReducer.rdc;
    const zoneCode = utilities.emptyForNull(
      getState()?.SessionReducer?.multiStoreDetails?.zoneCode
    );
    const viewType= getState()?.navbar.userMenu.viewType;
    const getprefer = getState()?.preference.listData;
    const marketFlag= getState()?.cart.orders.raMarketFlag
    const marketDate= getState()?.cart.orders.eventDate
    const aliasSKURetailerGrpID = getState()?.SessionReducer?.address?.aliasSKURetailerGrpID;
    const timestampCart = Date.now();
    if (multiStoreDetails?.childStoreList && Object.keys(multiStoreDetails?.childStoreList).length > 1) {
      let userIds = Object.keys(multiStoreDetails?.childStoreDetails).map(
        (key) => {
          return multiStoreDetails.childStoreDetails[key].userID;
        }
      );
      userId = userIds.join(",");
    }
    utilities.setLoader(true);
    restUtils
      .getData(
        viewAllCartsUrl+'?cartIds='+cartId.join(',')+'&userID='+userId +
          "&storeID=" +
          storeId +
          "&memberRDC=" +
          rdc +
          "&aliasSKURetailerGrpID=" +
          aliasSKURetailerGrpID +
          "&cartTimestamp=" +
          timestampCart +
          "&zoneCode=" +
          zoneCode
      )
      .then((response) => {
        let allCarts = response.data;
        utilities.setLoader(false);
        dispatch({
          type: GET_VIEW_ALL_CARTS,
         // setCurrentCart: cartId,
          payload: {
            carts: allCarts.map((cart) => {
              return {
                ...cart,
                shipTo: "Store",
                cartCount: cart.items.length,
                totalCost:
                 cart.TypeId === 2
                    ? computePromoTotalCost(cart.items)
                    : isShoppingAreaCart(cart.cartInfoName) || cart.TypeId === 10
                    ? computeShipweekTotalCost(cart.items)
                    : computeTotalCost(cart.items),
              };
            }),
          },
        });
        dispatch({
          type: SWITCH_VIEW,
          payload:viewType
        })
        if(exportCart) {
          cartExcelExport(
            allCarts,
            getprefer,
            viewType,
            aliasSKURetailerGrpID,
            marketFlag,
            marketDate,
            storeId
          )
        }
        if(multiStoreDetails?.childStoreList && Object.keys(multiStoreDetails?.childStoreList).length > 1) {
          dispatch(updateQty(true))
        }
        utilities.setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        utilities.setLoader(false);
        dispatch({
          type: IS_FETCHING_DATA,
          payload: false,
        });
        utilities.setLoader(false);
      });
  };
};
export const notifyCarts = () => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const postObj = {
      UserId: userId,
    };
    restUtils.postDataWithoutToken(notifyUrl, postObj).then((response) => {
      return dispatch({
        type: NOTIFY_CART,
        payload: {
          notify:
            response.data === "0" ||
            response.data === "" ||
            response.data === undefined
              ? false
              : true,
        },
      });
    });
  };
};

export const MarketFLAG = () => {
  return (dispatch) => {
    restUtils.getData(EVENT_ENABLE).then((response) => {
      return dispatch({
        type: MARKET_ENABLE,
        payload: {
          marketData: response.data,
        },
      });
    });
  };
};

export const ReunionOrdering = (storeId = '') => {
  return (dispatch) => {
    restUtils
      .getData(GET_CONFIG + `?configKey=REUNION,RA,CTBRA,MS3,MOMENTUM,COC&storeId=${storeId}`)
      .then(response => {
        return dispatch({
          type: REUNION_ORDERING_ENABLE,
          payload: {
            reunionOrderingEnable: response.data
          }
        })
      });
  };
};

export const createCart = (
  cartName,
  orderType = "REGULAR",
  poNum = "",
  ShoppingCartInfoTypeId = WH_ShoppingCartId
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    const userName = getState().SessionReducer.address.userName;
    const postObj = {
      UserId: userId,
      MolUserName: userName,
      Cartname: cartName,
      ShoppingCartInfoTypeId,
    };
    utilities.setLoader(true);
    restUtils.postData(createCartUrl, postObj).then((response) => {
      utilities.setLoader(false);
      utilities.showToast("Cart has been succesfully created!", false);
      return dispatch({
        type: CREATE_CART,
        payload: {
          cartId: response.data.ShoppingCartId,
          cartName,
          orderType,
          poNum,
          TypeId: ShoppingCartInfoTypeId,
        },
      });
    }).catch(error=>{
      utilities.setLoader(false);
    });
  };
};

export const editCartName = (
  cartId,
  ShoppingCartInfoTypeId,
  cartName,
  parentId
) => {
  return (dispatch, getState) => {
    const userId = getState().SessionReducer.UserId;
    let postObj = {};
    let isMultistoreCheck =
      utilities.msExist(getState().SessionReducer.multiStoreDetails) > 1;
    if (isMultistoreCheck) {
      postObj = {
        ID: parseInt(cartId),
        parentId: parseInt(parentId),
        shoppingCartInfoTypeID: ShoppingCartInfoTypeId,
        userId,
        cartName,
      };
    } else {
      postObj = {
        ID: parseInt(cartId),
        shoppingCartInfoTypeID: ShoppingCartInfoTypeId,
        userId,
        cartName,
      };
    }
    utilities.setLoader(true);
    restUtils.postData(editCartNameUrl, postObj).then((response) => {
      utilities.setLoader(false);
      Event("CART", "Cart name updated");
      utilities.showToast("Cart name changed succesfully!", false);
      dispatch({
        type: UPDATE_CART_NAME,
        payload: {
          cartId,
          cartName,
        },
      });
      //return dispatch(viewAllCarts());
    }).catch(error=>{
      utilities.setLoader(false);
    });
  };
};
export const checkoutOrderValidation = (checkoutResponseData) => ({
  type: CHECKOUT_ORDER_VALIDATION,
  payload: { checkoutResponseData },
});

export const placeOrder = (cart) => ({
  type: PLACE_ORDER,
  payload: { cart },
});

export const setCurrentCart = (cart) => ({
  type: SET_CURRENT_CART,
  payload: { cart },
});
