import { createStore, applyMiddleware } from "redux";
import {
  persistCombineReducers,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { reducer as toastr } from "react-redux-toastr";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import config from "./config/config";
import categories from "./views/Categories/reducer";
import products from "./views/Products/reducer";
import reviews from "./components/Reviews/reducer";
import cart from "./views/Cart/reducer";
import MatchingImage from "./views/Scan/reducer";
import preference from "./views/Preferences/reducer";
import variations from "./components/Variations/reducer";
import search from "./views/Search/reducer";
import navbar from "./components/NavBar/reducer";
import addressBook from "./views/AddressBook/reducer";
import shipLaters from "./views/ShipLaters/reducer";
import finelineCatalog from "./views/FinelineCatalog/reducers";
import ProductsReducer from "./views/Categories/ProductsReducer";
import SessionReducer from "./views/Login/reducer";
import FavoritesReducer from "./views/Favorites/reducer";
import vendors from "./views/Vendors/reducer";
import ImReducer from "./views/ImpulseMerchandising/reducer";
import RaReducer from "./views/RetailAssortment/reducer";
import DomReducer from "./views/DealsOfTheMonth/reducer";
import ReunionReducer from "./views/ReunionEvent/reducer";
import PlanogramReducer from "./views/Planograms/reducer";
import UtilityReducer from "./utils/reducer";
import panelReducer from "./views/OMSOrder/reducer";

const rootPersistConfig = {
  key: "root",
  storage,
  blacklist: [
    "navbar",
    "search",
    "toastr",
    "categories",
    "products",
    "vendors",
    "reviews",
    "variations",
    "cart",
    "finelineCatalog",
    "fulfillmentDetail",
  ],
};

const rootReducer = persistCombineReducers(rootPersistConfig, {
  categories: persistReducer(
    {
      key: "categories",
      storage,
      blacklist: config.OFFLINE
        ? ["isFetching", "hasMore"]
        : ["isFetching", "hasMore", "items"],
    },
    categories
  ),
  products: persistReducer(
    {
      key: "products",
      storage,
      blacklist: config.OFFLINE
        ? ["isFetching", "hasMore"]
        : ["isFetching", "hasMore", "items"],
    },
    products
  ),
  vendors: persistReducer(
    {
      key: "vendors",
      storage,
      blacklist: config.OFFLINE ? ["isFetching"] : ["isFetching", "items"],
    },
    vendors
  ),
  reviews: persistReducer(
    {
      key: "reviews",
      storage,
      blacklist: config.OFFLINE ? ["isFetching"] : ["isFetching", "items"],
    },
    reviews
  ),
  variations: persistReducer(
    {
      key: "variations",
      storage,
      blacklist: config.OFFLINE ? ["isFetching"] : ["isFetching", "items"],
    },
    variations
  ),
  cart: persistReducer({ key: "cart", storage }, cart),
  navbar: persistReducer(
    { key: "navbarReducer", storage, blacklist: ["wpMenu"] },
    navbar
  ),
  search,
  toastr,
  AddressBookReducer: addressBook,
  ShiplatersReducer: shipLaters,
  ProductsReducer: persistReducer(
    { key: "productsReducer", storage },
    ProductsReducer
  ),
  SessionReducer: persistReducer({ key: "session", storage }, SessionReducer),
  ImReducer: persistReducer(
    { key: "impulseMerchandising", storage },
    ImReducer
  ),
  RaReducer: persistReducer({ key: "RetailAssortment", storage }, RaReducer),
  PlanogramReducer: persistReducer(
    { key: "Planogram", storage },
    PlanogramReducer
  ),
  FavoritesReducer: persistReducer(
    { key: "favorites", storage },
    FavoritesReducer
  ),
  DomReducer: persistReducer({ key: "domReducer", storage }, DomReducer),
  ReunionReducer: persistReducer(
    { key: "reunionReducer", storage },
    ReunionReducer
  ),
  MatchingImage: persistReducer(
    { key: "MatchingImage", storage },
    MatchingImage
  ),
  preference: persistReducer({ key: "preference", storage }, preference),
  finelineCatalog: finelineCatalog,
  UtilityReducer: persistReducer(
    {
      key: "utility",
      storage,
    },
    UtilityReducer
  ),
  panelReducer: panelReducer,
});

const history = createBrowserHistory();
export const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
);

export const persistor = persistStore(store);

export { history };
export default store;
