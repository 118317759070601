import { TOGGLE_PANEL, FETCH_DATA_REQUEST, FETCH_DATA_SUCCESS, FETCH_DATA_FAILURE } from './actions';
const initialState = {
    panels: {
        panel1:{
            data:null,
            isOpen:true,
            loading:false,
            error:null
        },
    },
};

const panelReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_PANEL:
            return {
                ...state,
                panels: {
                    ...state.panels,
                    [action.payload]: {
                        ...state.panels[action.payload],
                        isOpen: !state.panels[action.payload]?.isOpen,
                    },
                },
            };

        case FETCH_DATA_REQUEST:
            return {
                ...state,
                panels: {
                    ...state.panels,
                    [action.payload]: {
                        ...state.panels[action.payload],
                        loading: true,
                        error: null,
                    },
                },
            };

        case FETCH_DATA_SUCCESS:
            return {
                ...state,
                panels: {
                    ...state.panels,
                    [action.payload.panelId]: {
                        ...state.panels[action.payload.panelId],
                        loading: false,
                        data: action.payload.data,
                    },
                },
            };

        case FETCH_DATA_FAILURE:
            return {
                ...state,
                panels: {
                    ...state.panels,
                    [action.payload.panelId]: {
                        ...state.panels[action.payload.panelId],
                        loading: false,
                        error: action.payload.error,
                    },
                },
            };
        default:
            return state;
    }
};

export default panelReducer; 