import { closeOutItemsUrl,directShipItemsUrl, HOT_DEALS_URL, deals } from '../urlConstants';

export const listingItems = {
  NewItems: { text: 'New Items', url: 'NewItems=NewItemsVal' },
  Discontinued: { text: 'Discontinued Items', url: 'Discontinued=1' },
  CloseoutItems: { text: 'Closeout Items', url: closeOutItemsUrl },
  DirectShipItems: { text: 'DirectShipItems Items', url: directShipItemsUrl },
  HotDeals: { text: deals, url: HOT_DEALS_URL }
};

export const productMenu = [
  {
    name: 'Departments',
    isMenu: true,
    type: 'DynamicDropdown',
    key: 'departments',
    props: {
      className: 'item',
      to: '#'
    },
    menuList_drop: []
  },
  {
    name: 'WholeSale Deals',
    type: 'DropdownItem',
    key: 'wholesaleDeals',
    props: {
      className: 'item',
      to: '/wholeSaleDeals'
    },
    menuList_drop: [
      {
        name: 'Retail Events',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/retail-events'
        }
      },
      {
        name: 'Monthly Specials',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/monthlySpecials'
        }
      },
      {
        name: 'Flash sales',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/flashSales'
        }
      },
      {
        name: deals,
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/listing/HotDeals'
        }
      },
      {
        name: 'Reunion Event',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/reunionEvent'
        }
      },
      {
        name: 'Closeout Corner',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/closeoutCorner'
        }
      }
    ]
  },
  {
    name: 'New items',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/listing/NewItems'
    }
  },
  {
    name: 'Discontinued Items',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/listing/Discontinued'
    }
  },
  {
    name: 'Closeout Items',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '#'
    }
  },
  {
    name: 'Retail Assortments',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/retailAssortments'
    }
  },
  {
    name: 'Planograms',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/planograms'
    }
  },
  {
    name: 'Impulse Merchandising',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/impulse-merchandising'
    }
  }
];

export const orderMenu = [
  {
    name: 'Order Tools',
    type: 'StaticDropdown',
    props: {
      className: 'item',
      to: '#'
    },
    menuList_drop: [
      {
        name: 'Order Pad',
        type: 'DropdownItem',
        props: {
          className: 'item',
          id: 'categories',
          to: '/orderpad'
        }
      },
      {
        name: 'Order Upload',
        type: 'DropdownItem',
        props: {
          className: 'item',
          id: 'categories',
          to: '/orderupload'
        }
      }
      // {
      //   name: 'Special Order Site',
      //   type: 'DropdownItem',
      //   props: {
      //     className: 'item',
      //     to: '#'
      //   }
      // }
    ]
  },

  {
    name: 'Order Edit',
    type: 'StaticDropdown',
    props: {
      className: 'item',
      to: '#'
    },
    menuList_drop: [
      {
        name: 'Ship Laters',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/shipLaters'
        }
      },
      {
        name: 'Edit Orders',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '/editOrders'
        }
      }
    ]
  },

  {
    name: 'Order Report',
    type: 'StaticDropdown',
    props: {
      className: 'item',
      to: '#'
    },
    menuList_drop: [
      {
        name: 'Fill Rates',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '#'
        }
      },
      {
        name: 'Cancelled Items',
        type: 'DropdownItem',
        props: {
          className: 'item',
          to: '#'
        }
      }
    ]
  },

  {
    name: 'All Orders',
    type: 'DropdownItem',
    props: {
      className: 'item',
      id: 'categories',
      to: '/allorders'
    }
  },
  {
    name: 'Reunion Assortments',
    type: 'DropdownItem',
    props: {
      className: 'item',
      id: 'categories',
      to: '#'
    }
  },
  {
    name: 'Auto-Ship',
    type: 'DropdownItem',
    props: {
      className: 'item',
      id: 'categories',
      to: '#'
    }
  },
  {
    name: 'Reunion Event Items',
    type: 'DropdownItem',
    props: {
      className: 'item',
      id: 'categories',
      to: '#'
    }
  },
  {
    name: 'DOM Events',
    type: 'DropdownItem',
    props: {
      className: 'item',
      id: 'categories',
      to: '#'
    }
  }
];

export const favMenu = [
  {
    name: 'Favorite Items',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/favorites'
    }
  },
  {
    name: 'Favorite Vendors',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/favoriteVendors'
    }
  },
  {
    name: 'Momentum',
    type: 'DropdownItem',
    props: {
      className: 'item',
      to: '/momentum'
    }
  }
];
export const orderStatusMenu = [
  'All Orders',
  'Ship Laters',
  'Reunion Assortments',
  'Promoship',
  'Reunion Event Items',
  'DOM Events',
  'Fill Rates',
  'Canceled Items',
  'Edit Orders'
];

export const orderToolsMenu = ['Order Pad', 'Order Upload'];

export const orderStaticMenu = [
  'Discontinued Items',
  'Retail Events',
  'Reunion Event',
  'Monthly Specials',
  'New Items',
  'Retail Assortments'
];

export const userProfileMenu = [
  /*{ name: 'Account' },*/
  { name: 'View Options' },
  { name: 'Preferences', to: '/Preferences' },
  { name: 'Multi-Store Selection', to: '/MultiStoreSelection' },
  { name: 'Store Info & Access' },
  { name: 'Help & Tutorials', to: '/helpAndTutorials' },
  {name:'Logout'}
];

export const textField = [
  {
    price: '$127.57',
    name: 'FlexV 20/60 6Ah Battery',
    description:
      '  FlexVolt, 20/60V, Lithium-Ion, 6.0 Ah Battery, Extended Run Time with Long Run Time & Battery Life, Allows User Maximum Runtime, Light Weight..'
  },
  {
    price: '$100.27',
    name: 'Microwave Oven',
    description:
      '1.1 CUFT Capacity, 1000W Output Power, Black, Microwave, 11-1/4 Carousel Turntable System, LED Digital Control, 11 Power Levels, Digital Timer/Clock, 5 Auto Cook Programs.'
  },
  {
    price: '$70.00',
    name: 'Lamp',
    description:
      ' Polished Brass Finish Canopy Kit, Includes Canopy, Crossbar, 4 Mounting Screws, Lock Washer, Locknut, Screw Collar Loop, 2 Cap Nuts.'
  }
];

export const custCareClosingDays = [
  'Thanksgiving Day',
  'Christmas Day',
  "New Year's Day"
];

export const custCareLimitDays = [
  'Martin Luther King, Jr. Day',
  'Memorial Day',
  'Independence Day',
  'Labor Day',
  'Christmas Eve'
];

export const MOLhelpdeskClosingDays = [
  'Martin Luther King, Jr. Day',
  'Memorial Day',
  'Independence Day',
  'Labor Day',
  'Christmas Eve',
  "New Year's Day"
];
