import React, { Component } from "react";
import '../common/filters.css'
import { filterValues } from './../common/constants';
import { assortmentLevels } from './../common/constants';
import utilities from '../../utils/utilities';
import _, { filter } from 'lodash';
import StickyBox from "react-sticky-box";
import restUtils from "../../utils/restUtils";
import { GET_ORDER_FILTER_DATA } from "../../urlConstants";

class Filter extends Component {
  constructor(props){
      super(props);
      this.state = {
          contentDivVis:props.filters&& Array(props.filters.length).fill(false),
          filterTopOffset: 0
      }
  }

  componentDidMount = () => {
    this.getFilterTop();    
  }
  
  clearAllFilter = () => {
    let storedUrl = window.location.href.split('?');
    window.location.href = storedUrl[0];
  }

  getFilterTop = () => {
    let filterTopOffset = document.getElementById("filterChanges").getBoundingClientRect().top; 
    this.setState({
        filterTopOffset
    })
  }

  handleFilterAction = (e) => {
    if(this.props.isLoading){
      return false;
    }
    const {filterValue, filterHead } = e.target.dataset;
    const filterHeadElement = document.getElementById('fh-' + filterHead);
    if (e.target.checked) {
      filterHeadElement.className = 'filter-head applied-filter-head'
    } else {
      const qSelect =  document.querySelector('#fs-' + e.target.name.replace(" ", "_"));
      let filterApplied = false;
      if(qSelect) {
        let filterCBs = qSelect.querySelectorAll('.filter-checkbox');
        if (!filterCBs) return;        
        filterCBs.forEach(cb => {
          if (cb.checked) {
            filterApplied = true;
            return;
          }
        })
      }
      if (!filterApplied)
        filterHeadElement.className = 'filter-head'
    }
    this.props.callback(e.target.checked, filterHead, filterValue)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.clearFilters) {
      document.querySelectorAll('.filter-checkbox').forEach(cb => {
        cb.checked = false;
      });
      document.querySelectorAll('.filter-head').forEach(fh => {
        if(!fh.id.includes('Department'))
          fh.className = 'filter-head'
      })
      this.setState({contentDivVis:Array(newProps.filters.length).fill(false)})
    }
  }

  toggleDiv = (e) => {
    const index = e.target.dataset.divIndex;
    let {contentDivVis} = this.state;
    contentDivVis[index] = !contentDivVis[index]
    this.setState({contentDivVis});
  };

  render() {
    const {filters, showFilters, filtersApplied, appliedFilters, isNoProducts, screenName, loading} = this.props;

    const {contentDivVis} = this.state;
    let toggleFilter =
      isNoProducts && showFilters
        ? filtersApplied
          ? { display: 'block' }
          : { display: 'none' }
        : isNoProducts 
          ? filtersApplied
          ? { display: 'block' } 
          : { display: 'none' }
        : { display: 'block' }; 
    return (
      <>
      <StickyBox offsetTop={this.state.filterTopOffset} className='stickFilterMobile'>
        <div style={toggleFilter} id= "filterChanges">
          {!showFilters && 
          <i
            className={(filtersApplied)?'filter icon applied-filter':'filter icon'}
            id='filter_symbol'
            onClick={this.props.toggleShowFilter}
          >
            <span className='expand-arrow'>»</span>
          </i>
          }
          {
            showFilters &&
            <div className={'assortmentFilters filters'}>
              <div className='refine-filter-head filter-header'>
                <span>Refine Search</span>
              <i className="filter icon" onClick={this.clearAllFilter} />
                <span id="filter_icon" 
                  className = {(filtersApplied)?'applied-filter filter-hide':'filter-hide'}
                  onClick={this.props.toggleShowFilter}>
                   <span className='shrink-arrow'> <>&lt;&lt;</> hide</span> 
                </span>
              </div>            
              {filters &&
                filters.map((category, index) => {
                  let filterVals = category.valueList;
                  let categoryName  =  category.name.replace(" ", "");
                  // let filterVals = category.valueList.map((item) => {
                  //   if(!item?.display){
                  //   return `${item.key}-${item.value}`;
                  //   } else{
                  //     return `${item.display}`;
                  //   } 
                  // }) ;
                  // console.log("filterVals",filterVals)
                  if(_.isEmpty(filterVals)) return;
                  filterVals = (!Array.isArray(filterVals))?[filterVals]:filterVals;
                  let filterHeadSelected = false;
                  if(screenName === 'korbarstatus')
                  {
                    let filterHeadSelect = false;
                    filterVals.forEach(item => {
                      if(appliedFilters && appliedFilters[categoryName] && (appliedFilters[categoryName].includes(item))){
                        filterHeadSelect = true;
                      }
                    });  
                    filterHeadSelected = filterHeadSelect; 

                  }      
                  return (
                    <div className='filter-section' key={categoryName} id={'fs-' + categoryName.replace(" ", "_")}>
                      <span className={( filterHeadSelected )?'filter-head applied-filter-head':'filter-head'} id={'fh-' + categoryName}>
                        {category.name}
                        <i className={contentDivVis[index]?'minus icon toggleIcon':'plus icon toggleIcon'}
                          data-div-index={index}
                          onClick={this.toggleDiv}>
                        </i>
                      </span>
                      {contentDivVis[index] &&
                      <div id={'contents-' + index}>
                        {filterVals && filterVals.map((item, valIndex) => {
                          const checkboxId = `${categoryName}_${item.key}`;
                          let orderTypeFilterVal = item.key;
                        
                          if (categoryName) {
                            if(!utilities.isEmptyOrNullString(item)) {
                              orderTypeFilterVal =  item['key']
                            }
                          }  
                          return (
                            !utilities.isEmptyOrNullString(item) && (
                              <div className="ui checkbox filter-cb-wrap" key={item}>
                                <input type='checkbox'
                                  className='filter-checkbox'
                                  key={item}
                                  data-filter-head={categoryName}
                                  data-filter-value={ orderTypeFilterVal}
                                  name={categoryName}
                                  onChange={this.handleFilterAction}
                                  value={orderTypeFilterVal }
                                  checked = {
                                    (appliedFilters && 
                                    appliedFilters[categoryName] && 
                                    (appliedFilters[categoryName] && appliedFilters[categoryName].includes(orderTypeFilterVal) ? true:  false))
                                  }
                                  id={checkboxId}
                                />
                              <label>
                                {categoryName === "Status" ?  item.display : `${item.key} - ${item.value}`}
                                    
                                    </label>
                              </div>
                            )
                          )
                        })}
                      </div>
                      }
                    </div>
                  )
                })
            }
          </div>
          }
        </div>
      </StickyBox>
      </>
    )
  }
}

export default Filter;
