import {productListingUrl,imProductListingUrl,domListingUrl,closeOutItemsUrl,directShipItemsUrl, HOT_DEALS_URL} from '../../urlConstants';
export const listingUrls = 
{
    productListing : productListingUrl,
    imProductListing : imProductListingUrl,
    DOM : domListingUrl,
    DomSearch : "",
    CloseoutItems : closeOutItemsUrl,
    DirectShipItems: `${directShipItemsUrl}/GetDsVendorItems`,
    HotDeals: HOT_DEALS_URL

}