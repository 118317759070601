import React from 'react';
import restUtils from '../../utils/restUtils';
import { Loader, Responsive, Pagination, Popup } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import history from '../../history';
import "../OMSOrder/OrderCss.css";
import _ from 'lodash';
import utilities from '../../utils/utilities';
import ExcelExport from '../common/ExcelExport';
import SAM_Icon from '../AllOrders/SAM_Icon.gif';
import renderImages from '../../views/common/RenderImages';
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { Link } from 'react-router-dom';
import search_img from '../images/Search.svg';
import { GET_ORDER_LIST, UPS_URL, FEDEX_URL, GET_ORDER_FILTER_DATA } from '../../../src/urlConstants';
import Filter from './Filter';
import { getRaCategories } from '../RetailAssortment/actions';
import { PAGE_LIMIT } from '../common/constants';
import { isArray } from 'util';


class OMSOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: [],
      orderStatus: [],
      filters: [],
      loading: false,
      serverError: false,
      clearFilters: false,
      appliedFilters: {},
      showFilters: (this.props.getprefer && this.props.getprefer.filterView)
        ? this.props.getprefer.filterView === 'E' ? true : false : window.innerWidth > 768 ? true : false,
      isNoProducts: false,
      noOfOrders: 0,
      scrollPostion: 0,
      noOfOrders: 0,
      firstItem: 0,
      lastItem: 0,
      currentPage: 0,
      totalPages: 0,
      filterForRequest: {},
      isDirectShipPage: this.props?.match?.path === "/directshipallorders" ? true : false
    }
    this.filterString = {};
    this.searchParam = 'ItemNum';
    this.searchNumber = '';
    this.sortParam = 'orderDate';
    this.sortDirection = 'desc';
    this.pathname = '';
    this.pageSize = '25';
    this.scrollPosition = 0;
    this.page = 1;
    // this.getOrderFilterData = this.getOrderFilterData.bind(this);
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage ?
      this.props.getprefer.resultPerPage : '25';
    this.pathname = this.props.location && this.props.location.pathname;
    console.log("Compounde did mount ", this.state.filters.length)
    if(_.isEmpty(this.state.filters)) {
      restUtils.getDataWithoutToken(GET_ORDER_FILTER_DATA).then((res)=>{
        // this.props.setOrderFilterData(res.data)  
        this.setState({filters:res.data})  
      })
    } 

    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else {
      this.getOrders();
      //  this.getOrderFilterData();
    }
  }

  // getOrderFilterData = () => {
  //   restUtils.getDataWithoutToken(GET_ORDER_FILTER_DATA).then((res)=>{
  //       this.setState({
  //         filters:res.data
  //       })
  //    });
  // }
  // omsorderUrl = (val) => {
  //   if (val) {
  //     history.replace({
  //       pathname: `/betaversion`,
  //       state: {}
  //     })
  //   }
  // }


  changeSearchParam = e => {
    this.scrollPosition = window.pageYOffset;
    this.searchParam = e.currentTarget.value;
  };

  searchItems = () => {
    this.scrollPosition = window.pageYOffset;
    this.searchNumber = document.getElementById('search_order_status').value;
    this.page = 1;
    this.getOrders();
  };

  handleEnterKey = e => {
    this.scrollPosition = window.pageYOffset;
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  changeSortParam = e => {
    window.scrollTo(0, 0);
    this.scrollPosition = window.pageYOffset;
    this.page = 1;
    this.sortParam = e.currentTarget.value;
    this.getOrders();
  };

  changeSortDirection = e => {
    this.page = 1;
    this.sortDirection = e.currentTarget.value;
    window.scrollTo(0, 0);
    this.scrollPosition = window.pageYOffset;
    this.getOrders();
  };

  onOrderpagesize = e => {
    this.pageSize = e.target.value;
    this.page = 1;
    window.scrollTo(0, 0);
    this.getOrders();
  }
  onPageChange = e => {
    let queryPage = e.currentTarget.text;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? this.state.currentPage + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.page = queryPage;
    //this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
    this.getOrders();
  };

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.page = getParams[0].split('=')[1];
    this.pageSize = getParams[1].split('=')[1];
    let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
    if (searchParam !== '') {
      let search = searchParam.split('=');
      this.searchParam = search[0];
      this.searchNumber = search[1].split('&')[0];
    }
    document.getElementById('search_order_status').value = this.searchNumber;
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' :
      queryParam[1].includes('SortType=desc') ? 'desc' : '';
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    // document.getElementById('order_sts_dropdown').value = this.searchParam;
    let appliedFilters = utilities.getFilterArray(this.filterString);
    let filterForRequest = utilities.getFilterForRequest(appliedFilters);
    this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
    this.setState({
      appliedFilters: appliedFilters,
      filterForRequest: filterForRequest
    }, () => {
      this.getOrders();
    });
  }

  setUrlParamsPath = () => {
    let pathName = '';
    pathName = utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page=' + this.page;
    pathName = pathName + '&ResultsPerPage=' + this.pageSize;
    this.scrollPosition = window.pageYOffset;
    if (this.searchNumber) {
      pathName = pathName + '&searchBy=' + this.searchParam + '=' + this.searchNumber;
    }
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam=' + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType=' + this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    pathName = pathName + '&scrollPosition=' + this.scrollPosition;
    if (!_.isEmpty(this.filterString)) {
      let obj = utilities.getQueryStrFromJson(this.filterString);
      pathName = pathName + '&filterString=' + utilities.getQueryStrForFilterString(obj);
    }
    let pushUrl = this.pathname + '?' + pathName;
    window.history.pushState(window.history.state, "", pushUrl);
    return pushUrl
  }

  setBackButtonPath = () => {
    const path = this.setUrlParamsPath();
  }

  redirectOrderStatusItem = (order) => {
    const pushUrl = this.setUrlParamsPath();
    history.push({
      pathname: '/OrderItemDetails',
      state: { orderDetails: order, pushUrl: pushUrl }
    })
  }

  reditectDsOrderStatus = (order) => {
    const pushUrl = this.setUrlParamsPath();
    history.push({
      pathname: '/DirectShipOrderDetails',
      state: { orderDetails: order, pushUrl: pushUrl }
    })
  }
  trimSpecialCharacters = (str) => {
    // Replace all non-alphanumeric characters (excluding spaces) with an empty string
    return str.replace(/[^\w\s]/gi, '');
  }
  getOrders = () => {
    let queryJson = [];
    let appliedFilters = this.state.filterForRequest;
    // queryJson.push(appliedFilters);
    // debugger;
    if (!_.isEmpty(appliedFilters)) {
      queryJson.push(appliedFilters);
    //   for (let property in appliedFilters) {
    //     if (appliedFilters.hasOwnProperty(property)) {
    //       let filter = {};
    //       filter.valueList = appliedFilters[property];
          
    //     }
    //   }
    }
    let searchArr = {};
    if (this.searchNumber) {
      let SearchName = this.searchParam;
      let SearchValue = this.searchNumber
      searchArr[SearchName] = SearchValue.split(',');
      queryJson.push(searchArr);
    }
    
    let pageStartingvalue = parseInt(this.page - 1) * parseInt(this.pageSize)
    let requestPayLoad = {
      ...{"pagination": {"offset": pageStartingvalue,
      "length": parseInt(this.pageSize)}},
      "sort": this.sortDirection === "desc" ? `-${this.sortParam}` : this.sortParam,   
     
    };
    function getObjectKeysAndValues(object) {
      const result = {};
      queryJson.forEach((object, index) => { 
      Object.keys(object).forEach(key => {
        // Assuming you want to prefix keys and store values in another variable
        const newKey = `${key}`; // Example: Prefixing with 'new_'
        const value = object[key];
        
        // Assigning the prefixed key and value to result object
        result[newKey] = value;
      });
      
      
    });
    return result;
    }
    const filterData = getObjectKeysAndValues(queryJson);
    if (!_.isEmpty(filterData)) {
      requestPayLoad = {...requestPayLoad,  filters: filterData }
    }
    
    // if (!_.isEmpty(this.searchNumber)){
    //   // requestPayLoad = {...requestPayLoad, "search":this.searchNumber }
    //   requestPayLoad = {...requestPayLoad, ...{search: {...searchArr}} }
    // }
    
    let url = `${GET_ORDER_LIST}`
    this.setState({ loading: true });
    restUtils
      .postDataWithBearer(url, requestPayLoad)
      .then(response => {
        let totalPagesCount = parseInt(response.data.total % this.pageSize) === 0 ? parseInt(response.data.total / this.pageSize) : parseInt(response.data.total / this.pageSize) + 1;
        let lastItemData = parseInt(response.data.total) > parseInt(this.pageSize * this.page) ? parseInt(this.pageSize * this.page) : parseInt(response.data.total)
        this.setState({
          allOrders: response.data.items, noOfOrders: response.data.total, loading: false, serverError: false,
          firstItem: pageStartingvalue, lastItem: lastItemData,
          currentPage: this.page, totalPages: totalPagesCount
        });

        if (!this.state.showFilters) {
          this.setState({
            showFilters: true
          }, () => {
            for (let key in this.state.appliedFilters) {
              document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
            }
            this.setState({
              showFilters: false
            });
          });
        } else {
          for (let key in this.state.appliedFilters) {
            document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
          }
        }

        window.scrollTo(0, this.scrollPosition);
        this.setUrlParamsPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  showLess = (changeIndex, samNumber) => {
    let allOrders = this.state.allOrders;
    this.state.allOrders.forEach((orders, index) => {
      if (orders.orderNumber === samNumber)
        allOrders[changeIndex].showLess = true;
    });
    this.setState({ allOrders });
  }

  showMore = (changeIndex, samNumber) => {
    let allOrders = this.state.allOrders;
    this.state.allOrders.forEach((orders, index) => {
      if (orders.orderNumber == samNumber)
        allOrders[changeIndex].showLess = false;
    });
    this.setState({ allOrders });
  }

  openTrackingSite = (trackingNumber) => {
    window.open(trackingNumber.startsWith('1Z') ? UPS_URL + `&InquiryNumber1=${trackingNumber}` : FEDEX_URL.replace('trackingNumber', trackingNumber), '_blank');
  }

  renderTrackingDetails = (order) => {
    const uniqTrackingNum = _.uniq(order.retailerTrackingNumber.split(','))
    if (uniqTrackingNum.length > 1) {
      return <span className='order_details'><Link onClick={this.setUrlParamsPath} to={{ pathname: "/OrderItemLevelDetails", state: { orderDetails: order } }}>Click here</Link></span>
    } else {
      return <span className='order_details'><a href="#" onClick={() => this.openTrackingSite(uniqTrackingNum[0])}>{uniqTrackingNum[0]}</a></span>
    }
  }
  filterCallback = (isAdd, filterKey, filterValue) => {
    let filterForRequest = this.state.filterForRequest;
    this.scrollPosition = window.pageYOffset;
    let appliedFilters = this.state.appliedFilters;
    this.page = 1;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
      filterForRequest = { ...filterForRequest, [filterKey]: [filterValue] };
    } else if (keyExists) {
      if (isAdd) {
        filterForRequest[filterKey].push(filterValue);
      }
      else {
        if (filterForRequest[filterKey]) {
          let filterValues = filterForRequest[filterKey].filter(item => item !== filterValue);
          appliedFilters[filterKey] = appliedFilters[filterKey].replace(filterValue, "");
          if (filterValues.length === 0) {
            delete filterForRequest[filterKey];
          }
          else {
            filterForRequest[filterKey] = filterValues;
          }
        }
      }
      let value = appliedFilters[filterKey].split(',').filter(item => item !== "");
      let index = value.indexOf(filterValue);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        if (isAdd)
          value.push(filterValue);
      }
      if (!value.length && keyExists) delete appliedFilters[filterKey];
      else {
        appliedFilters[filterKey] = value.join(',');
      }
    }
    this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
    this.setState({
      appliedFilters,
      clearFilters: false,
      filterForRequest
    }, () => {
      this.getOrders();
    });
  };
  render() {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    return (
      <div className='page-wrap'>
        <div className='page-header editOrderHeaderTop'>Order Status (OMS)  <a href="/omsshiplater" style={{"marginLeft":"10px"}}>Ship Later (OMS)</a></div>
        {this.props.multiStoreDetails.access === 1 ?
          <h4>You don't have the permission to view this page.Please contact the True Value Administrator.</h4>
          :
          <>
            {this.state.loading &&
              <div>
                <Loader active />
              </div>
            }
            <div className="editOrderHeaderTwo">
              <div className='sortFields' style={{ width: '100%' }}>
                <div id='input_search'>
                  <span>
                    {window.innerWidth < 768 ? 'Search' : 'Order Search'}
                  </span>
                  <select
              className='sort-criteria combo'
              onChange={this.changeSearchParam}
              id='order_sts_dropdown'>
              <option value='ItemNum'>Item #</option>              
              <option value='EventNum'>Event #</option>
              <option value='PoNum'>PO #</option>
            </select>
            <span style={{marginLeft:"5px"}}>
                  <input
                    id='search_order_status'
                    onKeyDown={this.handleEnterKey}
                    type='text'
                  />
                 
                  {renderImages.getImage({
                    src: search_img,
                    className: 'search_icon_address cursor_p',
                    onClick: this.searchItems
                  })}
                   </span>
                </div>
                <div className='sort_by mobile_sort'>
                  <span className='filter-label'>
                    <span>Show</span>
                    <select
                      value={this.pageSize}
                      className='select-range combo'
                      onChange={this.onOrderpagesize}
                    >
                      <option className='SelectOption' value={25}>
                        25
                      </option>
                      <option className='SelectOption' value={50}>
                        50
                      </option>
                      <option className='SelectOption' value={100}>
                        100
                      </option>
                    </select>
                  </span>
                  <span className='filter-label'>Sort By</span>
                  <select className='sort-criteria combo' onChange={this.changeSortParam} id='pl_dropdown' style={{ width: "12.7rem" }} value={this.sortParam}>
                    {this.state.isDirectShipPage ?
                      <>
                        <option value='orderDate'>Order Created Date</option>
                        <option value='poNbr'>PO#</option>
                        <option value='status'>Order Status</option>
                      </>
                      :
                      <>
                        <option value='orderDate'>Order Create Date</option>
                        <option value='poNbr'>PO#</option>
                        <option value='orderType'>Order Type</option>
                        <option value='releaseDate'>Expected Release Week</option>
                        <option value='status'>Order Status</option>
                      </>
                    }

                  </select>
                  <select className='sort-order combo' onChange={this.changeSortDirection} defaultValue='desc' value={this.sortDirection}>
                    <option value='asc'>Ascending</option>
                    <option value='desc'>Descending</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={window.innerWidth > 1024 ? "displayFlex allOrdersTable all_orderTable" : "allOrderstablemobile"}>
              <div className='filterorderstatus' style={{ flex: this.state.showFilters ? '0 0 20%' : '0 0 0' }}>
                <Filter
              clearFilters={this.state.clearFilters}
              filtersApplied={!_.isEmpty(this.state.appliedFilters)}
              appliedFilters={this.state.appliedFilters}
              toggleShowFilter={this.toggleShowFilter}
              showFilters={this.state.showFilters}
              filters={this.state.filters}
              callback={this.filterCallback}
              isNoProducts={this.state.isNoProducts}
              screenName={ 'korbarstatus' }
              loading={this.state.loading}
            />
              </div>
              <Responsive minWidth='1025' id={this.state.isDirectShipPage && 'ds_orderStatusTable'}>
                <div className="eighteen wide column" id='allOrdersTable'>
                  <div style={{ position: 'sticky', top: '10.9rem', background: '#f2f2f2' }}>
                    <div className="order-pagination">
                      <span className='OrderTotalitem'>
                        {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.noOfOrders} orders
                      </span>
                      <span className='paginationAllorders'>
                        {this.state.totalPages > 0 && (
                          <Pagination
                            className='pagination'
                            boundaryRange={0}
                            activePage={this.state.currentPage}
                            totalPages={this.state.totalPages}
                            onPageChange={this.onPageChange}
                            ellipsisItem={null}
                            siblingRange={1}
                            firstItem={this.state.currentPage === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                            lastItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                            prevItem={this.state.currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                            nextItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                          />)}
                      </span>

                      <ExcelExport className='excel_left excelHide'
                        fileName={this.state.isDirectShipPage ? 'DirectShip All Orders' : 'All Orders'}
                        orderResults={this.state.allOrders}
                        searchNumber={this.searchNumber}
                        searchParam={this.searchParam}
                        viewType={this.props.viewType}
                        getprefer={
                          this.props.getprefer &&
                            this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
                        }
                        sortParam={this.sortParam}
                        sortDirection={this.sortDirection}
                        appliedFilters={this.state.appliedFilters}
                        storeId={this.props.storeId}
                        userId={this.props.userId}
                        rdcNum={this.props.rdcNum}
                        aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
                        totalPages={parseInt(this.pageSize * this.state.totalPages / PAGE_LIMIT) + 1} pageSize={PAGE_LIMIT} />

                    </div>
                  </div>
                  <table className="ui celled table all-wh-table stickyTableEditOrder padding" id='font-size-orders'>
                    <thead>
                      <tr>
                        <th className='allOdrHeader' id='no_border'  >Order Number</th>
                        <th className='allOdrHeader' id='no_border'  >Order Created Date</th>
                        <th className='allOdrHeader' id='no_border' >PO#</th>
                        <th className='allOdrHeader' id='no_border' >Event#</th>
                        <th className='allOdrHeader' id='no_border' >Order Type</th>
                        <th className='allOdrHeader' id='no_border' >Item Count</th>
                        <th className='allOdrHeader' id='no_border' >RDC</th>
                        <th className='allOdrHeader' id='no_border' >Ship To</th>
                        <th className='allOdrHeader' id='no_border' >Expected Release Date</th>
                        {this.props.viewType !== MEMBER_VIEW && (getPrefer === 'c3' || getPrefer === 'c4') ? "" :
                          <th className='allOdrHeader' id='no_border' >{utilities.getLabelsByType(this.props.viewType, "TOTAL_COST", getPrefer)}</th>
                        }
                        <th className='allOdrHeader' id='no_border' >Status</th>
                        <th className='allOdrHeader' id='no_border' >&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(this.state.noOfOrders === 0 || this.state.serverError)
                        ? <>
                          <tr>
                            <td colSpan='11' className='t-center'>No orders to display!</td>
                          </tr>
                        </>
                        : this.state.allOrders.length > 0 && this.state.allOrders.map((order, index) => {
                          return (
                            <>
                              <tr className='wh-row'>
                                <td className='t-center' id='field_width'>{utilities.hyphenForZero(order.orderNumber)}</td>
                                <td className='t-left' id='field_width'>{order.orderDate ? utilities.hyphenForNull(utilities.getDateFromTimeStamp(order.orderDate)) : '-'}</td>
                                <td className='t-center' id='field_width'>
                                  {order.referenceData.map((items, index) => {
                                    return items.data === "koctOrderNbr" && utilities.hyphenForNull(items.value ? items.value : "-")

                                  })}
                                </td>
                                <td className='t-center' id='field_width'>
                                  {order.referenceData.map((items, index) => {
                                    return items.data === "promoNbr" && utilities.hyphenForNull(items.value ? items.value : "-")

                                  })}
                                </td>
                                <td className='t-center' id='field_width'>
                                  {order.referenceData.map((items, index) => {
                                    return items.data === "mfOrderType" && utilities.hyphenForNull(items.value ? items.value : "-")

                                  })}

                                </td>
                                <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.itemCount)}</td>
                                <td className='t-center' id='field_width'>
                                  {order.referenceData.map((items, index) => {
                                    return items.data === "mfRdc" && utilities.hyphenForNull(items.value ? items.value : "-")

                                  })}

                                </td>
                                <td className='t-center' id='field_width'>{utilities.hyphenForNull(order?.consumerName)}</td>
                                <td className='t-center' id='field_width'>
                                  {order.referenceData.map((items, index) => {
                                    return items.data === "releaseDate" && utilities.hyphenForNull(items.value ? utilities.getDateFromTimeStamp(items.value) : "-")

                                  })}
                                </td>
                                {this.props.viewType !== MEMBER_VIEW && (getPrefer === 'c3' || getPrefer === 'c4') ? "" :
                                  <td className='t-center' id='field_width'>
                                    {order.orderTotals['orderTotal'] ? <MaskPrice
                                      field='Member Cost black'
                                      getprefer={getPrefer}
                                      viewType={this.props.viewType}
                                      fieldVal={order.orderTotals['orderTotal']}
                                    /> : "-"}
                                  </td>}
                                <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.status).replace(/_/g, ' ')}</td>
                                <td  className='t-center checkoutPointer' id='remove_border'><p onClick={() => { this.redirectOrderStatusItem(order) }}>View Details</p></td>
                              </tr>
                              
                            </>
                          )
                        })

                      }

                    </tbody>
                  </table>
                </div>
              </Responsive>
              <Responsive maxWidth='1024'>
                <div className="portrait-pagination">
                  <div className='OrderTotalitem'>
                    {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.noOfOrders} orders
                  </div>
                  <div className='paginationAllorders'>
                    {this.state.totalPages > 0 && (<Pagination
                      className='pagination'
                      boundaryRange={0}
                      activePage={this.state.currentPage}
                      totalPages={this.state.totalPages}
                      onPageChange={this.onPageChange}
                      ellipsisItem={null}
                      siblingRange={1}
                      firstItem={this.state.currentPage === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                      lastItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                      prevItem={this.state.currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                      nextItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                    />)}
                  </div>
                  <div className='order_count-portarit'>Total {this.state.noOfOrders} Orders
                    <ExcelExport
                      className='excel_left'
                      fileName={'All Orders'}
                      searchNumber={this.searchNumber}
                      viewType={this.props.viewType}
                      getprefer={
                        this.props.getprefer &&
                          this.props.getprefer.customerView
                          ? this.props.getprefer.customerView
                          : 'c1'
                      }
                      sortParam={this.sortParam}
                      sortDirection={this.sortDirection}
                      appliedFilters={this.state.appliedFilters}
                      storeId={this.props.storeId}
                      userId={this.props.userId}
                      rdcNum={this.props.rdcNum}
                      aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
                      totalPages={parseInt(this.pageSize * this.state.totalPages / PAGE_LIMIT) + 1} pageSize={PAGE_LIMIT} />
                  </div>
                </div>
                {
                  this.state.allOrders.map((order_details, index) => {
                    return <OrderCard
                      order={order_details}
                      loggedInUser={this.props.loggedInUser}
                      index={index}
                      samNumber={order_details?.orderNumber}
                      showLess={this.showLess}
                      showMore={this.showMore}
                      openTrackingSite={this.openTrackingSite}
                      renderTrackingDetails={this.renderTrackingDetails}
                      getPrefer={this.props.getprefer &&
                        this.props.getprefer.customerView
                        ? this.props.getprefer.customerView
                        : 'c1'}
                      viewType={this.props.viewType} />
                  })
                }
              </Responsive>
            </div>
            {this.state.noOfOrders > 0 &&
              <div className='t-right'>
                <i className='addedBy'>
                  **Harvard, Atlanta, Woodland and Central Ship are the preferred Ship to customer RDCs. Orders placed via these RDCs will have tracking number information available.
                </i>
              </div>}
          </>
        }
      </div>
    )
  }
}
const OrderCard = ({ order, loggedInUser, index, samNumber, showLess, showMore, openTrackingSite, renderTrackingDetails, getPrefer, viewType }) => {

  return (
    <div className='all-orders-card'>
      <div className='floatRight view-details-link'>
        <Link to={{ pathname: "/OrderItemLevelDetails", state: { orderDetails: order } }}>View Details</Link></div>
      <table id='order-card-table'>
        <tr>
          <td className='order-card-field padding'>Order Number</td>
          <td>{utilities.hyphenForZero(order.orderNumber)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Order Created Date</td>
          <td>{order.orderDate ? utilities.hyphenForNull(utilities.getDateFromTimeStamp(order.orderDate)) : '-'}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>PO</td>
          <td>{order.referenceData.map((items, index) => {
            return items.data === "koctOrderNbr" && utilities.hyphenForNull(items.value ? items.value : "-")

          })}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Event</td>
          <td>
            {order.referenceData.map((items, index) => {
              return items.data === "promoNbr" && utilities.hyphenForNull(items.value ? items.value : "-")

            })}

          </td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Order Type</td>
          <td>
            {order.referenceData.map((items, index) => {
              return items.data === "mfOrderType" && utilities.hyphenForNull(items.value ? items.value : "-")

            })}

          </td>
        </tr>
        {order.showLess === false && <> 
          <tr>
            <td className='order-card-field padding'>Item Count</td>
            <td>{(order.orderStatus === 'closed' || order.orderStatus === 'being processed') ? '' : utilities.hyphenForNull(order.numberOfItems)}</td>
          </tr>
          <tr>
            <td className='order-card-field padding'>RDC</td>
            <td> {order.referenceData.map((items, index) => {
              return items.data === "mfRdc" && utilities.hyphenForNull(items.value ? items.value : "-")

            })}</td>
          </tr>
          <tr>
            <td className='order-card-field padding'>Ship To</td>
            <td>{utilities.hyphenForNull(order?.consumerName)}</td>
          </tr>
          <tr>
            <td className='order-card-field padding'>Expected Release Date</td>
            <td>{order.releaseDate ? utilities.hyphenForNull(utilities.getDateFromTimeStamp(order.releaseDate)) : "-"}</td>
          </tr>
          <tr>
            <td className='order-card-field padding'>{utilities.getLabelsByType(viewType, "TOTAL_COST", getPrefer)}</td>
            <td>{order.subCostTotal ?
              <MaskPrice
                field='Member Cost black'
                getprefer={getPrefer}
                viewType={viewType}
                fieldVal={order?.orderTotals['orderTotal']}
              /> : "-"}</td>
          </tr>
          <tr>
            <td className='order-card-field padding'>Status</td>
            <td>{utilities.hyphenForNull(order?.status)}</td>
          </tr>
        </>}


        <tr>
          <td colSpan={2} className='t-right'>
            {order.showLess !== false && <p>
              <a className="btn btn-primary" onClick={() => showMore(index, samNumber)}>...show more</a>
            </p>}
            {order.showLess === false && <p>
              <a className="btn btn-primary" onClick={() => showLess(index, samNumber)}>...show less</a>
            </p>}
          </td>
        </tr>
      </table>
    </div>
  )
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  const loggedInUser = state.SessionReducer.address.userName;
  const rdcNum = state.SessionReducer.rdc;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId, userName, loggedInUser, userId, rdcNum,
    getprefer: state.preference.listData,
    viewOnly: state.SessionReducer.viewOnly,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    aliasSKURetailerGrpID: state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ getRaCategories }, dispatch)
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OMSOrder));