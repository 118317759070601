import React from 'react';
import {
  Icon,
  Label,
  Dropdown,
  DropdownItem,
  Responsive,
  Popup,
  Modal,
  DropdownMenu
} from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import './NavBar.css';
import { userProfileMenu } from '../../constants/productMenu';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { switchView } from './actions';
import { logout, setVendorPopUp, setHereForYou } from '../../views/Login/actions';
import { getFavoriteItems } from '../../views/Favorites/actions';
import new_tv_logo from '../../views/images/nnwh-temp-logo.jpg';
import need_help_logo from '../../views/images/need-help-logo.jpg';
import barcodeIcon from '../../views/images/BarcodeIcon.png';
import personIcon from '../../views/images/PersonIcon.png';
import renderImages from '../../views/common/RenderImages';
import hereForYouLogo from '../../views/images/hereForYou.png'
import CartFlyout from '../../views/Cart/CartFlyout';
import { account, store, statmentAndPayment, enterClaim, getRedirectUriMOL, SAVE_USER_AGREEMENT, DRAFT_CART_REPORT } from '../../urlConstants';
import { MEMBER_VIEW, CUSTOMER_VIEW } from '../NavBar/menuConstants';
import utilities from '../../utils/utilities';
import history, { Event } from '../../history';
import CategoryList from '../../views/common/CategoryList'
import ChangeStore from './ChangeStore';
import {adminMenu} from '../NavBar/menuConstants'
import Dialog from 'react-dialog';
import restUtils from '../../utils/restUtils';
import { BIGDESKTOP } from '../../views/common/constants';
import HereForYou from './HereForYou';

class HeaderTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showOptionsDiv: false, showProfileMenu: false, showNameMenu: false,showChangeStore: false, showAdminDropDown: false,
    showHereForYou: false,
    showVendorpopUp:false,
    isPopupShowDCF: localStorage.getItem("DCRpopup") ? false : true,
    storeguid:this.props.storeguid,
    userguid: this.props.userguid,
    needHelpNumber: this.getNeedHelpNumber(this.props.wpMenu)
    };
  }
  
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    if(window.location.pathname === '/hfy') {
      this.setState({showHereForYou: true})
      }
  }
  componentWillMount() {
    if(this.state.isPopupShowDCF && window.location.pathname === '/home'){
      restUtils.getData(`${DRAFT_CART_REPORT}?storeId=${this.props.storeId}`)
    .then(response => {
      if(response?.data && response.data.length > 0){
        this.setState({
          dartCartReportData: response.data
        })
      }
    })
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.wpMenu){
      const needHelpResponse = this.getNeedHelpNumber(nextProps.wpMenu);
      this.setState({
        needHelpNumber: needHelpResponse
      })
    }
    this.setState({showVendorpopUp: nextProps.isVendorPopUp, 
      storeguid: nextProps.storeguid,
      userguid:nextProps.userguid})
  }
  
  getNeedHelpNumber = (menuData) => {
    let needHelpNum = '';
    if(menuData && menuData.length > 0) {
      const menuFilter = menuData.filter((val) => val.title === 'Need help?');
      const subMenuFilter = menuFilter.length > 0 ? menuFilter[0]?.submenus?.filter((val) => val?.title?.includes('Customer Care')) : [];
      if(subMenuFilter.length > 0){
        const needHelpInfo = subMenuFilter[0]?.title?.split('Customer Care: ') || [];
        needHelpNum = needHelpInfo.length > 0 ? needHelpInfo[1] : '';
      }
    }
    return needHelpNum;
  }
  handleClickOutside = (e) => {
    const {title}= e.target;
    if(!utilities.hasParentWithMatchingSelector(e.target, '#cart-menu') && title !== 'Cart' && !utilities.hasParentWithMatchingSelector(e.target, '#name-menu')){
      this.setState({
        showCartMenu: false,
        showNameMenu: false
      });
    }
  }
  closeVendorPopUp = () =>{
    this.setState({showVendorpopUp: false})
    this.props.setVendorPopUp(false)
  }
  closePopUpDCR = () =>{
    localStorage.setItem('DCRpopup', false)
   this.setState({isPopupShowDCF:false})
  }
  acceptAcknowledge = () =>{
    const payload = {
      "isAgreementSigned": 1
    }
    this.props.setVendorPopUp(false)
    restUtils.postDataWithBearer(SAVE_USER_AGREEMENT,payload).then((res)=>{
    })
  }
  toggleOptionsDiv = event => {
    event.stopPropagation();
    const { showOptionsDiv } = this.state;
    this.setState({
      showOptionsDiv: !showOptionsDiv
    });
  };
  MouseLeaveViewOption = () => {
    this.setState({
      showOptionsDiv: false
    });
  };
  MouseEnterViewOption = () => {
    this.setState({
      showOptionsDiv: true
    });
  };
  // Making the profile menu dropdown controlled one
  toggleProfileMenu = () => {
    const { showProfileMenu } = this.state;
    this.setState({
      showProfileMenu: !showProfileMenu
    });
  };
  toggleshowChangeStoreMenu = () => {
    const { showChangeStore } = this.state;
    this.setState({
      showChangeStore: !showChangeStore
    });
  };
  toggleAdminMenu =() =>{
    const { showAdminDropDown } = this.state;
    this.setState({
      showAdminDropDown: !showAdminDropDown
    });
  }
  MouseEnterChangeStoreMenu =() =>{
    this.setState({
      showProfileMenu: false,
      showCartMenu: false,
      showNameMenu: true,
    });
  }
  MouseLeaveChangeStoreMenu =() =>{
    this.setState({
      showProfileMenu: false,
      showCartMenu: false,
      showNameMenu: false,
    });
  }
  MouseEnterProfileMenu = () => {
    this.setState({
      showProfileMenu: true,
      showCartMenu: false,
      showNameMenu: false,
    });
  };
  MouseLeaveProfileMenu = () => {
    this.setState({
      showProfileMenu: false
    });
  };
  MouseLeaveAdminMenu = () => {
    this.setState({
      showAdminDropDown: false
    });
  };
  MouseEnterAdminMenu = () => {
    this.setState({
      showAdminDropDown: true
    });
  };
  switchView = view => {
    this.toggleProfileMenu();
    Event('HEADER','Switching View'+view);
    localStorage.setItem('viewType', view)
    this.props.switchView(view);
  };

  accountClick = () => {
    Event('HEADER','Account Clicked');
    utilities.openInNewTab(account);
  };
  
  storeClick = () => {
    Event('HEADER','Store Clicked');
    history.push('/content/store-info-employee-access');
  };
  statmentClick = () => {
    Event('HEADER','Store Clicked');
    utilities.openInNewTab(statmentAndPayment);
  };
 claimClick = () => {
    Event('HEADER','Store Clicked');
    utilities.openInNewTab(enterClaim);
  };

  onLogout = () => {
    Event('HEADER','Logout Clicked');
    this.props.logout();
  };

  redirectMol = () => {
    this.props.logout();
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem("isLeaveNW",true);
    window.location.assign(getRedirectUriMOL())
  }

  toggleCartMenu = () => {
    const { showCartMenu } = this.state;
    this.setState({
      showProfileMenu: false,
      showNameMenu:false,
      showCartMenu: !showCartMenu
    });
  };

  totalCartcount = () => {
    history.push('/cart')
  };
  redirectHome = ()=>{
    history.push('/home');
  }
  makeCall = ()=>{
    window.location = `tel:${this.state.needHelpNumber}`;
  }
  openHereForYouModel = () =>{
    this.setState({showHereForYou:true})
  }
  handleMinimize = (data) => {
    this.setState({showHereForYou:false})
    this.props.setHereForYou(data)
  }
  openChangesStoreModel = () =>{
    this.setState({showChangeStore:true})
  }
  closeHereForYou = () =>{
    this.setState({showHereForYou:false})
  }
  closeChangeStore = () =>{
    this.setState({showChangeStore:false})
  }
  closeChangeStorePopUp = () =>{
    this.setState({showChangeStore:false})
  }
  handleSelect = (key) => {
    const url = key.split("$")[1] || "";
    if (url.startsWith("/")) {
        if (url.startsWith("/http")) {
          utilities.openInNewTab(url.slice(1));
        } else {
          this.props.history.push(url);
        }
    };
  }
  render() {
    const { viewType,childStoreList } = this.props;
    const { showOptionsDiv, showProfileMenu, showCartMenu, showNameMenu, showAdminDropDown } = this.state;
    const boldStyle = {
      fontWeight: 'bold',
      letterSpacing: '0.7px'
    };
    const popupStyle = {fontWeight: 100, fontSize: '15px'};
  const isReunionAppPage = this.props.location.pathname === '/reunionApp';
  const isHomePage = window.location.pathname === '/home' ? true : false;
    return (
      <>
       {this.state.isPopupShowDCF && !_.isEmpty(this.state.dartCartReportData) &&  this.state.dartCartReportData.length > 0 && <DraftCartReportPopup closePopUpDCR={this.closePopUpDCR} dispalyData = {this.state.dartCartReportData}/>}
      <div className='heroHeader ui stackable grid zero-margin' computer = {3} tablet = {2}>
       <div className="heroHeaderWrapper">
        <div className = 'header-top-left'>
        {renderImages.getImage({
              src: new_tv_logo,
              align: 'left',
              alt: 'truevalue',
	            className: 'cursor_p headerLogoImg',
              onClick:this.redirectHome
        })}
        {
          _.isEmpty(this.state.needHelpNumber)  || utilities.isMobile() ?
          (renderImages.getImage({
              src: hereForYouLogo,
              align: 'left',
              alt: 'truevalue',
                className: 'cursor_p headerLogoImg needHelp',
                onClick:this.openHereForYouModel
          })) :
          (
            <Popup content={`phone #: ${this.state.needHelpNumber}`} className={"iconPopUp"} style ={popupStyle} trigger ={renderImages.getImage({
              src: hereForYouLogo,
              align: 'left',
              alt: 'truevalue',
              className: 'cursor_p headerLogoImg needHelp',
              onClick:this.openHereForYouModel
            })} />
          )
        }
            <Modal closeIcon
            className='hereForYouInfoPoup'
            onClose = {this.closeHereForYou}
            closeOnDimmerClick={false}
            open={this.state.showHereForYou}>
            <Modal.Content className=''>
              <HereForYou handleClose ={this.closeHereForYou} handleMinimize = {this.handleMinimize}/>
            </Modal.Content>
            </Modal>      
        
        </div>        
        <div className='header_icons header-top-right'>
          <div className='userInfoWrapper'>
          <div className = 'header_user_icons_text'>
          <Modal closeIcon
         className='changeStorePopup'
         onClose = {this.closeChangeStore}
         closeOnDimmerClick={false}
            open={this.state.showChangeStore}>
            <Modal.Content className=''>
               <ChangeStore handleClose ={this.closeChangeStorePopUp}/>
            </Modal.Content>
          </Modal>
          <div className='headerNameStore' style={{ marginLeft: `${utilities.isMobile() ? '' : '12rem'}` }} >
          <p className='name_card'>Hello, {this.props.LoggedUser} &nbsp; &nbsp;  </p>
          <p> Store# {this.props.storeId}  </p>
          </div>
            <p>{this.props.storeName}</p>            
          </div>
          <div className='header_user_icons'>
          <Dropdown
              className='pointing top right'
              icon={<span className='personIcon'><img src={personIcon} /><i className='caret down icon' /></span>}
              onClick={this.toggleProfileMenu}
              open={showProfileMenu}
              onMouseEnter={this.MouseEnterProfileMenu}
              onMouseLeave={this.MouseLeaveProfileMenu}
            >
              <Dropdown.Menu
                className='user-menu'
                id='user-menu'
              >
                <div>
                  <DropdownItem className='user-menu-option'  onClick={this.openChangesStoreModel}>
                    Change Store
                  </DropdownItem>
                  {this.props.showAdminMenu && (
                    <Dropdown
                    key="Admin"
                    text="Admin"
                    id='admin-views-menu'
                    className='adminDropDownMenu'
                    onClick={this.toggleAdminMenu}
                    open={showAdminDropDown}
                    onMouseEnter={this.MouseEnterAdminMenu}
                    onMouseLeave={this.MouseLeaveAdminMenu}
                    icon={utilities.isMobile() ? (showOptionsDiv ? (
                      <i className='caret up icon' />
                    ) : (
                      <i className='caret down icon' />
                    )) : <i className='caret right icon' />}
                    >
                    
                      <DropdownMenu className={utilities.isMobile() ? 'user-viewoptions-menu-mobile' : 'user-viewoptions-menu'}>
                        {_.map(adminMenu,(option)=>{
                          return (
                          <DropdownItem 
                            onClick={()=>this.handleSelect(option.key)}
                            className='user-menu-option'
                          >
                            {option.title}
                          </DropdownItem>
                          )                         
                        } )}
                      
                      </DropdownMenu>
                      
                    </Dropdown>
                  )}
                  {userProfileMenu.map(option => {
                    const menuItem = option.name;
                    if (menuItem === 'View Options') {
                      return (
                        <>
                        {(this.props.multiStoreDetails && 
                          ((this.props.multiStoreDetails.PogMbr) || 
                          (this.props.multiStoreDetails.viewPOG && this.props.multiStoreDetails.PogMbr) ||
                          (this.props.multiStoreDetails.access === 1  && this.props.multiStoreDetails.CatMbr)  || 
                          this.props.multiStoreDetails.access !== 1)) &&
                        <Dropdown
                          key={menuItem}
                          text={menuItem}
                          icon={utilities.isMobile() ? (showOptionsDiv ? (
                            <i className='caret up icon' />
                          ) : (
                            <i className='caret down icon' />
                          )) : <i className='caret right icon' />}
                          onClick={this.toggleOptionsDiv}
                          onMouseEnter={this.MouseEnterViewOption}
                          onMouseLeave={this.MouseLeaveViewOption}
                          className='views-menu'
                          id='views-menu'
                          open={showOptionsDiv}
                        >
                          <Dropdown.Menu className={utilities.isMobile() ? 'user-viewoptions-menu-mobile' : 'user-viewoptions-menu'}>
                            <DropdownItem
                              key={MEMBER_VIEW}
                              onClick={() => this.switchView(MEMBER_VIEW)}
                              style={viewType === MEMBER_VIEW ? boldStyle : {}}
                              className='user-menu-option'
                            >
                              Member View
                            </DropdownItem>
                            <DropdownItem
                              key={CUSTOMER_VIEW}
                              onClick={() => this.switchView(CUSTOMER_VIEW)}
                              style={
                                viewType === CUSTOMER_VIEW ? boldStyle : {}
                              }
                              className='user-menu-option'
                            >
                              Customer View
                            </DropdownItem>
                          </Dropdown.Menu>
                        </Dropdown>
                        }
                        </>
                      );
                    }
                    if (menuItem === 'Account') {
                      return (
                        <DropdownItem
                          key={menuItem}
                          onClick={this.accountClick}
                          className='user-menu-option'
                        >
                          {menuItem}
                        </DropdownItem>
                      );
                    }
                    if (menuItem === 'Store Info & Access') {
                      return (
                        <DropdownItem
                          key={menuItem}
                          onClick={this.storeClick}
                          className='user-menu-option'
                        >
                          {menuItem}
                        </DropdownItem>
                      );
                    }
                    if (menuItem === 'Logout') {
                      return (
                        <DropdownItem
                          key={menuItem}
                          onClick={() => this.redirectMol()}
                          className='user-menu-option'
                        >
                          {menuItem}
                        </DropdownItem>
                      );
                    }
                    if (
                      this.props.multiStoreDetails &&
                      ((this.props.multiStoreDetails.PogMbr) || 
                      (this.props.multiStoreDetails.viewPOG && this.props.multiStoreDetails.PogMbr) || 
                      (this.props.multiStoreDetails.access === 1 && this.props.multiStoreDetails.CatMbr) ||
                      (this.props.multiStoreDetails.access !== 1 && menuItem === 'Preferences')||
                      (menuItem !== 'Preferences')))
                    {
                    return (
                      <DropdownItem
                        key={menuItem}
                        as={Link}
                        to={option.to}
                        className='user-menu-option'
                      >
                        {menuItem}
                      </DropdownItem>
                    );
                    }

                    return null;
                  })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
            {this.props.multiStoreDetails && this.props.multiStoreDetails.access === 1 ?
            <>
            <Popup content='Product Scan' trigger={
            <Link to='/Scan' className='scanLink'>
            <img src={barcodeIcon} />
            </Link>} />
            
            </>
            :
            <>
            <Popup content='Product Scan' trigger={
            <Link to='/Scan' className='scanLink'>
            <img src={barcodeIcon} />
            </Link>} /> 
           </>
            }
            <Icon.Group>
              <CartFlyout
                toggleCartMenu={this.toggleCartMenu}
                showCartMenu={!(isReunionAppPage || this.props.multiStoreDetails && this.props.multiStoreDetails.access === 1 ) && showCartMenu}
                disableCart={isReunionAppPage ||  this.props.multiStoreDetails &&  this.props.multiStoreDetails.access === 1 }
              />
              {!(isReunionAppPage || this.props.multiStoreDetails &&  this.props.multiStoreDetails.access === 1 ) && !this.props.notifyUser && this.props.cartCount && this.props.cartCount > 0 ? (
                <Label
                  color='orange'
                  onClick={this.totalCartcount}
                  size='mini'
                  floating
                  circular
                  content={this.props.cartCount}
                  className='cart-counter'
                />
              ) : null}
              {!(isReunionAppPage || this.props.multiStoreDetails && this.props.multiStoreDetails.access === 1 ) && (this.props.notifyUser) ? (
                 <Label
                    size='tiny'
                    color='yellow'
                    floating
                    circular
                    className='cart-counter1'
                    content={<i style={{ color: 'black', fontSize:'11px'}}>!</i>}/>                   
              ) : null}
            </Icon.Group>
            
          </div>
        </div>
        <Responsive {...Responsive.onlyComputer} minWidth={1241} maxWidth={BIGDESKTOP}>
        <div className="searchDiv" id="">
          <CategoryList />
        </div>
        </Responsive>
        </div>
        </div>
        <Modal closeIcon
         className='changeStorePopup vendorPopup'
         onClose = {this.closeVendorPopUp}
         closeOnDimmerClick={false}
            open={this.state.showVendorpopUp && isHomePage}>
            <Modal.Content className=''>
              <div className='vendorPopUpContent'>
                <p>Dear Retailer:</p>
                <p>We would like you to make you aware of several new content areas on MOL which have been added to assist with online selling, third party website selling, MAP policies and authorized dealer programs:</p>
                <p>Online Selling Restrictions - We have recently seen a surge in supplier policies related to online selling. We have therefore created an MOL page which lists supplier policies, the items to which such restrictions may apply and contact people at each supplier. IF YOU ARE RESELLING ITEMS ONLINE, PLEASE FAMILIARIZE YOURSELF WITH THIS PAGE. CONSEQUENECS FOR VIOLATING THESE SUPPLIER POLICIES CAN BE SEVERE! <a target="_blank" href='/content/online-selling-restrictions'>https://nwa.truevalue.com/content/online-selling-restrictions</a>.</p>
                <p>MAP/IMAP Supplier Policies - Where product suppliers have provided us Minimum Advertised Price Policies (which may also include advertising method restrictions), they will be posted on this page on MOL <a target="_blank" href='/content/minimum-advertised-pricing-policies'>https://nwa.truevalue.com/content/minimum-advertised-pricing-policies</a>. We also updated Netwarehouse so that MAP or IMAP prices, where applicable, will be displayed under the "Additional Information" section of the product detail page.</p>
                <p>Authorized Dealer Programs - Product suppliers that require you to enroll into an authorized reseller program before purchasing their products will be listed under this page on MOL <a target="_blank" href='/content/authorized-dealer-programs'>https://nwa.truevalue.com/content/authorized-dealer-programs</a>. Also listed are links to the applications, forms or agreements required by the product supplier for enrollment in the Authorized Dealer Program. Items subject to these programs are marked as such on Netwarehouse as well, under the product detail page.</p>
                <button onClick={this.acceptAcknowledge}>I acknowledge receipt of this notification</button>
                </div>
            </Modal.Content>
          </Modal>
      </div>
      </>
    );
  }
}
const DraftCartReportPopup =({closePopUpDCR, dispalyData}) => {
  return(
    <div className="onlyReunionPopupWrapper">
      <div className="onlyReunionPopup">
        <Dialog width='1' height='1' position='absolute'>
            <span className='onlyReunionPopup_close' onClick={closePopUpDCR}>X</span>
            <div className="dcfHeader">
                <h4 className="ui header" style={{'textAlign': 'center'}}>There are pending Reunion orders in your cart that are approaching the order deadline. Please submit the cart(s) prior to the order deadline.</h4>
            </div>
            <div class="dcfContent">
              {
                <table className="cartTable">
                  <tr  className="cartHeaders">
                    <th>Cart ID</th>
                    <th>Cart Name</th>
                    <th>Event Name</th>
                    <th>Order Deadline</th>
                  </tr>
                  { dispalyData.length > 0 && dispalyData.map((items)=>{
                    let expireIn = items['DueInDays'];
                    expireIn = items['DueInDays'] === '0' ? 'Today' : expireIn;
                  return <tr  className="cartHeaders">
                      <td className="cartHeaders">{items['CartId']}</td>
                      <td className="cartHeaders"> {items['CartName']}</td>
                      <td className="cartHeaders">{items['EventName']}</td>  
                      <td className="cartHeaders">{expireIn}</td>
                    </tr>
                  })
                  }
                </table>
              }
            </div>    
        </Dialog>
      </div>
    </div>
  )

}
const mapStateToProps = state => {
  let currentCart =
    state.cart.orders.allCarts &&
    state.cart.orders.allCarts.length &&
    state.cart.orders.allCarts.find(cart => {
      return parseInt(state?.cart?.orders?.currentCart?.cartId) === parseInt(cart?.cartId);
    });
  let cartCount = currentCart ? currentCart.cartCount : 0; //handling case where some other user session modifies current cart
  const userName = state.SessionReducer.userName;
  const LoggedUser = state.SessionReducer.address && state.SessionReducer.address.userName
  const storeId = state.SessionReducer.storeId;
  const storeName = state.SessionReducer.address && state.SessionReducer.address.storeName;
  const viewTypeStorage = localStorage.getItem('viewType');
  const wpMenu = state.SessionReducer.wpMenu;
  return {
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    cartCount,
    userName,
    LoggedUser,
    storeId,
    storeName,
    notifyUser: state.cart.orders.notifyUser,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    showAdminMenu: state.SessionReducer?.address?.showAdminMenu,
    isVendorPopUp: state.SessionReducer?.isVendorPopUp,
    userguid: state.SessionReducer.userguid,
    storeguid: state.SessionReducer.storeguid,
    wpMenu

  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ switchView, getFavoriteItems, logout,setVendorPopUp, setHereForYou }, dispatch)
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderTop));

