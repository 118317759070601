import { GET_FULFILLMENT_DETAILS, } from '../../urlConstants';
import restUtils from '../../utils/restUtils';


export const TOGGLE_PANEL = 'TOGGLE_PANEL';
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const togglePanel = (panelId) => ({
  type: TOGGLE_PANEL,
  payload: panelId,
});

const fetchDataRequest = (panelId) => ({
  type: FETCH_DATA_REQUEST,
  payload: panelId,
});

const fetchDataSuccess = (panelId, data) => ({
  type: FETCH_DATA_SUCCESS,
  payload: { panelId, data },
});

const fetchDataFailure = (panelId, error) => ({
  type: FETCH_DATA_FAILURE,
  payload: { panelId, error },
});

export const fetchData = (panelId, fulfillmentNumber) => {
  return async (dispatch, getState) => {
    const panelState = getState().panelReducer.panels[panelId];
    if (panelState && panelState.data)
      return;
    dispatch(fetchDataRequest(panelId));
    try {
      const requestUrl = `${GET_FULFILLMENT_DETAILS}?fulfillmentRequestNumber=${fulfillmentNumber}`;
      restUtils.getDataWithBearer(requestUrl).then(
        res => {
          let data = res.data.fulfillmentRequest;
          dispatch(fetchDataSuccess(panelId, data));
        });

    } catch (error) {
      dispatch(fetchDataFailure(panelId, error.toString()));
    }
  };

};