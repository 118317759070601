import React from 'react';
import restUtils from '../../utils/restUtils';
import { Loader, Responsive, Pagination } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import "./OrderCss.css";
import utilities, { shouldHideSUGG_RETAILForPreference, shouldHideCostForPreference } from '../../utils/utilities';
import ExcelExport from '../common/ExcelExport';
import renderImages from '../common/RenderImages';
import { Link } from 'react-router-dom';
import { GET_ORDER_DETAILS, GET_FULFILLMENT_LIST, GET_FULFILLMENT_DETAILS, UPS_URL, FEDEX_URL } from '../../urlConstants';
import InternalServerError from '../ErrorPages/500_error_page';
import history from '../../history';
import ReactToPrint from 'react-to-print';
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { getTableRow, getSelect, getTable } from '../common/ComponentUtilities';
import { showItemCount, getPagination } from '../common/CustomComponents';
import { TierIcon } from '../common/TierIcon';
import _ from 'lodash';
import Panel from './PanelComponent';
class orderItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: [],
      loading: false,
      serverError: false,
      poNum: '',
      isMultiPo: false,
      img_txt_to_display: (this.props.getprefer && this.props.getprefer.thumbnailImage && this.props.getprefer.thumbnailImage !== 'Y') ? 'Show Images' : 'Hide Images',
      orderItemId: this.props.location.state ? this.props.location.state.orderDetails : null,
      orderDetails: this.props.location.state.orderDetails,
      firstItem: 0,
      lastItem: 0,
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      isHovered: false,
      isChecked: false,
      activeTab: "tab1",
      fulfillments: [],
      collapseActiveTab: 0,
      fulfillmentDetails: [],
    }
    this.page = 1;
    this.pageSize = 50;
    this.sortParam = 'itemNbr';
    this.sortDirection = 'asc';
    this.orderStatusPath = window.history?.state?.state?.pushUrl || '';
    this.scrollPosition = 0;
    // this.handleChecked = this.handleChecked.bind(this);

  }



  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage ?
      this.props.getprefer.resultPerPage : '50';
    this.handleTabChange(this.state.activeTab);
    // this.togglePanel(this.state.collapseActiveTab,this.state.fulfillments.fulfillmentRequestNumber)
    if (queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else {
      this.getItemDetails();
    }
  }

  roundNull = (data, field) => {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    if (data) {
      return <MaskPrice
        field={field}
        getprefer={getPrefer}
        viewType={this.props.viewType}
        fieldVal={Number(data).toFixed(2)}
      />
    } else return "-";
  }

  // showHideImages = evt => {
  //   let img_txt_to_display =
  //     evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
  //   this.setState({ img_txt_to_display: img_txt_to_display });
  // };

  // changeSortParam = e => {
  //   this.page = 1;
  //   this.sortParam = e.currentTarget.value;
  //   this.scrollPosition = 0;
  //   this.getItemDetails();
  // };

  // changeSortDirection = e => {
  //   this.page = 1;
  //   this.sortDirection = e.currentTarget.value;
  //   this.scrollPosition = 0;
  //   this.getItemDetails();
  // };

  // handleTrackingMouseOver = trackingNums => {
  //   return <ul className={"mouseHover"}>
  //       {trackingNums && trackingNums.map((num, index)=> {
  //       return <li className={"mouseHoverText"} key={index}>{num}</li>
  //     })}
  //   </ul>
  // }

  // setScrollPosition = () => {
  //   this.scrollPosition = window.pageYOffset;
  //   this.setBackButtonPath();
  // }

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' :
      queryParam[1].includes('SortType=desc') ? 'desc' : '';
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    let scrollPosition = this.scrollPosition;
    this.setState({
      scrollPosition: scrollPosition
    }, () => {
      this.getItemDetails();
    });
  }

  setBackButtonPath = () => {
    let pathName = '';
    this.scrollPosition = window.pageYOffset;
    pathName = 'scrollPosition=' + this.scrollPosition;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam=' + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType=' + this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    let pushUrl = '?' + pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }

  goToPreviousPage = () => {
    if (this.orderStatusPath) {
      history.push(this.orderStatusPath)
    } else {
      history.goBack();
    }
  }

  // renderTrackingDetails =(retailerTrackingNumber) => {
  //   const uniqTrackingNum = _.uniq(retailerTrackingNumber.split(','))
  //   if(uniqTrackingNum.length > 1 ) {
  //     return <span className='order_details' onMouseOver={()=>this.setState({isHovered :true})} onMouseOut={()=>this.setState({isHovered :false})}>
  //       <a  href="#"  onClick={() => this.openTrackingSite(uniqTrackingNum)}> {this.state.isHovered && this.handleTrackingMouseOver(uniqTrackingNum)}{`${uniqTrackingNum[0]}...>>`}</a>
  //     </span>
  //   } else {
  //     return <span className='order_details'><a href="#"  onClick={() => this.openTrackingSite(uniqTrackingNum)}>{uniqTrackingNum[0]}</a></span>
  //   }
  // }

  //  onOrderpagesize=e=>{
  //   this.pageSize = e.target.value;
  //   this.page = 1;
  //   this.getItemDetails();
  // }

  //  onPageChange = e => {
  //   let queryPage = e.currentTarget.text;
  //   if (e.currentTarget.text === 'Next') {
  //     queryPage =
  //       this.state.currentPage < this.state.totalPages
  //         ? this.state.currentPage + 1
  //         : this.state.currentPage;
  //   }
  //   if (e.currentTarget.text === 'Prev') {
  //     queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
  //   }
  //   if (e.currentTarget.text === '»') {
  //     queryPage = this.state.totalPages;
  //   }
  //   if (e.currentTarget.text === '«') {
  //     queryPage = 1;
  //   }
  //   this.page = queryPage;
  //   this.scrollPosition = window.pageYOffset;
  //   window.scrollTo(0, 0);
  //   this.getItemDetails();
  // };

  // showLess = (itemNumber) => {
  //   let itemDetails = this.state.itemDetails;
  //   this.state.itemDetails.forEach((order, index) => {     
  //      if(order.itemNbr === itemNumber)
  //      itemDetails[index].showLess = true ;        
  //   });
  //   this.setState({ itemDetails});
  // }

  // showMore = (itemNumber) => {
  //   let itemDetails = this.state.itemDetails;
  //   this.state.itemDetails.forEach((order, index) => {     
  //      if(order.itemNbr === itemNumber)
  //      itemDetails[index].showLess = false ;        
  //   });
  //   this.setState({ itemDetails});
  // }
  // handleChecked(e){
  //   this.setState(prevState => {
  //     return { isChecked: !prevState.isChecked };
  //   });
  //   this.getItemDetails(!this.state.isChecked);
  // }

  getItemDetails = (checked = this.state.isChecked) => {
    const sortArr = { sortParam: this.sortParam, sortType: this.sortDirection };
    const orderDetails = this.state.orderDetails;   
    let includeAllItems = checked ? 1 : 0;
    const url = `${GET_ORDER_DETAILS}?orderNumber=${orderDetails?.orderNumber}`
    this.setState({ loading: true });
    restUtils
      .getDataWithBearer(url)
      .then(response => {
        this.setState({ itemDetails: response.data.lineItems, loading: false });
        // this.setState({ itemDetails: response.data.lineItems,  firstItem: response.data.firstItem, lastItem: response.data.lastItem,
        //   currentPage: response.data.pageNumber, totalPages: response.data.pageCount,  totalItems: response.data.totalItems,
        //    orderTotal: response.data.orderTotal, totalWeight: response.data.totalWeight, 
        //   loading: false, serverError: false, poNum:response.data.memberOrderRef, isMultiPo: response.data.isMultiPOs });
        this.getFulfillmentList();
        window.scrollTo(0, this.scrollPosition);
        this.setBackButtonPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });
  }

  checkFillRateAndQtyCanShow = () => {
    const orderDetails = this.state.orderDetails;
    return ((orderDetails.displayedStatus === 'CANCELED' && utilities.isEmptyOrNullString(orderDetails.orderNumber)) ||
      orderDetails.displayedStatus === 'ORDER CREATED' || orderDetails.displayedStatus === 'ORDER FILLING'
      || orderDetails.displayedStatus === 'DC ORDER COMPLETE');
  }

  getWarehouseImage = (imageUrl, img_style) => {
    const imageUrl1 = `https://images.truevalue.com/getimage.asp?ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2&id=${imageUrl}`;
    return renderImages.getImage({ src: imageUrl1, className: 'orderImage', style: { display: img_style } })
  }

  getWareHouseRows = (rowProps, img_style, order) => {
    const warehouseCols = this.getWareHouseColumns(order, img_style);
    return (
      <>
        {getTableRow(rowProps, warehouseCols)}
      </>
    )
  }
  handleTabChange = (tab) => {
    this.setState({ activeTab: tab });
  }
  getFulfillmentList = () => {
    const requestUrl = `${GET_FULFILLMENT_LIST}?orderNumber=${this.state.orderDetails?.orderNumber}`;
    this.setState({ loading: true });
    restUtils.getDataWithBearer(requestUrl).then(
      res => {
        this.setState({
          fulfillments: res.data,
          loading: false
        })       
      });
  } 
  getWarehouseHeaders = () => {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl = shouldHideCostForPreference(this.props.viewType, getPrefer);
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(this.props.viewType, getPrefer);
    const tableHeader = [
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: '' },
      { props: { className: 'OderItemHeader', id: 'no_border' }, text: 'Item #' }
    ];

    tableHeader.push(
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'ADV Code #'},
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'UPC #' },
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'Model #'},
      { props: { className: 'OderItemHeader', id: 'no_border' }, text: 'Item Description' },
      // //{props:{className:'allOdrHeader', id:'no_border'}, text: 'Program'},
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'Seq #'},
      // { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'RDC' },
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'Weight'},
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'Pack'},
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Ord Qty' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Alloc. Qty' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Shipped Qty' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Canceled Qty' },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: utilities.getLabelsByType(this.props.viewType, "COST", getPrefer) },
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: utilities.getLabelsByType(this.props.viewType, "EXTENDED_COST", getPrefer) },
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'Source'},
      //{props:{className:'allOdrHeader', id:'no_border'}, text: 'Cancel Code'},
      { props: { className: 'allOdrHeader', id: 'no_border' }, text: 'Status' },
    );


    return (getTableRow({}, tableHeader, true))
  }

  getWareHouseColumns = (order, img_style) => {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl = shouldHideCostForPreference(this.props.viewType, getPrefer);
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(this.props.viewType, getPrefer);
    const tierIcon = order.tieredCostInd ? <TierIcon /> : "";
    const tableDataCols = [
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: this.getWarehouseImage(order.lineItemId, img_style) },
      { columnProps: { className: 't-left', id: 'field_width' }, columnText: <Link id='item_field' onClick={this.setScrollPosition} to={'/product/' + order.lineItemId}>{utilities.hyphenForNull(order.lineItemId)}</Link> },
    ];
    tableDataCols.push(
      // { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.advCode) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order?.upc) },
      // { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.model) },
      { columnProps: { className: 't-left', id: 'field_Desc_width' }, columnText: utilities.hyphenForNull(order.itemDescription) + "\n" + (order.vendorName ? order.vendorName : '') },
      //{ columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.noteProgram) },
      // { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.seqNumber) },
      // { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order?.rdcNbr) },
      // { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order?.itemWeight) },
      // { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order?.pack) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: order.lineItemQty },

      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order?.fulfillmentInfo?.length>0 ? order?.fulfillmentInfo.reduce((sum,item) => sum+item.allocatedQty,0)  : "") },

      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.shippedQty) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.cancelledQty) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: <>{this.roundNull(order?.itemUnitPrice, 'Member Cost black')}</> },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: <>{this.roundNull(order?.lineTotals.lineTotal, 'Member Cost black')}</> },
    );

    tableDataCols.push(
      // {columnProps:{ className: 't-center', id: 'field_width' }, columnText:utilities.hyphenForNull(order?.source)},
      //{columnProps:{ className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.cancelCode)},
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.status) }
    )
    return tableDataCols;
  }

  // togglePanel = (index, itemNumber) => {
  //   this.getFulfillmentDetails(itemNumber);
  //   this.setState({ collapseActiveTab: this.state.collapseActiveTab === index ? null  : index, fulfillmentDetails: itemNumber });
  //   // this.setState({  fulfillmentDetails: itemNumber });
  // };


  render() {
    const orderDetails = this.state.orderDetails;
    const itemDetails = this.state.itemDetails;
    const fulfillments = this.state.fulfillments;
    const isChecked = this.state.isChecked;
    const fulfillmentDetails = this.state.fulfillmentDetails;
    const multiPoSelectMsg = isChecked ? 'Show All Items for the PO' : 'Show All Items for the Invoice';
    const img_style = this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl = shouldHideCostForPreference(this.props.viewType, getPrefer);
    const poNumber = orderDetails.referenceData.filter((items) => {
      if (items.data === "koctOrderNbr") {
        return items.value;
      }
    })
    const promoNbr = orderDetails.referenceData.map((items, index) => {
      return items.data === "promoNbr" && utilities.hyphenForNull(items.value)
    })
    const  dataPanel = fulfillments.length > 0 && fulfillments.items.map((item, index) => (
      <Panel
      key={index} 
      index = {index}
      panelId = {`panel${index+1}`}
      item = {item}
      fulfillmentNumber = {item?.fulfillmentRequestNumber } 
      />
    ))

    return (
      this.state.serverError ? <InternalServerError /> :
        <div className='page-wrap' ref={node => (this.componentRef = node)}>
          <div className='editOrderHeaderTop'>
            <span className='page-header'>Order Details (OMS)</span>
            {/* <span id='print_img'>
              <ReactToPrint
                pageStyle='@page{size:landscape;}'
                trigger={() => (
                  <i className='print icon' id='printicon'></i>
                )}
                content={() => this.componentRef}
              />
            </span> */}
          </div>
          {this.state.loading &&
            <div>
              <Loader active />
            </div>
          }
          <div className='editOrderHeaderTwo'>
            <div className='sort_by'>           
              <span className='order_count'>
                <ExcelExport
                  className='excel_left excelHide'
                  fileName={'Order Item Details'}
                  sortParam={this.sortParam}
                  sortDirection={this.sortDirection}
                  orderDetails={this.state.orderDetails}
                  storeId={this.props.storeId}
                  userId={this.props.userId}
                  viewType={this.props.viewType}
                  getprefer={
                    this.props.getprefer &&
                      this.props.getprefer.customerView
                      ? this.props.getprefer.customerView
                      : 'c1'
                  }
                  page={this.page}
                  orderResults={itemDetails}
                  aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
                  onVariablePricing={this.props.onVariablePricing}
                  checkFillRateAndQtyCanShow={this.checkFillRateAndQtyCanShow} />
              </span> &nbsp;&nbsp;
                <span id='back_button' onClick={this.goToPreviousPage}>Back to Orders</span>
            </div>
            <br />

            <div className='font-style'>
              <p className='itemDetails itemDetailsMobile'>Order Type:<span className='order_details'> {orderDetails.referenceData.map((items, index) => {
                                    return items.data === "mfOrderType" && utilities.hyphenForNull(items.value ? items.value : "-")

                                  })}</span></p>
              <p className='itemDetails itemDetailsMobile'>PO #:<span className='order_details'>{poNumber[0].value}</span></p>
              <p className='itemDetails itemDetailsMobileInvoice'>Order Total:<span className='order_details'>{'$' + utilities.roundForNotNull(orderDetails.orderTotals.orderTotal)}</span></p>
              <p className='itemDetails itemDetailsMobileInvoice'>Total Weight:<span className='order_details'>-</span></p>
              <p className='itemDetails itemDetailsMobileInvoice'>Status:<span className='order_details'>{utilities.hyphenForNull(orderDetails.status)}</span></p>
              <p className='itemDetails itemDetailsMobileInvoice'>Created Date:<span className='order_details'>{orderDetails.orderDate ? utilities.hyphenForNull(utilities.getDateFromTimeStamp(orderDetails.orderDate)) : '-'}</span></p>

            </div>
          </div>
          <div className={window.innerWidth > 1024 ? "allOrdersTable orderstatusMargin" : "allOrdersTableItem"}>
            <Responsive minWidth='1025'>
              <div class="eighteen wide column">                           
                <div className="TabItems">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        className={`nav-link ${this.state.activeTab === 'tab1' ? 'active' : ''}`}
                        onClick={() => this.handleTabChange('tab1')}
                      >
                        Order Line Items <span className='itemCounts'>{itemDetails?.length}</span>
                      </button>

                    </li>
                    <li className="nav-item">
                      <button
                        className={`nav-link ${this.state.activeTab === 'tab2' ? 'active' : ''}`}
                        onClick={() => this.handleTabChange('tab2')}
                      >
                        Fulfillment Requests <span className='itemCounts'>{fulfillments.items?.length}</span>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div className={`tab-pane ${this.state.activeTab === 'tab1' ? 'active' : ''}`} id="tab1Content">
                      <h3 className='contentTitle'> Order Line Items </h3>
                      {getTable({ class: "ui celled table all-wh-table ", id: 'font-size-orders' }, this.getWarehouseHeaders(), itemDetails, this.getWareHouseRows, { className: 'wh-row' }, img_style)}
                    </div>
                    <div className={`tab-pane ${this.state.activeTab === 'tab2' ? 'active' : ''}`} id="tab2Content">
                      <h3 className='contentTitle'>Fulfillment Requests</h3>
                      <div>
                        {this.state.activeTab === 'tab2' && dataPanel}
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </Responsive>

          </div>
          {!this.state.loading &&
            <><div className='t-right'>
              <i className='addedBy'>
                *Price reflects override retail entered when order was submitted.
              </i>
            </div>
              <div className='t-right'>
                <i className='addedBy'>
                  **Harvard, Atlanta, Woodland and Central Ship are the preferred Ship to customer RDCs. Orders placed via these RDCs will have tracking number information available.
                </i>
              </div></>
          }
        </div>
    )
  }
}


const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId, userName, userId, getprefer: state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer: state.preference.listData,
    aliasSKURetailerGrpID: state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
    onVariablePricing: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.onVariablePricing : false,
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({}, dispatch)
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(orderItemDetails));
