import React, { Component } from "react";
import BreadcrumbComp from "../../../components/BreadcrumbComp";
import OptionsSection from "./OptionsSection";
import { getPagination } from "../../common/CustomComponents";
import PalletListView from "./PalletListView";
import { MaskPrice } from '../../common/MaskPrice';
import PalletGridView from "./PalletGridView";
import utilities from "../../../utils/utilities";
import { exportExcel, getFirstCartId } from "../../common/ComponentUtilities";
import { connect } from "react-redux";
import Filter from "./Filter";
import restUtils from "../../../utils/restUtils";
import { Loader } from "semantic-ui-react";
import { isArray } from "util";
import _ from "lodash";
import {
  isSearchRequired,
  isSortRequired,
  isShowByRequired,
  getBreadcrumbLinks,
  isSeqRequried,
  getPageNumber,
  handleQtyChange,
  addToCart,
  getVendorDetails,
  setScrollPosition,
  palletLocationVal
} from "./itemListingUtilities";
import { PALLET_LISTING } from "../../../urlConstants";
import { viewAllCarts, updateQty } from "../../Cart/actions";
import UnderConstruction from "../../UnderConstruction";
import { MEMBER_VIEW } from '../../../components/NavBar/menuConstants';

class PalletListing extends Component {
  constructor(props) {
    super(props);
    const {
      eventId,
      flowType,
      fromReunionHome,
      promoName,
      relayNbr,
      relayYear,
      shoppingId,
      vendorId
    } = props.match?.params ? props.match?.params: props ;
    const shoppingAreaName = props?.match?.params?.shoppingAreaName ? decodeURIComponent(props?.match?.params?.shoppingAreaName): props?.shoppingAreaName;
    const vendorName = decodeURIComponent(props?.match?.params?.vendorName);
    const shoppingAreaMetaId = props?.location?.state?.shoppingAreaMetaId ? decodeURIComponent(props?.location?.state?.shoppingAreaMetaId): props?.shoppingMetaDataId;
    this.state = {
      fromReunionHome: fromReunionHome,
      flowType: flowType,
      eventId: eventId,
      relayNbr: relayNbr,
      relayYear: relayYear,
      promoName: promoName,
      shoppingAreaName: shoppingAreaName,
      shoppingAreaId: shoppingId,
      shoppingAreaMetaId: shoppingAreaMetaId,
      promoNbr: eventId,
      vendorId: vendorId,
      fromVendorPage: vendorId? true : false,
      orderDeadline:'',
      allItems: vendorId? false : true,
      dealDetails: false,
      vendorName: vendorName,
      filters: [],
      isLoading: false,
      palletData: [],
      quantities: [],
      eventEnableAfter: '',
      eventEnableBefore:'',
      eventMessage: '',
      overrideRetails: [],
      isNoProducts: false,
      imgTextToDisplay: "Hide Images",
      isGridView: utilities.getGridView(props.getprefer),
      paginationParams: {},
      appliedFilters: {},
      searchValue: this.props?.searchValue ? this.props?.searchValue: "",
      searchPattern: "[0-9]*",
    };
    this.pageSize = this.props.getprefer && this?.props.getprefer?.resultPerPage
      ? this.props.getprefer.resultPerPage
      : "50";
    this.scrollPosition = 0;
    this.sortParam = "pallet_nbr";
    this.sortDirection = "asc";
    this.searchby = this.props?.searchBy ? this.props?.searchBy:"pallet_nbr";
    this.searchValue = this.props?.searchValue ? this.props?.searchValue: "";
    this.page = 1;
    this.defaultChecked = utilities.isEmptyOrNullString(vendorId)
      ? {}
      : {
        name: "Vendors",
        value: vendorId,
      };
    this.defaultCheckedFilterValue = utilities.isEmptyOrNullString(vendorId)
      ? {}
      : {
        name: "Vendors",
        valueList: [vendorId],
      };
    this.filterList = this.props.fromScanning && this.props.filterBy ? this.props.filterBy : '';
    this.pathname = "";
    this.handleQtyChange = handleQtyChange.bind(this);
    this.addToCart = addToCart.bind(this);
    this.searchByOptions = this.getSearchByOptions(flowType);
    this.getVendorDetails = getVendorDetails.bind(this);
    this.setScrollPosition = setScrollPosition.bind(this);
    this.hideFilter = this.props.fromScanning && true
  }

  componentDidMount() {
    this.props.updateQty(false)
    let queryParam = window.location.href.split('?');
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else {
    this.fetchPallets();
    }
  }
  componentDidUpdate() {
    if(this.props.qtyUpdate) {
      this.scrollPosition = window.pageYOffset;
      this.fetchPallets();
      this.props.updateQty(false)
    }
  }

  handleViewChange = (isGridView) => {
    this.setState({ isGridView });
  };

  showHideImages = (evt) => {
    let imgTextToDisplay =
      evt.target.innerHTML === "Show Images" ? "Hide Images" : "Show Images";
    this.setState({ imgTextToDisplay });
  };

  getSearchByOptions = () => {
   return [
      {
        props: { value: "pallet_nbr", id: "item", className: "SelectOption" },
        text: "Pallet SKU #",
        pattern: "[0-9]*",
      },
      {
        props: { value: "model", id: "models", className: "SelectOption" },
        text: "Model #",
        pattern: "[a-zA-Z0-9-/. ]*",
      },
      {
        props: { value: "description", id: "upc", className: "SelectOption" }, 
        text: "Description",
        pattern: "[a-zA-Z0-9-/\"' ]*",
      }
    ];
  };
  getPalletListingPayload = (isCompleteList) => {
    const {
      relayYear,
      relayNbr,
      eventId,
      paginationParams,
      searchValue,
      shoppingAreaId,
      flowType,
      promoNbr
    } = this.state;
    const resultPerPage = !isCompleteList
      ? this.pageSize
      : paginationParams?.totalItems;
    let currentCart = this.props?.carts && this.props.carts.find(cart => parseInt(cart.cartId) === parseInt(this.props.currentCartId)); 
    let getCartId = getFirstCartId(shoppingAreaId,eventId,this.props.carts, this.props.currentCartId)
    return {
      promoNbr: parseInt(eventId),
      rdcNbr:this.props.rdc,
      memberNbr: this.props.storeId,
      shoppingId:shoppingAreaId,
      flowType,
      relayNbr: relayNbr,
      relayYear: relayYear ? relayYear : 2020,
      cartId: getCartId,
      multiStoreCartId : utilities.msExist(this.props.multiStoreDetails) > 1 && currentCart?.parentId ? currentCart.parentId : '',
      shoppingId : shoppingAreaId,
      vendorId:parseInt(this.state.vendorId),
      aliasSKURetailerGrpID: utilities.isAliasSkuRequired(
        this.props.multiStoreDetails
      )
        ? 1
        : 0,
      sortBy: this.sortParam,
      sortOrder: this.sortDirection,
      ...(!utilities.isEmptyOrNullString(searchValue)
        ? {
            searchby: this.searchby,
            searchType: searchValue,
          }
        : {}),
      page: isCompleteList ? "1" : this.page,
      resultPerPage,
      ...(!_.isEmpty(this.filterList) && !_.isEmpty(this.defaultCheckedFilterValue)
        ? { filterBy: [...[this.defaultCheckedFilterValue],...this.filterList] }
        : !_.isEmpty(this.filterList)
        ? { filterBy: this.filterList}
        : this.defaultCheckedFilterValue
        ? { filterBy: [this.defaultCheckedFilterValue] }
        : {}),
    };
  };

  getFieldFromUrl = (queryString, fieldName) => {
    return queryString?.split(fieldName)[1]?.split("&")[0];
  };

  getUrlFilters = (queryParam) => {
    // to reinitialise fields from url
    const queryString = queryParam[1];
    let getParams = queryParam[1].split("&");
    this.page = getParams[0].split("=")[1];
    this.pageSize = getParams[1].split("=")[1];
    this.sortParam = queryString.includes("&SortParam=")
      ? this.getFieldFromUrl(queryString, "SortParam=")
      : this.sortParam;
    this.sortDirection = queryString.includes("SortType=asc")
      ? "asc"
      : queryString.includes("SortType=desc")
        ? "desc"
        : this.sortDirection;
    if (queryString.includes("&SearchBy=")) {
      this.searchby = this.getFieldFromUrl(queryString, "SearchBy=");
    }
    if (queryString.includes("&SearchValue=")) {
      this.searchValue = this.getFieldFromUrl(queryString, "SearchValue=");
    }
    this.scrollPosition = queryString.includes("scrollPosition=")
      ? this.getFieldFromUrl(queryString, "scrollPosition=")
      : 0;
    let filterString = queryString.includes("filterString")
      ? queryString.split("filterString=")[1]
      : "";
    let appliedFilters = utilities.getFilterArrayAsMultiSelectObject(
      filterString
    );
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        searchValue: this.searchValue,
        appliedFilters: appliedFilters,
      },
      () => {
        this.fetchPallets();
      }
    );
  };

  fetchPallets = () => {
    this.setState({ isLoading: true });
    const payload = this.getPalletListingPayload();
    restUtils
      .postData(PALLET_LISTING, payload)
      .then((res) => {
        if(res?.data && (res.data?.evtStsActiveBefore === false || res.data?.evtStsActiveAfter === false)) {
          this.setState({
            eventEnableAfter: res.data?.evtStsActiveAfter,
            eventEnableBefore: res.data?.evtStsActiveBefore,
            eventMessage: res.data?.Message,
          })
          return;
        } else if (!res?.data.palletLists || res.data.palletLists.length === 0) {
          this.setState((prevState) => {
            return {
              isLoading: false,
              palletData: [],
              isNoProducts: true,
              paginationParams: {
                ...prevState.paginationParams,
                totalPages: "0",
                currentPage: "0",
                totalItems: "0",
                firstItem: "0",
                lastItem: "0",
              },
            };
          });
          return;
        }
        const { data } = res;
        let filters = res?.data?.filters;
        filters.forEach(filter=>{
          if(filter.name==="Vendors"){
            filter.valueList = utilities.objSortValues(filter.valueList);
          }
        })
        this.setState({
          isNoProducts: false,
          isLoading: false,
          palletData: data.palletLists,
          paginationParams: data.pagination,
          filters,
          palletItems: [],
          orderDeadline: res.data?.OrderDeadline ? utilities.getDateFromTimeStamp(res.data.OrderDeadline): '',
          quantities: data.palletLists?.map((pallet) => {
            return [
              utilities.emptyForNull(pallet.userQty),
              utilities.emptyForNull(pallet.userQty2),
              utilities.emptyForNull(pallet.userQty3),
              utilities.emptyForNull(pallet.userQty4),
            ];
          }),
        },()=>{
          window.scrollTo(0, this.scrollPosition);
        });
        this.setBackButtonPath();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  filterCallback = (isAdd, filterKey, filterValue) => {
    let { appliedFilters } = this.state;
    this.scrollPosition = window.pageYOffset;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: [filterValue] };
    } else if (keyExists) {
      let value = appliedFilters[filterKey];
      let index = value.indexOf(filterValue);
      if (isArray(value) && index > -1) {
        value.splice(index, 1); // remove if value exists
      } else {
        value.push(filterValue); // add if value is new
      }
      if (!value.length && keyExists) {
        delete appliedFilters[filterKey];
      } else {
        appliedFilters[filterKey] = value;
      }
    }
    this.filterList = utilities.getFilterListFromObject(appliedFilters);
    this.setState(
      {
        currentPage: 1,
        appliedFilters,
        clearFilters: false,
        backPath: this.pathname,
      },
      () => {
        this.page = 1;
        this.fetchPallets();
        this.setBackButtonPath(appliedFilters);
      }
    );
  };

  changePageSize = (e) => {
    this.pageSize = e.currentTarget.value;
    this.page = "1";
    this.scrollPosition = window.pageYOffset;
    this.fetchPallets();
    this.setBackButtonPath();
  };

  onPageChange = (e) => {
    const { paginationParams } = this.state;
    this.scrollPosition = 0;
    this.page = getPageNumber(e.currentTarget.text, paginationParams);
    window.scrollTo(0, 0);
    this.fetchPallets();
    this.setBackButtonPath();
  };

  getListingHeader = () => {
    const { paginationParams, imgTextToDisplay } = this.state;
    return (
      <div className="listing-header">
        <span>
          {paginationParams.firstItem}-{paginationParams.lastItem} of{" "}
          {paginationParams.totalItems} products
        </span>
        <span onClick={this.showHideImages} id="show_and_hide_images">
          {imgTextToDisplay}
        </span>
        {paginationParams.totalPages > 0 && (
          <span className="pagination_top">
            {getPagination(
              0,
              paginationParams.currentPage,
              paginationParams.totalPages,
              this.onPageChange,
              null,
              1
            )}
          </span>
        )}
        {!this.props?.fromScanning &&
         exportExcel({
          className: "exportPDF exportPdfPLP",
          onClick: this.handleExport,
        })}
      </div>
    );
  };

  closeMultistoreModal=()=>{
    //this.fetchPallets();
  }

  getItemParams = (product, index) => {
    let itemParams = { product };
    const fieldSet = this.getFieldSet(product, index);
    itemParams = { ...itemParams, fieldSet, itemIndex: index };
    return itemParams;
  };

  getFieldSet = (pallet, index) => {
    const { getprefer, memberViewType } = this.props;
    const { access, CatMbr } = this.props?.multiStoreDetails;
    const memberCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={pallet.mbrCost}
        field={"Member Cost black"}
      />
    );
    const marketCost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={pallet.specWhseCost}
        field={"Member Cost black"}
      />
    );

    const srcost = (
      <MaskPrice
        viewType={memberViewType}
        getprefer={utilities.getPreferenceHideMask(getprefer)}
        fieldVal={pallet.regSr}
      />
    );
    return !this.state.isGridView && window.innerWidth >= 1024
      ? [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                [
                  { label: "Pallet Location:", val: palletLocationVal(pallet.PalletLocNbr, pallet.PalletLocSection)},
                  { label: "UPC:", val: pallet.upc },
                  { label: "EVT Cost:", val:marketCost, className:'First_level_details red_color' },
                  { label: "Dating:", val: pallet.terms + " Days" },
                ],
                [
                  { label: "Pallet SKU:", val: pallet.palletNbr },
                  {
                    label: "Retail Fine Line:",
                    val:
                      pallet.deptId +
                      pallet.classId +
                      " - " +
                      pallet.subClassId,
                  },
                  { label: "Member Cost:", val: memberCost },
                  { label: "Pack:", val: pallet.pack },
                ],
                [
                  { label: "Model Number:", val: pallet.model },
                  { label: "Sugg. Retail:", val: srcost },
                  {
                    label: "Savings:",
                    val: utilities.isEmptyOrNullString(pallet.savings)
                      ? "-"
                      : utilities.PercentageSavings(pallet.savings * 100),
                    className:'First_level_details red_color'
                  },
                  ...(pallet.maxQtyItemRestriction > 0) ? [{
                    label: "Max Qty:", val: pallet.maxQtyItemRestriction
                  }]:[]
                ],
                [
                  ...(pallet.maxQtyPerReunion > 0) ? [{
                    label: "Max Qty Per Ship Window:", val: pallet.maxQtyPerReunion
                  }]:[]
                ]
              ]
            : [
                [
                  { label: "Pallet Location:", val: palletLocationVal(pallet.PalletLocNbr, pallet.PalletLocSection) },
                  { label: "UPC:", val: pallet.upc },
                  { label: "Member Cost:", val: memberCost },
                  { label: "Dating:", val: pallet.terms + " Days" },
                ],
                [
                  { label: "Pallet SKU:", val: pallet.palletNbr },
                  {
                    label: "Retail Fine Line:",
                    val:
                      pallet.deptId +
                      pallet.classId +
                      " - " +
                      pallet.subClassId,
                  },
                  { label: "Pack:", val: pallet.pack },
                ],
                [
                  { label: "Model Number:", val: pallet.model },
                  { label: "Savings:", val: pallet.specWhseCost, className:'First_level_details red_color' },
                  ...(pallet.maxQtyItemRestriction > 0) ? [{
                    label: "Max Qty:", val: pallet.maxQtyItemRestriction
                  }]:[]
                ],
                [
                  ...(pallet.maxQtyPerReunion > 0) ? [{
                    label: "Max Qty Per Ship Window:", val: pallet.maxQtyPerReunion
                  }]:[]
                ]
              ]),
        ]
      : [
          ...(access !== 1 || (access === 1 && CatMbr)
            ? [
                { label: "Pallet Location:", val: palletLocationVal(pallet.PalletLocNbr, pallet.PalletLocSection) },
                { label: "Pallet SKU:", val: pallet.palletNbr },
                { label: "Model Number:", val: pallet.model },
                { label: "UPC:", val: pallet.upc },
                {
                  label: "Retail Fine Line:",
                  val:
                    pallet.deptId + pallet.classId + " - " + pallet.subClassId,
                },
                { label: "Sugg. Retail:", val:srcost},
                { label: "EVT Cost:", val: marketCost, className:'First_level_details red_color' },
                { label: "Member Cost:", val: memberCost },
                {
                  label: "Savings:",
                  val: utilities.isEmptyOrNullString(pallet.savings)
                    ? "-"
                    : utilities.PercentageSavings(pallet.savings * 100),
                  className:'First_level_details red_color'
                },
                { label: "Dating:", val: pallet.terms + " Days" },
                { label: "Pack:", val: pallet.pack },
                ...(pallet.maxQtyItemRestriction > 0) ? [{
                  label: "Max Qty:", val: pallet.maxQtyItemRestriction
                }]:[],
                ...(pallet.maxQtyPerReunion > 0) ? [{
                  label: "Max Qty Per Ship Window:", val: pallet.maxQtyPerReunion
                }]:[]
              ]
            : [
                { label: "Pallet Location:", val: palletLocationVal(pallet.PalletLocNbr, pallet.PalletLocSection) },
                { label: "Pallet SKU:", val: pallet.palletNbr },
                { label: "Model Number:", val: pallet.model },
                { label: "UPC:", val: pallet.upc },
                {
                  label: "Retail Fine Line:",
                  val:
                    pallet.deptId + pallet.classId + " - " + pallet.subClassId,
                },
                { label: "Pack:", val: pallet.pack },
                ...(pallet.maxQtyItemRestriction > 0) ? [{
                  label: "Max Qty:", val: pallet.maxQtyItemRestriction
                }]:[],
                ...(pallet.maxQtyPerReunion > 0) ? [{
                  label: "Max Qty Per Ship Window:", val: pallet.maxQtyPerReunion
                }]:[]
              ]),
        ];
  };

  handleExport = () => {
    const payload = this.getPalletListingPayload(true);
    utilities.showToast("Exporting to Excel...");
    restUtils.postData(PALLET_LISTING, payload).then((response) => {
      if (
        !response?.data?.palletLists ||
        response.data.palletLists.length === 0
      ) {
        utilities.showToast("No pallets available.", true);
        return;
      }
      let  preference =
        this.props.getprefer && this.props.getprefer.customerView
          ? this.props.getprefer.customerView
          : "c1";
      const itemsList = response?.data?.palletLists?.map((product) => {
        return {
          "Pallet Location": palletLocationVal(product.PalletLocNbr, product.PalletLocSection),
          "Pallet SKU": utilities.emptyForNull(product.palletNbr),
          Model: utilities.emptyForNull(product.model),
          Pack: utilities.emptyForNull(product.pack),
          UPC: utilities.emptyForNull(product.upc),
          "Retail Fine Line": utilities.emptyForNull(
            product.deptId + product.classId + " - " + product.subClassId
          ),
          "Member COST": utilities.getCostBasedOnnatvalue(
            this.props.memberViewType,
            product.mbrCost,
            preference
          ),
          "EVT Cost": utilities.getCostBasedOnnatvalue(
            this.props.memberViewType,
            product.specWhseCost,
            preference
          ),
          Savings: utilities.isEmptyOrNullString(product.savings)
          ? ""
          : utilities.PercentageSavings(product.savings * 100),
          Dating: utilities.emptyForNull(product.terms),
          "Ship Week 1": product.shipDate1 && product.shipDate1 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate1
          ) : '',
          "Ship Week 2": product.shipDate2  && product.shipDate2 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate2
          ) : '',
          "Ship Week 3": product.shipDate3  && product.shipDate3 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate3
          ) : '',
          "Ship Week 4": product.shipDate4  && product.shipDate4 !== 'Invalid date' ? utilities.getDateFromTimeStamp(
            product.shipDate4
          ) : '',
          ...(utilities.isAliasSkuRequired(this.props.multiStoreDetails) ?{ "Alternate SKU#": utilities.emptyForNull(product.aliasSku)}: {})
        };
      });
      utilities.exportToCsv(itemsList, this.state.shoppingAreaName);
    });
  };

  getPalletParams = (pallet, index) => {
    let palletParams = { pallet };
    const fieldSet = this.getFieldSet(pallet, index);
    palletParams = { ...palletParams, fieldSet };
    return palletParams;
  };

  getPalletListing = () => {
    const cartParams = {
      addToCart: this.addToCart,
    };
    const imgStyle =
      this.state.imgTextToDisplay === "Hide Images"
        ? "displayBlock"
        : "displayNone";
    const {
      isGridView,
      palletData,
      eventId,
      relayNbr,
      relayYear,
      vendorId,
      vendorName,
      shoppingAreaId,
      shoppingAreaMetaId,
      shoppingAreaName,
      fromReunionHome,
      isLoading
    } = this.state;
    const itemListParams = this.getItemListParams();
    if (!this.state.isLoading) {
      if (Array.isArray(palletData) && !_.isEmpty(palletData)) {
        return (
          <>
            <span className='disclaimer-span'>*Expand for Component</span>
            {shoppingAreaMetaId && parseInt(shoppingAreaMetaId)===2 && 
             <p className='disclaimer-span reunion palletlist'>Discount City Pallets and Displayers should be ordered as a quantity of 1 to receive 1 pallet.</p>
            }
              {this.state.shoppingAreaMetaId && parseInt(this.state.shoppingAreaMetaId)===3 && 
            <p className='disclaimer-span reunion impalleylist'>Impulse Alley Clip Strips should be ordered as a quantity of 1 to receive 1 entire Clip Strip and it's associated quantity.</p>
            }
            <div
              className={
                isGridView ? "ui stackable column grid product-grid" : ""
              }
            >
              {palletData.map((product, index) => {
                const itemParams = this.getItemParams(product, index);
                return (
                  <>
                    {!isGridView && window.innerWidth >= 1024 ? (
                      <PalletListView
                        flowType={this.state.flowType}
                        eventId={eventId}
                        promoName={this?.props?.match?.params?.promoName}
                        shoppingAreaId={shoppingAreaId}
                        shoppingAreaMetaId={shoppingAreaMetaId}
                        shoppingAreaName={shoppingAreaName}
                        allItems={this.state.allItems}
                        vendorName={vendorName}
                        vendorId={vendorId}
                        relayNbr={relayNbr}
                        relayYear={relayYear}
                        cartParams={cartParams}
                        itemParams={itemParams}
                        imgStyle={imgStyle}
                        product={product}
                        itemListParams={itemListParams}
                        setBackButtonPath={this.setScrollPosition}
                        fromReunionHome={fromReunionHome}
                        quantities={this.state.quantities[index]}
                        indexCheck ={index}
                        quantitiesMs={this.state.quantities}
                        closeMultistoreModal={this.closeMultistoreModal}
                      />
                    ) : (
                        <PalletGridView
                        promoName={this?.props?.match?.params?.promoName}
                          flowType={this.state.flowType}
                          getprefer={this.props.getprefer}
                          allItems={this.state.allItems}
                          eventId={eventId}
                          memberViewType={this.props.memberViewType}
                          shoppingAreaId={shoppingAreaId}
                          shoppingAreaMetaId={shoppingAreaMetaId}
                          shoppingAreaName={shoppingAreaName}
                          vendorName={vendorName}
                          vendorId={vendorId}
                          relayNbr={relayNbr}
                          relayYear={relayYear}
                          cartParams={cartParams}
                          itemParams={itemParams}
                          imgStyle={imgStyle}
                          product={product}
                          itemListParams={itemListParams}
                          setBackButtonPath={this.setScrollPosition}
                          fromReunionHome={fromReunionHome}
                          quantities={this.state.quantities[index]}
                          indexCheck ={index}
                          quantitiesMs={this.state.quantities}
                          closeMultistoreModal={this.closeMultistoreModal}
                          
                        />
                      )}
                  </>
                );
              })}
            </div>
          </>
        );
      } else {
        return (
          <div className="noProdctsDisplay">
            {_.isEmpty(palletData) && !isLoading && (<h1>No Pallets to Display!</h1>)}            
          </div>
        );
      }
    }
  };

  getItemListParams = () => {
    const { flowType } = this.state;
    return {
      handleQtyChange: this.handleQtyChange,
      isAddToCartRequired: true,
      isScanListing: false,
      isSeqRequired: isSeqRequried(flowType),
    };
  };

  setBackButtonPath = (newAppliedFilters) => {
    const appliedFilters = newAppliedFilters
      ? newAppliedFilters
      : this.state.appliedFilters;
    let pathName = "";
    pathName = utilities.isEmptyOrNullString(this.page)
      ? "Page=1"
      : "Page=" + this.page;
    pathName = pathName + "&ResultsPerPage=" + this.pageSize;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + "&SortParam=" + this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + "&SortType=" + this.sortDirection;
      } else {
        pathName = pathName + "&SortType=asc";
      }
    }
    pathName = pathName + "&scrollPosition=" + this.scrollPosition;
    if (!utilities.isEmptyOrNullString(this.state.searchValue)) {
      pathName =
        pathName +
        "&SearchBy=" +
        this.searchby +
        "&SearchValue=" +
        this.state.searchValue;
    }
    pathName =
      this.scrollPosition > 0
        ? pathName + "&scrollPosition=" + this.scrollPosition
        : pathName;
    if (!_.isEmpty(this.filterList)) {
      pathName =
        pathName +
        "&filterString=" +
        utilities.getQueryStrForFilterString(appliedFilters);
    }
    if (pathName !== "") {
      let pushUrl = this.pathname + "?" + pathName;
      if(!this.props.fromScanning) {
        window.history.replaceState(window.history.state, "", pushUrl);
        }
    }
  };
  getSortByOptions = () => {
   return [
      {
        props: {
          value: "pallet_nbr",
          id: "pallet_nbr",
          className: "SelectOption",
        },
        text: "Pallet SKU",
      },
      {
        props: { value: "upc", id: "upc", className: "SelectOption" },
        text: "UPC",
      },
      {
        props: { value: "model", id: "modelId", className: "SelectOption" },
        text: "Model#",
      },
      {
        props: { value: "dating", id: "dating", className: "SelectOption" },
        text: "Dating",
      },
      {
        props: {
          value: "member_cost",
          id: "member_cost",
          className: "SelectOption",
        },
        text: "Member Cost",
      },
      {
        props: { value: "savings", id: "savings", className: "SelectOption" },
        text: "Savings",
      },
      {
        props: {
          value: "market_cost",
          id: "market_cost",
          className: "SelectOption",
        },
        text: "EVT Cost",
      },
      {
        props: {
          value: "pallet_location",
          id: "pallet_location",
          className: "SelectOption",
        },
        text: "Pallet Location",
      },
      {
        props: {
          value: "retail_fineline",
          id: "retail_fineline",
          className: "SelectOption",
        },
        text: "Retail Fineline",
      }
    ];
  };

  showDealDetails() {
    this.setState({
      dealDetails: true,
    });
  }

  DialogClose = () => this.setState({ dealDetails: false });

  changeSortParam = (e) => {
    this.sortParam = e.target.value;
    this.page = 1;
    this.fetchPallets();
    this.setBackButtonPath();
  };

  changeSortDirection = (e) => {
    this.sortDirection = e.target.value;
    this.fetchPallets();
    this.page = 1;
    this.setBackButtonPath();
  };
  changeSearchValue = (e) => {
    this.setState({ searchValue: e.target.value });
  };
  changeSearchBy = (e) => {
    const option = this.searchByOptions.find(
      (option) => option?.props?.value === e.target.value
    );
    const pattern = option?.pattern;
    this.setState({ searchPattern: pattern, searchValue: "" });
    this.searchby = e.target.value;
  };
  searchItems = () => {
    this.page = 1;
    this.fetchPallets();
    this.setBackButtonPath();
  };

  getCurrentPath = (allItems) => {
    if (allItems) {
      return { link: this?.props?.location?.pathname, text: "All Items" };
    }
  };
  render() {
    const {
      isLoading,
      isNoProducts,
      shoppingAreaId,
      shoppingAreaMetaId,
      shoppingAreaName,
      promoName,
      eventId,
      relayNbr,
      relayYear,
      flowType,
      vendorId,
      vendorName,
      filters,
      fromReunionHome,
      fromVendorPage,
      orderDeadline,
      allItems,
      searchValue,
      searchPattern,
      promoNbr,
      palletData,
      eventEnableAfter,
      eventEnableBefore,
      eventMessage,
      paginationParams
    } = this.state;
    const path = getBreadcrumbLinks(
      fromReunionHome,
      flowType,
      eventId,
      vendorName, // PromoName for flow type 2
      vendorId,
      shoppingAreaName,
      shoppingAreaId,
      shoppingAreaMetaId,
      relayYear,
      relayNbr,   // assortmentId for flow type 2
      '',         // modelNbr for flow type 2
      promoName,
      '',
      promoNbr,
      allItems,
      vendorId?"PalletListing":"AllPallets",
    )
    if (palletData || (eventEnableAfter === false || eventEnableBefore === false )) {
      return (
        <>
          {(eventEnableAfter === false || eventEnableBefore === false ) ? 
            <UnderConstruction eventMessage={eventMessage} shoppingAreaName={shoppingAreaName}/>
            :
            <div className="page-wrap">
              {!this.props?.fromScanning ? 
              <>
              <div className="page-header">
                {shoppingAreaName} - {eventId} - {promoName}
              </div>
              <BreadcrumbComp path={path} />
              </>:<h2>{shoppingAreaName}</h2>}
              <br />
              {fromVendorPage && this.getVendorDetails(vendorName, orderDeadline , vendorId)}
              {!this.hideFilter &&(
              <OptionsSection 
                isSortByRequired={isSortRequired(flowType)}
                sortByList={this.getSortByOptions()}
                changeSortParam={this.changeSortParam}
                sortOrder={this.sortDirection}
                sortParam={this.sortParam}
                isSearchRequired={isSearchRequired(flowType)}
                searchByList={this.searchByOptions}
                isExportRequired={this.props.isExportRequired}
                changeSortDirection={this.changeSortDirection}
                isShowByRequired={isShowByRequired(flowType)}
                searchItems={this.searchItems}
                pageSize={this.pageSize}
                changePageSize={this.changePageSize}
                changeSearchBy={this.changeSearchBy}
                changeSearchValue={this.changeSearchValue}
                isGridViewIconRequired={true}
                handleViewChange={this.handleViewChange}
                searchPattern={searchPattern}
                searchValue={searchValue}
                searchParam={this.searchby}
              />)}
              <div className="displayFlex allOrdersTable">
                {isLoading && <Loader active />}
                {!this.hideFilter && (
                <Filter
                  filters={filters}
                  isNoProducts={isNoProducts}
                  appliedFilters={this.state.appliedFilters}
                  defaultChecked={this.defaultChecked}
                  clearFilters={false}
                  filterCallback={this.filterCallback}
                />)}
                <div id="grid_width" className="full-width pl_data">
                  {this.getListingHeader()}
                  {isNoProducts ? (
                    <div className="no_prdcts_display">
                      <h1>No Pallets to Display!</h1>
                    </div>
                  ) : (
                    <>{this.getPalletListing()}</>
                  )}
                  {paginationParams.totalPages > 0 && (
                   <span className="pagination_top">
                     {getPagination(
                       0,
                       paginationParams.currentPage,
                       paginationParams.totalPages,
                       this.onPageChange,
                       null,
                       1
                     )}
                   </span>
	                 )}
                </div>
              </div>
            </div>
          }
        </>
      );
    } else {
      return (
        <>
          {this.state.isLoading && <Loader active />}
        </>
      )
    }
  }
}

const mapStateToProps = (state) => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId: state.SessionReducer.storeId,
    getprefer: state.preference.listData,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    memberViewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    rdc: state.SessionReducer.rdc,
    carts: state.cart.orders.allCarts,
    userId: state.SessionReducer.UserId,
    userName: state.SessionReducer.address.userName,
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
    currentCartId: state.cart.orders.currentCart.cartId, 
    qtyUpdate: state.cart.orders?.updateQty
  };
};

export default connect(mapStateToProps, { viewAllCarts, updateQty })(PalletListing);
