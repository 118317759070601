export const breadcrumbLinks = [{ link: '/home', text: 'Home' }];
export const colDefs = [
    {
      header: 'Item #',
      key: 'lineItemId'
    },
    {
      header: 'Item Description',
      key: 'itemDescription'
    },
    {
      header: 'PO #',
      key: 'koctOrderNbr'
    },
    {
      header: 'Order #',
      key: 'mfOrderNbr'
    },
    {
      header: 'Pack',
      key: 'pack'
    },
    {
      header: 'RDC',
      key: 'mfRdc'
    },
    {
      header: 'Qty On Order',
      key: 'lineItemQty'
    },
    {
      header: 'Cost',
      key: 'itemUnitPrice'
    },
    {
      header: 'Ext. Cost',
      key: 'lineTotals'
    },   
    {
      heading: 'Select All',
      key: 'action:Remove'
    }
  ];
  
  export const sortOptions = [
    // {
    //   text: 'Order Create Date',
    //   value: 'orderDate'
    // },
    {
      text: 'Item#',
      value: 'lineItemId'
    },
    {
      text: 'PO #',
      value: 'koctOrderNbr'
    },
    // {
    //   text: 'Order Type',
    //   value: 'orderType'
    // },
    // {
    //   text: 'Expected Release Week',
    //   value: 'releaseDate'
    // },
    // {
    //   text: 'Ship Later Age',
    //   value: 'shiplaterage'
    // },
    // {
    //   text: 'Cost Change Indicator',
    //   value: 'costchangeindicator'
    // },
    // {
    //   text: 'Status',
    //   value: 'Order Status'
    // },
  ];
  
  
  export const searchOptions = [
    {
      text: 'Item #',
      value: 'lineItemId'
    },
    {
      text: 'PO #',
      value: 'koctOrderNbr'
    }
  ];
  
  export const orderOptions = [
    {
      text: 'Ascending',
      value: 'asc'
    },
    {
      text: 'Descending',
      value: 'desc'
    }
  ];