import React from 'react';
import restUtils from '../../utils/restUtils';
import { Loader, Responsive,Pagination } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import history from '../../history';
import "./AllOrders.css";
import _ from 'lodash';
import utilities from '../../utils/utilities';
import ExcelExport from '../common/ExcelExport';
import SAM_Icon from './SAM_Icon.gif';
import renderImages from '../../views/common/RenderImages';
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { Link } from 'react-router-dom';
import search_img from '../images/Search.svg';
import { ORDER_STATUS, UPS_URL, FEDEX_URL } from '../../../src/urlConstants';
import Filter from '../common/Filter';
import {getRaCategories} from '../RetailAssortment/actions';
import {PAGE_LIMIT} from '../common/constants';

class AllOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allOrders: [],
      filters: [],
      loading: false,
      serverError: false,
      clearFilters: false,
      appliedFilters: {},
      showFilters:(this.props.getprefer && this.props.getprefer.filterView) 
                    ? this.props.getprefer.filterView === 'E' ? true : false : window.innerWidth > 768 ? true :false,
      isNoProducts: false,
      noOfOrders: 0,
      scrollPostion : 0,
      noOfOrders: 0,
      firstItem: 0,
      lastItem: 0,
      currentPage: 0,
      totalPages: 0,
      filterForRequest: {}
    }
    this.filterString = {};
    this.searchParam = 'itemNbr';
    this.searchNumber = '';
    this.sortParam = 'orderDate';
    this.sortDirection = 'desc';
    this.pathname = '';
    this.pageSize = '50';
    this.scrollPosition = 0;
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage ? 
    this.props.getprefer.resultPerPage : '50';
    this.pathname = this.props.location && this.props.location.pathname;
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.getOrders();
    }
  }

  changeSearchParam = e => {
    this.scrollPosition = window.pageYOffset;
    this.searchParam = e.currentTarget.value;
  };

  searchItems = () => {
    this.scrollPosition = window.pageYOffset;
    this.searchNumber = document.getElementById('search_order_status').value;
    this.page = 1;
    this.getOrders();
  };

  handleEnterKey = e => {
    this.scrollPosition = window.pageYOffset;
    if (e.keyCode === 13) {
      this.searchItems();
      return;
    }
  };

  changeSortParam = e => {
    window.scrollTo(0, 0);
    this.scrollPosition = window.pageYOffset;
    this.page =1;
    this.sortParam = e.currentTarget.value;
    this.getOrders();
  };

  changeSortDirection = e => {
    this.page = 1;
    this.sortDirection = e.currentTarget.value;
    window.scrollTo(0, 0);
    this.scrollPosition = window.pageYOffset;
    this.getOrders();
  };

  onOrderpagesize=e=>{
    this.pageSize = e.target.value;
    this.page = 1;
    window.scrollTo(0, 0);
    this.getOrders();
  }
  onPageChange = e => {
    let queryPage = e.currentTarget.text;
    if (e.currentTarget.text === 'Next') {
      queryPage =
        this.state.currentPage < this.state.totalPages
          ? this.state.currentPage + 1
          : this.state.currentPage;
    }
    if (e.currentTarget.text === 'Prev') {
      queryPage = this.state.currentPage === 1 ? 1 : this.state.currentPage - 1;
    }
    if (e.currentTarget.text === '»') {
      queryPage = this.state.totalPages;
    }
    if (e.currentTarget.text === '«') {
      queryPage = 1;
    }
    this.page = queryPage;
    //this.scrollPosition = window.pageYOffset;
    window.scrollTo(0, 0);
    this.getOrders();
  };

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');
    this.page = getParams[0].split('=')[1];
    this.pageSize =  getParams[1].split('=')[1];
    let searchParam = queryParam[1].includes('searchBy') ? queryParam[1].split('searchBy=')[1] : '';
    if(searchParam !== ''){
      let search = searchParam.split('=');
      this.searchParam = search[0];
      this.searchNumber = search[1].split('&')[0];
    }
    document.getElementById('search_order_status').value = this.searchNumber;
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : '';    
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
    this.filterString = queryParam[1].includes('filterString') ? queryParam[1].split('filterString=')[1] : '';
    document.getElementById('order_sts_dropdown').value = this.searchParam;
    let appliedFilters = utilities.getFilterArray(this.filterString);
    let filterForRequest = utilities.getFilterForRequest(appliedFilters);
    this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
    this.setState({
      appliedFilters : appliedFilters,
      filterForRequest: filterForRequest
    },() => {
      this.getOrders();
    });
  }
  
  setUrlParamsPath = () => {
    let pathName = ''; 
    pathName = utilities.isEmptyOrNullString(this.page) ? 'Page=1' : 'Page='+this.page;
    pathName = pathName + '&ResultsPerPage='+this.pageSize;
    this.scrollPosition = window.pageYOffset;
    if (this.searchNumber) {
      pathName = pathName + '&searchBy='+this.searchParam+'='+this.searchNumber;
    }
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam='+this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType='+this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }    
    pathName = pathName + '&scrollPosition='+this.scrollPosition;
    if (!_.isEmpty(this.filterString)) {
      let obj = utilities.getQueryStrFromJson(this.filterString);
      pathName = pathName + '&filterString='+ utilities.getQueryStrForFilterString(obj);
    }    
    let pushUrl = this.pathname +'?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
    return pushUrl
  }

  setBackButtonPath = () => {
    const path = this.setUrlParamsPath();
  }

  redirectOrderStatusItem = (order) => {
    const pushUrl = this.setUrlParamsPath();
    history.push({
      pathname: '/OrderItemLevelDetails',
      state: { orderDetails: order, pushUrl: pushUrl}
    })
  }

  getOrders = () => {
    let searchArr = {};
    if (this.searchNumber) {
      searchArr = {
        name: this.searchParam,
        valueList: this.searchNumber.split(',')
      };
    }
    const sortArr = { sortParam: this.sortParam, sortType: this.sortDirection };  
    let queryJson = [];  
    let appliedFilters = this.state.filterForRequest;
    if(!_.isEmpty(appliedFilters)){      
      for (let property in appliedFilters) {
        if (appliedFilters.hasOwnProperty(property)) {
          let filter = {};
          filter.name = property;
          if(property === 'Status'){
            let valueList = [];
            let values = appliedFilters[property];
            for(let key in values){
              valueList.push(values[key]);
            }
            filter.valueList = valueList;
          }          
          else filter.valueList = appliedFilters[property];
          queryJson.push(filter);
        }
      }
    }
    let requestPayLoad = { "storeId": this.props.storeId, "userId": this.props.userId, "rdcNum": this.props.rdcNum, searchBy: searchArr, sortBy: sortArr, 
                            ...{ filterBy: queryJson },"pageNumber": this.page,aliasSKURetailerGrpID : this.props.aliasSKURetailerGrpID,
                            "orderGroupsPerPage": this.pageSize };
    const url = `${ORDER_STATUS}`
    this.setState({ loading: true });
    restUtils
      .postData(url, requestPayLoad)
      .then(response => {
        let filters = response.data.filters.map((filter) => {
          if(filter.name === 'Status')
          {
           let valueList = [];
           for(let key in filter.valueList){
            valueList.push(filter.valueList[key]);
           }
           filter.valueList = valueList;
          }
          return filter;
        });

        this.setState({ allOrders: response.data.orderGroupList, noOfOrders: response.data.totalItems, filters: filters, loading: false, serverError: false,
          firstItem: response.data.firstItem, lastItem: response.data.lastItem,
          currentPage: response.data.pageNumber, totalPages: response.data.pageCount });

        if (!this.state.showFilters) {
          this.setState({
            showFilters: true
          }, () => {
            for (let key in this.state.appliedFilters) {
              document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
            }
            this.setState({
              showFilters: false
            });
          });
        } else {
          for (let key in this.state.appliedFilters) {
            document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
          }
        }
      
       window.scrollTo(0,this.scrollPosition);
       this.setUrlParamsPath();
      })
      .catch(error => {
        console.log(error);
        this.setState({ loading: false, serverError: true });
      });      
  }

  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };

  showLess = (changeIndex, samNumber) => {
    let allOrders = this.state.allOrders;
    this.state.allOrders.forEach((orders, index) => {      
       if(orders.samNumber === samNumber)
          allOrders[index].orderList[changeIndex].showLess = true ;        
    });
    this.setState({ allOrders});
  }

  showMore = (changeIndex, samNumber) => {
    let allOrders = this.state.allOrders;
    this.state.allOrders.forEach((orders, index) => {      
       if(orders.samNumber === samNumber)
          allOrders[index].orderList[changeIndex].showLess = false ;        
    });
    this.setState({ allOrders});
  }

  openTrackingSite = (trackingNumber) => {    
    window.open(trackingNumber.startsWith('1Z') ? UPS_URL + `&InquiryNumber1=${trackingNumber}` : FEDEX_URL.replace('trackingNumber', trackingNumber), '_blank');
  }

  renderTrackingDetails =(order) => {
    const uniqTrackingNum = _.uniq(order.retailerTrackingNumber.split(','))
    if(uniqTrackingNum.length > 1 ) {
      return <span className='order_details'><Link onClick={this.setUrlParamsPath} to={{ pathname: "/OrderItemLevelDetails", state: { orderDetails: order } }}>Click here</Link></span>
    } else {
      return <span className='order_details'><a href="#"  onClick={() => this.openTrackingSite(uniqTrackingNum[0])}>{uniqTrackingNum[0]}</a></span>
    }
  }

  filterCallback = (isAdd, filterKey, filterValue) => {
    let filterForRequest = this.state.filterForRequest;
    this.scrollPosition = window.pageYOffset;
    let appliedFilters = this.state.appliedFilters;
    this.page = 1;
    let keyExists = appliedFilters.hasOwnProperty(filterKey);
    if (!keyExists && isAdd) {
      appliedFilters = { ...appliedFilters, [filterKey]: filterValue };
      filterForRequest = { ...filterForRequest , [filterKey] : [filterValue]};
    } else if (keyExists) {
      if(isAdd)
      { 
        filterForRequest[filterKey].push(filterValue);
      }
      else{
        if(filterForRequest[filterKey])
        {
        let filterValues = filterForRequest[filterKey].filter(item=>item!==filterValue);
        appliedFilters[filterKey] = appliedFilters[filterKey].replace(filterValue,"");
        if(filterValues.length === 0)
        {
         delete filterForRequest[filterKey] ;
        }
        else{
          filterForRequest[filterKey] = filterValues;
        }
      }
      }
      let value = appliedFilters[filterKey].split(',').filter(item=>item!=="");
      let index = value.indexOf(filterValue);
      if (index > -1) {
        value.splice(index, 1);
      } else {
        if(isAdd)
        value.push(filterValue);
      }
      if (!value.length && keyExists) delete appliedFilters[filterKey];
      else {
        appliedFilters[filterKey] = value.join(',');
    }
    }
    this.filterString = utilities.getQueryJsonAssortment(appliedFilters);
    this.setState({
      appliedFilters,
      clearFilters: false,
      filterForRequest
    },() => {
      this.getOrders();
    });
  };

  getRaCategories = (order) => {
    let assortment = {
      RAMarketEndDate : order.assortment_RAMarketEndDate,
      department : {
        name : order.assortment_department && order.assortment_department.trim()
      },
      number : order.assortment_number,
      modelNbr : order.memberOrderRef,
      categoryName : order.assortment_category && order.assortment_category.trim(),
      description : order.assortment_description,
      rebateAmount : order.assortment_rebateamount,
      billingDateTerms : order.assortment_billingDateTerms,
      savings : order.assortment_savings,
      delivery : order.assortment_delivery,
      regCost : order.assortment_regCost,
      orderedModelNbr : order.assortment_orderedModelNbr,
      orderedDate : order.assortment_orderedDate
    }
    this.props.getRaCategories(assortment);
  }

  render() {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    return (
      <div className='page-wrap'>
<div cassName='page-header editOrderHeaderTop'>Order Status </div>
        {this.props.multiStoreDetails.access === 1 ?
        <h4>You don't have the permission to view this page.Please contact the True Value Administrator.</h4>
        :
        <>
        {this.state.loading &&
          <div>
            <Loader active />
          </div>
        }
        <div className="editOrderHeaderTwo">
        <div className='sortFields' style={{width:'100%'}}>
          <div id='input_search'>
            <span>
              {window.innerWidth < 768 ? 'Search' : 'Order Search'}
            </span>
            <select
              className='sort-criteria combo'
              onChange={this.changeSearchParam}
              id='order_sts_dropdown'>
              <option value='itemNbr'>Item #</option>
              {!utilities.isEmptyOrNullString(this.props.aliasSKURetailerGrpID) && ( 
              <option value={'alternateSKU'}>
              Alternate SKU#
              </option>)}
              {/* <option value='model'>Model #</option>
              <option value='upcNbr'>UPC #</option> */}
              <option value='invoiceNbr'>Invoice #</option>
              <option value='poNbr'>PO #</option>
              <option value='eventNbr'>Event #</option>
            </select>
            <input
              id='search_order_status'
              onKeyDown={this.handleEnterKey}
              type='text'
            />
            {renderImages.getImage({
              src: search_img,
              className: 'search_icon_address cursor_p',
              onClick: this.searchItems
            })}
          </div>
        <div className='sort_by mobile_sort'>
          <span className='addressbookSize'>
            <span >Show</span>
              <select
                value={this.pageSize}
                className='select-range'
                onChange={this.onOrderpagesize}
              >
                <option className='SelectOption' value={50}>
                  50
                </option>
                <option className='SelectOption' value={100}>
                  100
                </option>
              </select>
          </span>
          <span className='filter-label'>Sort By</span>
          <select className='sort-criteria combo' onChange={this.changeSortParam} id='pl_dropdown' style ={{width : "12.7rem"}} value={this.sortParam}>
          <option value='orderDate'>Order Create Date</option>
            <option value='invoiceNbr'>Invoice #</option>
            <option value='poNbr'>PO#</option>
            <option value='orderType'>Order Type</option>
            <option value='expectedReleaseWeek'>Expected Release Week</option>
            <option value='status'>Order Status</option>
            <option value='SAM_NBR'>Grouping Original Order</option>
          </select>
          <select className='sort-order combo' onChange={this.changeSortDirection} defaultValue='desc' value={this.sortDirection}>
            <option value='asc'>Ascending</option>
            <option value='desc'>Descending</option>
          </select>
        </div>
        </div>
        </div>
        <br />
        <div className={window.innerWidth > 1024 ? "displayFlex allOrdersTable" : "allOrderstablemobile"}>
          <div className='filterorderstatus' style = {{flex: this.state.showFilters? '0 0 20%': '0 0 0'}}>
            <Filter
              clearFilters={this.state.clearFilters}
              filtersApplied={!_.isEmpty(this.state.appliedFilters)}
              appliedFilters={this.state.appliedFilters}
              toggleShowFilter={this.toggleShowFilter}
              showFilters={this.state.showFilters}
              filters={this.state.filters}
              callback={this.filterCallback}
              isNoProducts={this.state.isNoProducts}
              screenName={'orderstatus'}
              loading={this.state.loading}
            />
          </div>
          <Responsive minWidth='1025'>
            <div className="eighteen wide column" id='allOrdersTable'>
            <div style={{position:'sticky', top:'10.9rem', background:'#f2f2f2'}}>
            <div className="order-pagination">
              <span className='OrderTotalitem'>
                {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.noOfOrders} orders
              </span>
              <span className='paginationAllorders'>        
              {this.state.totalPages > 0 && (         
                <Pagination
                  className='pagination'
                  boundaryRange={0}
                  activePage={this.state.currentPage}
                  totalPages={this.state.totalPages}
                  onPageChange={this.onPageChange}
                  ellipsisItem={null}
                  siblingRange={1}
                  firstItem={this.state.currentPage === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                  lastItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                  prevItem={this.state.currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                  nextItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                />)}
              </span>
              <span className='order_count'>Total {this.state.noOfOrders} Orders
              <ExcelExport className='excel_left excelHide' 
                fileName={'All Orders'} 
                orderResults={this.state.allOrders} 
                searchNumber = {this.searchNumber} 
                searchParam = {this.searchParam} 
                viewType={this.props.viewType}
                getprefer={
                  this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                sortParam = {this.sortParam}
	              sortDirection= {this.sortDirection} 
                appliedFilters = {this.state.appliedFilters} 
                storeId = {this.props.storeId}
                userId = {this.props.userId} 
                rdcNum = {this.props.rdcNum}
                aliasSKURetailerGrpID = {this.props.aliasSKURetailerGrpID}
                totalPages = {parseInt(this.pageSize*this.state.totalPages/PAGE_LIMIT) + 1} pageSize = {PAGE_LIMIT}/>
              </span>
            </div>
            </div>
              <table className="ui celled table all-wh-table stickyTableEditOrder" id='font-size-orders'>
                <thead>
                  <tr>
                    <th className='allOdrHeader' id='no_border' ></th>
                    <th className='allOdrHeader' id='no_border'  >Order Created Date</th>
                    <th className='allOdrHeader' id='no_border' >Invoice Date</th>
                    <th className='allOdrHeader' id='no_border' >Invoice#</th>
                    <th className='allOdrHeader' id='no_border' >PO#</th>
                    <th className='allOdrHeader' id='no_border' >Event#</th>
                    <th className='allOdrHeader' id='no_border' >Order Type</th>
                    {/* <th className='allOdrHeader' id='no_border' >Description and Category</th> */}
                    <th className='allOdrHeader' id='no_border' >Item Count</th>
                    <th className='allOdrHeader' id='no_border' >RDC</th>
                    <th className='allOdrHeader' id='no_border' >Ship To</th>
                    <th className='allOdrHeader' id='no_border' >Expected Release Week</th>
                    { this.props.viewType !== MEMBER_VIEW && (getPrefer === 'c3' || getPrefer ==='c4')  ?  "" :
                    <th className='allOdrHeader' id='no_border' >{utilities.getLabelsByType(this.props.viewType,"TOTAL_COST", getPrefer)}</th>
                    }
                    <th className='allOdrHeader' id='no_border' >Cancel Code</th>
                    <th className='allOdrHeader' id='no_border' >Status</th>
                  </tr>
                </thead>
                <tbody>
                  {( this.state.noOfOrders === 0 || this.state.serverError )
                  ? <>
                      <tr>
                          <td colSpan='11' className='t-center'>No orders to display!</td>
                      </tr>
                    </>
                  : 
                    this.state.allOrders.map((orders) => (
                      orders.orderList.map((order, index) => {
                        return (
                          <>
                            <tr>
                              <td className='t-center' id='field_width'>{index===0 && this.sortParam === 'SAM_NBR' && order.showSamIcon ? renderImages.getImage({ src: SAM_Icon }) : ''}</td>
                              <td className='t-left'   id='field_width'>{order.orderDateString ? utilities.hyphenForNull(order.orderDateString) : utilities.hyphenForNull(order.invoiceDateString) }</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.invoiceDateString)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.displayedInvoiceNumber)}</td>
                              <td className='t-center' id='field_width'>{utilities.naForNull(order.memberOrderRef)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForZero(order.eventNumber)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.orderType)}</td>
	                            {/*<td className='t-center' id='field_width'>{utilities.hyphenForNull(order.orderType) === 'ST'? 'STOCK': utilities.hyphenForNull(order.orderType)}</td>*/}
                              {/* <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.desc)}</td> */}
                              <td className='t-center' id='field_width'>{(order.orderStatus === 'closed' || order.orderStatus === 'being processed') ? '' : utilities.hyphenForNull(order.numberOfItems)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.rdcNumber)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.consumerName)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.shipDateString)}</td>
                              { this.props.viewType !== MEMBER_VIEW && (getPrefer === 'c3' || getPrefer ==='c4')  ?  "" :
                              <td className='t-center' id='field_width'>
                                {order.subCostTotal ? <MaskPrice
                                  field = 'Member Cost black'
                                  getprefer={getPrefer}
                                  viewType={this.props.viewType}
                                  fieldVal={order.subCostTotal}
                                /> : "-"}
                              </td>}
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.cancelReasonCode)}</td>
                              <td className='t-center' id='field_width'>{utilities.hyphenForNull(order.displayedStatus)}</td>
                            </tr>
                            <tr className={index===orders.orderList.length-1 ? 'wh-row' : ''}>
                              <td colSpan = '1'></td>
                              {/* <td colSpan='2' className='t-left'><i className='created_by'>Created by: {utilities.emptyForNull(order.createdBy)}</i></td> */}
                              <td colSpan='3' className='t-left' id='remove_border'>
                                **Tracking #: { order && this.renderTrackingDetails(order)}</td>
                                { this.props.viewType !== MEMBER_VIEW && (getPrefer === 'c3' || getPrefer ==='c4')  ?   
                                <td colSpan = '8' className= 't-left' id='remove_border'>
                                {order.assortment_description && order.orderType && utilities.hyphenForNull(order.orderType) === 'AV'?`Description: ${order.assortment_description? order.assortment_description.length > 50? order.assortment_description.substring(0,50) : order.assortment_description : ""}`:
                                ""}
                              </td> :
                              <td colSpan = '9' className= 't-left' id='remove_border'>
                                {order.assortment_description && order.orderType && utilities.hyphenForNull(order.orderType) === 'AV'?`Description: ${order.assortment_description? order.assortment_description.length > 50? order.assortment_description.substring(0,50) : order.assortment_description : ""}`:
                                ""}
                              </td>}
                              <td colSpan='1' className='checkoutPointer' id='remove_border'><p onClick={() => {this.redirectOrderStatusItem(order)}}>View Details</p></td>
                            </tr>
                          </>
                        )
                      })
                    ))
                  }
                </tbody>
              </table>
              <div className="order-pagination">
               <span className='OrderTotalitem'>
                 {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.noOfOrders} orders
               </span>
               <span className='paginationAllorders'>        
               {this.state.totalPages > 0 && (         
                 <Pagination
                   className='pagination'
	                   boundaryRange={0}
                   activePage={this.state.currentPage}
                   totalPages={this.state.totalPages}
                   onPageChange={this.onPageChange}
                   ellipsisItem={null}
                   siblingRange={1}
                   firstItem={this.state.currentPage === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                   lastItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                   prevItem={this.state.currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                   nextItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                 />)}
               </span>
            </div>
            </div>
          </Responsive>
          <Responsive maxWidth='1024'>
            <div className="portrait-pagination">
                <div className='OrderTotalitem'>
                  {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.noOfOrders} orders
                </div>
                <div className='paginationAllorders'>                
                {this.state.totalPages > 0 && ( <Pagination
                    className='pagination'
                    boundaryRange={0}
                    activePage={this.state.currentPage}
                    totalPages={this.state.totalPages}
                    onPageChange={this.onPageChange}
                    ellipsisItem={null}
                    siblingRange={1}
                    firstItem={this.state.currentPage === 1 ? { content: <span className='disabled'>«</span> } : { content: '«' }}
                    lastItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>»</span> } : { content: '»' }}
                    prevItem={this.state.currentPage === 1 ? { content: <span className='disabled'>Prev</span> } : { content: 'Prev' }}
                    nextItem={this.state.currentPage === this.state.totalPages ? { content: <span className='disabled'>Next</span> } : { content: 'Next' }}
                  />)}
                </div>
                <div className='order_count-portarit'>Total {this.state.noOfOrders} Orders
                <ExcelExport 
                  className='excel_left' 
                  fileName={'All Orders'} 
                  searchNumber = {this.searchNumber} 
                  viewType={this.props.viewType}
                  getprefer={
                    this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                  }
                  sortParam = {this.sortParam}
	                sortDirection= {this.sortDirection} 
                  appliedFilters = {this.state.appliedFilters} 
                  storeId = {this.props.storeId}
                  userId = {this.props.userId} 
                  rdcNum = {this.props.rdcNum} 
                  aliasSKURetailerGrpID = {this.props.aliasSKURetailerGrpID} 
                  totalPages = {parseInt(this.pageSize*this.state.totalPages/PAGE_LIMIT) + 1} pageSize ={PAGE_LIMIT}/>
                </div>
            </div>
            {this.state.allOrders.map((orders) => (
              orders.orderList.map((order_details, index) => {
               return <OrderCard 
                        order={order_details} 
                        loggedInUser={this.props.loggedInUser} 
                        index={index} 
                        samNumber={orders.samNumber}
                        getRaCategories={this.getRaCategories}
                        showLess={this.showLess}
                        showMore={this.showMore}
                        openTrackingSite={this.openTrackingSite}
                        renderTrackingDetails={this.renderTrackingDetails}
                        getPrefer={this.props.getprefer &&
                          this.props.getprefer.customerView
                          ? this.props.getprefer.customerView
                          : 'c1'}
                        viewType={this.props.viewType}/>
              })
            ))}
          </Responsive>          
        </div>
        {this.state.noOfOrders > 0 &&
        <div className='t-right'>
            <i className = 'addedBy'>
                **Harvard, Atlanta, Woodland and Central Ship are the preferred Ship to customer RDCs. Orders placed via these RDCs will have tracking number information available.
            </i>
        </div>}
        </>
      }
    </div>
    )
  }
}

const OrderCard = ({ order, loggedInUser, index , samNumber, getRaCategories, showLess, showMore, openTrackingSite, renderTrackingDetails, getPrefer, viewType }) => { 
  return (
    <div className='all-orders-card'>
      <div className='floatRight view-details-link'>
        <Link to={{ pathname: "/OrderItemLevelDetails", state: { orderDetails: order } }}>View Details</Link></div>
      <table id='order-card-table'>
        <tr>
          <td className='order-card-field padding hidden-sim' >SAM</td>
          <td>{renderImages.getImage({ src: SAM_Icon })}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Order Created Date</td>
          <td>{utilities.hyphenForNull(order.orderDateString)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Invoice Date</td>
          <td>{utilities.hyphenForNull(order.invoiceDateString)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Invoice #</td>
          <td>{utilities.hyphenForNull(order.displayedInvoiceNumber)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>PO #</td>
          <td>
          { order.orderType === 'AV' ? (
            <Link className='cart-vendor-span zero-padding'
                onClick = {() => getRaCategories(order)} 
                  to={{ pathname: '/assortment/' + order.assortment_number }}>
                {order.memberOrderRef}
              </Link> ) : 
              (order.memberOrderRef ? order.memberOrderRef : 'N/A')
          }
          </td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Event #</td>
          <td>{utilities.hyphenForZero(order.eventNumber)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Order Type</td>
          <td>{utilities.hyphenForNull(order.orderType)}</td>
        </tr>
        {order.showLess === false && <> <tr>
          <td className='order-card-field padding'>Description and Category</td>
          <td>{utilities.hyphenForNull(order.desc)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Item Count</td>
          <td>{(order.orderStatus === 'closed' || order.orderStatus === 'being processed') ? '' : utilities.hyphenForNull(order.numberOfItems)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>RDC</td>
          <td>{utilities.hyphenForNull(order.rdcNumber)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Ship To</td>
          <td>{utilities.hyphenForNull(order.consumerName)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Expected Release Week</td>
          <td>{utilities.hyphenForNull(order.shipDateString)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>{utilities.getLabelsByType(viewType,"TOTAL_COST", getPrefer)}</td>
          <td>{order.subCostTotal ? 
            <MaskPrice
              field = 'Member Cost black'
              getprefer={getPrefer}
              viewType={viewType}
              fieldVal={order.subCostTotal}
            /> : "-"}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Cancel Code</td>
          <td>{utilities.hyphenForNull(order.cancelReasonCode)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Status</td>
          <td>{utilities.hyphenForNull(order.status)}</td>
        </tr>
        </>}

        <tr>
          {/* <td className='t-left'>
            <i className='created_by'>Created by: {utilities.emptyForNull(order.createdBy)}</i>
          </td> */}
          <td className='t-left tracking-data'>
            **Tracking #: {order && renderTrackingDetails(order)}
          </td>
        </tr>
        <tr>
          <td colSpan = {2} className='t-right'>
            {order.showLess !== false && <p>
              <a className="btn btn-primary" onClick={() => showMore(index, samNumber)}>...show more</a>
            </p>}
            {order.showLess === false && <p>
              <a className="btn btn-primary" onClick={() =>showLess(index, samNumber)}>...show less</a>
            </p>}
          </td>
        </tr>
      </table>
    </div>
  )
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  const loggedInUser = state.SessionReducer.address.userName;
  const rdcNum = state.SessionReducer.rdc;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId, userName, loggedInUser, userId, rdcNum, 
    getprefer:state.preference.listData,
    viewOnly: state.SessionReducer.viewOnly,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : ''
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({getRaCategories}, dispatch)
  );
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AllOrders));