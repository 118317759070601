import React, { Component } from 'react';
import CartProductsTable from './CartProductsTable';
import {
  createCart,
  viewAllCarts,
  deleteCart,
  chooseCart,
  notifyCarts,
  removeProduct,
  editCartName,
  setLoader,
  getViewAllCarts,
  getCartInfo,
  checkoutOrderValidation
} from '../../views/Cart/actions';
import _ from 'lodash';
import { Modal } from 'semantic-ui-react';
import { getFavoriteItems } from '../../views/Favorites/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './styles.css';
import Bin from '../../images/bin.svg';
import renderImages from '../common/RenderImages';
import { Confirm, Loader,Icon, Popup } from 'semantic-ui-react'
import { orderNames } from './constants';
import { utilities, shouldHidePriceTotalPreference } from '../../utils/utilities';
import { Label } from 'semantic-ui-react';
import { MaskPrice } from '../common/MaskPrice';
import { reunionShoppingMetaId } from "../common/constants";
import ExcelExport from "../common/ExcelExport";
import { exportExcel } from "../common/ComponentUtilities";
import { getExcelExport, cartExcelExport } from "../ReunionEvent/AllReunion/viewCartFields";
import { isShoppingAreaCart } from "./constants";
import { EDIT_ORDER_ITEM_DETAILS } from '../../urlConstants'
import restUtils from '../../utils/restUtils';
import { totalCost } from './cartUtils';
import Freight from '../Freight';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCart: props.currentCart,
      newCartName: '',
      cartProducts: null,
      deletionConfirmOpen:false,
      cartToDelete:null,
      editCartId:null,
      editCartName:'',
      carts:props.carts,
      cartDetails: props.cartDetails,
      cartScroll: true,
      isLoading:false,
      showEstimator: false,
      quantites:{},
      qtyFocus: true,
      changesCart:'',
      defaultCart : props.location?.state?.cartId ? props.location?.state?.cartId : props.currentCart.cartId,
      getPrefer: this?.props?.getprefer?.customerView
         ? this.props.getprefer.customerView
         : "c1",
      intialCart: !_.isEmpty(props.currentCart) ? props.currentCart.parentId : props?.carts[0]?.parentId
    };
    this.multiStoreCart = {};
    this.cartSelected = {};
    this.cartRef = {}
    //this.getNotify =  setInterval(this.props.notifyCarts(),1000)
  }

  componentDidMount() { 
    this.props.checkoutOrderValidation(''); 
    const getCartIds = !_.isEmpty(this.getChildCarts()[this.state.currentCart.parentId]) ? this.getChildCarts()[this.state.currentCart.parentId].map(cart=> cart.cartId) : [this.state.currentCart.cartId]
    this.props.setLoader(true);
    this.props.getCartInfo()
    this.props.getViewAllCarts(getCartIds);
    this.props.getFavoriteItems();
    this.props.notifyCarts();
    let timesRun = 0;
    this.interval = setInterval(()=>{
      timesRun += 1;
      this.props.notifyCarts();
      if(timesRun === 4){
          clearInterval(this.interval);
      }
    }, 30000); 
  }
  componentDidUpdate() {
    if(document.getElementById('cart'+this.state.currentCart.cartId) && this.state.cartScroll) {
      window.scrollTo(0, document.getElementById('cart'+this.state.currentCart.cartId).offsetTop-10);
      this.setState({cartScroll: false})
    }
  }
  componentWillReceiveProps(newProps){
    if(newProps.carts!==this.props.carts){
      this.setState({carts:newProps.carts, quantites:this.multiStoreCart })
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  closeConfirmation = () => {
    this.setState({deletionConfirmOpen:false});
  }

  updateStatus = () => {
    const { cartToDelete } = this.state;
      const url = `${EDIT_ORDER_ITEM_DETAILS}/updateOrderStatus`;
      const payload = {
        rdcNum: cartToDelete?.editRdcNumber,
        orderType: cartToDelete?.editOrderType,
        orderNum: cartToDelete?.editOrderNbr,
        shoppingCartID: cartToDelete?.cartId,
        userId: cartToDelete.userId,
        oldCartName:'',
        storeId: this.props.storeId,
        molUserName: this.props.userName,
        isCancelOrder: false,
        status : 'O'
      };  
      restUtils
        .postData(url, payload)
        .then(response => {
        })
        .catch(err => {
          console.log(err);
        });
    }

  handleDeleteCart = (e) =>{
    const cartToDelete = this.props.carts.find(({cartId}) => parseInt(cartId) === parseInt(e.target.id));
    if(!(cartToDelete && cartToDelete.items) || cartToDelete.items.length === 0){
      if(cartToDelete.forEdit  && cartToDelete.forEdit  == 'Y'){
        this.updateStatus()
      }
      utilities.msExist(this.props?.multiStoreDetails) > 1 ?  this.props.deleteCart(parseInt(e.target.id),parseInt(cartToDelete?.parentId ? cartToDelete.parentId : 0),utilities.msExist(this.props?.multiStoreDetails) > 1)
      : this.props.deleteCart(parseInt(e.target.id),0,utilities.msExist(this.props?.multiStoreDetails) > 1);
      this.props.notifyCarts();
    }else{
      this.setState({deletionConfirmOpen:true, cartToDelete});
    }
  }

  deleteCart = () => {
    let parentId = utilities.msExist(this.props?.multiStoreDetails) > 1 ? parseInt(this.state.cartToDelete.parentId) : 0;
    if(this.state.cartToDelete.forEdit  && this.state.cartToDelete.forEdit  == 'Y'){
      this.updateStatus()
    }
    this.props.deleteCart(parseInt(this.state.cartToDelete.cartId), parentId,utilities.msExist(this.props?.multiStoreDetails) > 1);
    this.props.notifyCarts();
    this.closeConfirmation();
  };

  handleNewCartNameChange = e => {
    this.setState({ newCartName: e.target.value });
  };
  getChildCarts = () => {
    const { carts } = this.props
    const getCartId = carts.length > 0 && carts.map(ele=> ele.parentId)
    const cartId = _.uniqWith(getCartId, _.isEqual)
    let cartObj = {}
     cartId.map(ele=> {
       const cart = this.props.carts.filter(cart=> cart.parentId === ele)
         cartObj[ele] = cart
     })   
    return cartObj
  }
  exportCart = (index ,cart) => {
    const  getCartIds = !_.isEmpty(this.getChildCarts()[cart.cartId]) ? this.getChildCarts()[cart.cartId].map(cart=> cart.cartId) : [cart.cartId]
    if(cart.isLoad) {
      this.props.getViewAllCarts(getCartIds,cart.TypeId > 100)
    }
    
  }
  toggleDiv = (index , cart)=> {
    const  getCartIds = !_.isEmpty(this.getChildCarts()[cart.cartId]) ? this.getChildCarts()[cart.cartId].map(cart=> cart.cartId) : [cart.cartId]
    if(cart.isLoad) {
      this.props.getViewAllCarts(getCartIds)
    }
    const icon = document.getElementsByClassName('cartToggleIcon')[index];
    icon.className = icon.classList.contains('minus')
      ? 'plus icon cartToggleIcon'
      : 'minus icon cartToggleIcon';
    const contentDiv = document.getElementById('contents' + index);
    contentDiv.style.display =
      contentDiv.style.display === 'none' ? 'block' : 'none';
      const cartDiv = document.querySelectorAll('.cartToggleIcon')
      const checkExpand  = Object.values(cartDiv).every(ele=> ele.classList.contains('plus'))
      const collaspeDiv = document.querySelector('.collapseAll > i');
      if(checkExpand) {
        collaspeDiv.className = 'plus icon'
      }
      else {
        collaspeDiv.className = 'minus icon'
      }
  };  
  collapse = () => {
    const collaspeDiv = document.querySelector('.collapseAll > i');
    const cartDiv = document.getElementsByClassName('cart-accordion')
    cartDiv.forEach((ele, index)=>{
      ele.className ='cart-accordion'
      const icon = document.getElementsByClassName('cartToggleIcon')[index]
      if(icon !== undefined) {
        icon.className = collaspeDiv.classList.contains('minus')
      ? 'plus icon cartToggleIcon'
      : 'minus icon cartToggleIcon';
      }
     
    })
    
    collaspeDiv.className = collaspeDiv.classList.contains('minus') ? 'plus icon' : 'minus icon';
    const icon = document.getElementsByClassName('order-contents')
    icon.forEach(ele=>{
      ele.style.display = collaspeDiv.classList.contains('minus') ? 'block' : 'none'
    })
    const collaspeParentDiv = document.querySelector('.collapseAll > i').classList.contains('minus')
    if(collaspeParentDiv) {
      this.setState({qtyFocus: false},()=> {
        this.props.carts.map(cart=>{
          const  getCartIds = !_.isEmpty(this.getChildCarts()[cart.cartId]) ? this.getChildCarts()[cart.cartId].map(cart=> cart.cartId) : [cart.cartId]
          if(cart.isLoad) {
            this.props.getViewAllCarts(getCartIds)
          }
        })
      })
    }
  }

  enableCartNameEdit = (e)=> {
    const {cartId, cartName} = e.target.dataset;
    this.setState({editCartId:cartId,editCartName:cartName})
  }

  handleCartNameChange = (e)=> {
    this.setState({
      editCartName:e.target.value,
    })
  }

  handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.updateCartName(e);
    }
  }

  updateCartName = (e)=>{  
    const editCartName = this.state.editCartName;
    
    if(editCartName && editCartName.trim().length === 0){
      utilities.showToast('Cart name cannot be empty!');
      return;
    }  

    if (editCartName.length > 10) {
      utilities.showToast('Cart name cannot exceed 10 Characters');
      return;
    }

    if(editCartName === 'Events'){
      utilities.showToast("'Events' cart name is reserved for Reunion Event orders");
      return;
    }

    if(!utilities.isValidslash(editCartName)){
      utilities.showToast('Cart name should not contain special characters');
      return;
    }
    this.props.editCartName(e.target.id,orderNames['REGULAR'].infoType,editCartName,e.target.name ? e.target.name :0);
    this.setState({editCartId:null,editCartName:''});
  }

  createNewCart = e => {
    const newCartName = this.state.newCartName;
    if (utilities.isEmptyOrNullString(newCartName)) {
      utilities.showToast('Cart name cannot be empty');
      return;
    }
    if (newCartName.length > 10) {
      utilities.showToast('Cart name cannot exceed 10 Characters');
      return;
    }
    if(newCartName === 'Events'){
      utilities.showToast("'Events' cart name is reserved for Reunion Event orders");
      return;
    }
    if(!utilities.isValidslash(newCartName)){
      utilities.showToast('Cart name should not contain special characters');
      return;
    }
    this.props.createCart(newCartName, 'REGULAR');
    this.setState({ currentCart: newCartName, newCartName: '' });
  };
  getSelectedChild = () => {
    const { carts } = this.state
      const getCartId = carts.length > 0 && carts.map(ele=> ele.parentId)
      const cartId = _.uniqWith(getCartId, carts.parentId)
      let cartObj = {}
       cartId.map(ele=> {
         const cart = this.props.carts.filter(cart=> cart.parentId === ele)
           cartObj[ele] = cart
       })   
      return cartObj
  }
  changeCurrentCart = e => {
    this.setState({ currentCart: e.target.value });
    this.props.chooseCart(
      this.props.carts.find(cart => parseInt(cart.cartId) === parseInt(e.target.value))
    );
    let getCartIds = !_.isEmpty(this.getSelectedChild()[e.target.value]) ? this.getSelectedChild()[e.target.value].map(cart=> cart.cartId) : [e.target.value]
    this.props.setLoader(true);
    this.props.getViewAllCarts(getCartIds);
    this.props.getCartInfo();
    this.setState({changesCart: this.props.carts.find(cart => parseInt(cart.cartId) === parseInt(e.target.value)) });
  };

  setCartSelected = (cartSelected) => {
    this.cartSelected = cartSelected;
  }
  updateQuantities = (cartId, itemno, val, name, typeId) => {
    const attributeName= [
      "ItemQty",
      "ItemQty2",
      "ItemQty3",
      "ItemQty4"
    ]
    let qtyKey = 'itemQty'
    let qtyName = 'ItemQty'
    let userQty = {}
    if(typeId > 100 || typeId === 10) {
    let names = [name]
    qtyName = names.find(ele =>attributeName.includes(ele) &&  ele )
    qtyKey = parseInt(attributeName.indexOf(qtyName)) > 0 ? 'userQty'+(attributeName.indexOf(qtyName)+1) : "userQty"
    if(qtyKey === 'userQty') {
      userQty = {
        itemQty: utilities.zeroForNaN(val)
      }
    }
  }  
    else {
      userQty = {
        userQty: utilities.zeroForNaN(val)
      }
    }   
    let allCarts = {}
    for (const [key, carts] of Object.entries(this.state.quantites)) {
      let cartItem = carts.map(cart=> {
        if(parseInt(cart.cartId) === parseInt(cartId)) {
          cart.items = cart.items.length > 0 && cart.items.map(item => {
            const itemNumber = item.item_nbr !==0 ? item.item_nbr : item.number  !==0 ? item.number : item.model
            return itemNumber !== itemno
              ? item
              : {
                  ...item,
                  [qtyKey]: utilities.zeroForNaN(val), 
                  ...userQty            
                };
          })
           return cart
        }
      return cart
      })
      allCarts = {
        ...allCarts,
        [key]: cartItem
      }
    }
   this.setState({quantites: allCarts})
  }

  openEstimatorModel = () =>{
    this.setState({showEstimator:true})
  }
  closeEstimator = () =>{
    this.setState({showEstimator:false})
  }
  render() {
    const {carts, cartToDelete } = this.state;
    const getCartId = carts.length > 0 && carts.map(ele=> ele.parentId)
    const cartId = _.uniqWith(getCartId, _.isEqual)
    let msCart = _.uniqBy(carts, 'parentId');
    let cartObj = {}
     cartId.map(ele=> {
       const cart = carts.filter(cart=> cart.parentId === ele)
         cartObj[ele] = cart
     })
    let multiStoreCartId = '';
    const racartTotals= carts.map(cart=>{
      if(cart.cartName==='Assortment' || cart.cartType === 'ShoppingArea Assortmentflow'){
        var raCart = cart.items.map(item => { 
          return parseFloat((item.userQty ? item.userQty : item.qty)*item.cost) });
          const itemReducer = (item1, item2) =>
         parseFloat(item1) + parseFloat(item2);
           cart.totalCost = parseFloat(
              raCart.reduce(itemReducer, 0) 
           ).toFixed(2); 
        }
      })
    const cartdeleteName = cartToDelete ? `${cartToDelete.cartName}-${cartToDelete.cartId}` : '';
    const cartDeletionName = cartdeleteName ? ' ['+cartdeleteName+']':'';
    let isMultistoreCheck = utilities.msExist(this.props?.multiStoreDetails) > 1;
    const {currentCart } = this.props;
    const isCheckout = this.props.isCheckout ? this.props.isCheckout : false;
      const isConfirmation = this.props.isConfirmation ? this.props.isConfirmation : false;
      const isViewCart = !isCheckout && !isConfirmation;
      const isRetailAssortment = currentCart.TypeId === 4;
      const isEvents = currentCart.TypeId === 9;
      const isPlanogram = currentCart.TypeId === 5;
      const isCloseOutCorner = currentCart.TypeId === 3;
      const isIM = currentCart.TypeId === 7;
      const isMonthlySpecial = currentCart.TypeId === 10;
      const isDOM = currentCart.TypeId === orderNames["DOM"].infoType;
      const tableType = isCheckout
                    ? isRetailAssortment
                      ? 'ra-checkout'
                      : isEvents || isMonthlySpecial
                      ? 'event-checkout'
                      : isPlanogram
                      ? 'pl-checkout'
                      : isIM
                      ? 'im-checkout'
                      : isDOM
                      ? 'dom-checkout'
                      : 'checkout'
                    : isConfirmation
                    ? isRetailAssortment
                      ? 'ra-confirmation'
                      : isMonthlySpecial
                      ? 'ms-confirmation'
                      : isDOM
                      ? 'dom-confirmation'
                      : 'confirmation'
                    : isRetailAssortment
                    ? 'ra-cart'
                    : isIM
                    ? 'im-cart'
                    : isEvents || isMonthlySpecial
                    ? 'event-cart'
                    : isDOM
                    ? 'dom-cart'
                    : 'viewCart';
    const maskAndHideTotalCost  = shouldHidePriceTotalPreference(tableType,this.props.viewType,this.props.getprefer.customerView);
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    return (
      <div className='page-wrap'>
        <div className='page-header'>Cart</div>
        <button class="ui red button" style={{float:"right"}} onClick={this.openEstimatorModel}>LTL Freight Estimator</button>
        <Modal closeIcon
        className='EstimatorPopup'
        onClose = {this.closeEstimator}
        closeOnDimmerClick={false}
        open={this.state.showEstimator}>
        <Modal.Content className=''>
            <Freight />
        </Modal.Content>
      </Modal>
        <div className='info-text row'>
          Create a new order or expand an order from the list below
        </div>
        <div>
          <div className='create-cart'>
            <label className='select-cart-label'> Select Cart</label>
            <select
              className='select-cart-combo'
              value={this.props.currentCart && parseInt(this.state.currentCart?.parentId?this.state.currentCart?.parentId: this.state.currentCart?.cartId?this.state.currentCart?.cartId : this.state.changesCart.cartId)}
              onChange={this.changeCurrentCart}>
              {
              isMultistoreCheck?
              msCart.map(shpcart => {
                return <option value={shpcart.cartId}>{shpcart.cartName==='M4Success'?'VISMERCH':shpcart.cartName}-{shpcart.cartId}</option>;
            })
                :           
              carts.map(shpcart => {
                  return <option value={shpcart.cartId}>{shpcart.cartName==='M4Success'?'VISMERCH':shpcart.cartName}-{shpcart.cartId}</option>;
              })
              }
            </select> &nbsp;
            <div className='new-cart-div'>
              <input
                type='text'
                className='new-cart-input'
                value={this.state.newCartName}
                onChange={this.handleNewCartNameChange}
                placeholder='Enter Cart Name...'></input>
              <button className='create-cart-btn' onClick={this.createNewCart}>
                Create New Cart
              </button> (Cart Id will be appended to cart name)
            </div>
          </div>
        </div>
        {
          this.props.isFetchingData?
            <Loader active/>
            :
            <div className='orders'>
             {carts&& carts.length > 0 && (<span className="collapseAll" onClick={this.collapse}><i className={!utilities.isMobileTab() ? 'minus icon' :'plus icon'}></i></span>)}
              {carts &&
                carts.map((cart, index) => {
                  const isWow = cart?.items?.length && cart.items.length>0 ? cart.items[0].isReqFromWow:false
                  const metaId = cart?.shoppingAreaMetaId
                  let displayName = cart?.DisplayName ? 
                                        isWow?
                                          "WOW"
                                          :cart?.DisplayName 
                                        : orderNames[cart.cartInfoName]?.displayName;
                  displayName = reunionShoppingMetaId[metaId] ? reunionShoppingMetaId[metaId] : displayName ;
                  let newCartType = index === 0 || carts[index - 1].parentId !== cart.parentId;
                  let isMultiStoreNewCartType = false;
                  let newCartTypeExist=true;
                  const isMultistore = utilities.msExist(this.props?.multiStoreDetails) > 1;
                  if(newCartType){
                    multiStoreCartId = cart.parentId;
                  }
                  if(isMultistore && newCartType){
                    this.multiStoreCart[multiStoreCartId] = [cart];
                    let msParantexist = carts && carts.filter((newcart, index) => {
                     return newcart.parentId === cart.parentId
                    });
                    newCartTypeExist = msParantexist.some(someExist=>(someExist.items.length > 0))
                    isMultiStoreNewCartType = true;
                  }else if(isMultistore){
                      let addCart = this.multiStoreCart[multiStoreCartId];
                      addCart.push(cart);
                      this.multiStoreCart[multiStoreCartId] = addCart;
                  }
                  else {
                    this.multiStoreCart[cart.cartId] = [cart]
                  }
                  this.cartRef[cart.cartId] = React.createRef()
                  return (
                    <div key={this.cartRef[cart.cartId]} ref = {this.cartRef[cart.cartId]} className='cart-accordion viewCartTable' id={'cart'+cart.cartId}>
                      <div className='accordion-header'>
                      {(parseInt(cart.TypeId)===1 && (!isMultistore
                        || (isMultistore  && newCartType))) && cart.forEdit != 'Y' &&
                        <Popup
                        trigger={<i className='cart-name-edit fas fa-pencil' 
                        data-cart-id={cart.cartId} 
                        data-cart-name = {cart.cartName}
                        onClick={this.enableCartNameEdit}></i>}
                        content="Edit Cart Name"
                        size='mini'
                        position='top right'
                        basic
                        /> 
                        }
                        <span className='cart-name'>
                            {
                              (this.state.editCartId && parseInt(this.state.editCartId)===parseInt(cart.cartId))
                            ?
                            <>
                              <input type = "text" 
                              className='edit-cart-name'
                              id={cart.cartId}
                              name={cart.parentId}
                              onChange = {this.handleCartNameChange} 
                              onKeyDown = {this.handleEnterKey}
                              value={this.state.editCartName} 
                              onBlur={this.updateCartName}
                              maxLength = {10}
                              />
                              <span>-{cart.cartId}</span>
                              </>
                            :
                            (!isMultistore || newCartType) ?
                            <span>{cart.cartName==='M4Success'?'VISMERCH':cart.cartName}-{cart.cartId}</span>
                            : <span className='inline-block'></span>
                          }
                          
                        </span>
                        {
                          cart.forEdit == 'Y' && <span>&nbsp;[Order In Edit Mode]</span>
                        }
                        <span className='cart-accordion-header'>
                        {cart.notification === '1' &&
                          <Label
                            id = 'iconWarning'
                            size='tiny'
                            color='yellow'
                            circular
                            content={<i style={{ color: 'black', fontSize:'16px'}}>!</i>}/>}
                          {displayName}
                          - {cart.items.length} Item(s) &nbsp;
                          {!cart?.isLoad && (
                          maskAndHideTotalCost ? 
                            <>
                            {utilities.getLabelsByType(this.props.viewType,"TOTAL", getPrefer)} &nbsp;
                            {<MaskPrice 
                            getprefer={this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                            viewType={tableType==='dom-cart' ? 'Member View' : this.props.viewType}
                            field='Member Cost black'
                            fieldVal= {cart.cartName !== "Events" 
                                        ? (totalCost(cart)) 
                                        : carts.totalCost} 
                          />}
                            </> : ""
                            
                          )}                          
                        </span>
                        {cart.items.length > 0 && (!isMultistore || newCartType) && (!isMultistore || cartObj[cart.parentId].length > 1)  && (
                        cart.TypeId >=100 ? (
                           exportExcel({
                             className: "exportCart",
                              onClick : () => !cart?.isLoad ? cartExcelExport(
                                cartObj[cart.parentId],
                                this.state.getPrefer,
                                this.props.viewType,
                                this.props?.aliasSKURetailerGrpID,
                                this.props?.marketFlag,
                                this.props?.marketDate,
                                this.props?.storeId,
                                this.props.address?.storeName)  
                                 : this.exportCart(index, cart),
                                
                                                        
                           })
                         ) : (
                               <ExcelExport
                             className="exportCart"
                             fileName={"cartExport"}
                             viewType={this.props.viewType}
                             status={'Cart'}
                             order={cartObj[cart.parentId]}
                             RAMarketFlag={this.props.RAMarketFlag}
                             RAMarketDate={this.props.RAMarketDate}
                             aliasSKURetailerGrpID={this.props?.aliasSKURetailerGrpID}
                             getprefer={this.state.getPrefer}
                             multiStoreDetails = {this.props?.multiStoreDetails}
                            loadData = {this.exportCart}
                            multiStoreCart = {true}
                            index = {index}
                            cart = {cart}                       
                            storeId={this.props.storeId}    
                            storeName={this.props.address?.storeName}
                            favorites= {this.props.favorites}
                            
                           />
                         ))}
                        <i className={parseInt(this.state.currentCart?.parentId?this.state.currentCart?.parentId: this.state.currentCart?.cartId?this.state.currentCart?.cartId : this.state.changesCart.cartId) === parseInt(cart.parentId ?cart.parentId: cart.cartId) ? 'minus icon cartToggleIcon' : 'plus icon cartToggleIcon'}
                          onClick={() => this.toggleDiv(index, cart)}></i>
                        {((isMultistore  && newCartType) || !isMultistore)  &&
                          <Popup
                          trigger={ renderImages.getImage({
                            src: Bin,
                            alt: 'bin',
                            className: 'delete-cart-icon',
                            id :cart.cartId,
                            onClick: this.handleDeleteCart})}
                          content="Remove"
                          size='mini'
                          position='top right'
                          basic
                        />
                        }
                      </div>
                      <div id={'contents' + index} className='order-contents' style={{'display':parseInt(this.state.currentCart?.parentId?this.state.currentCart?.parentId: this.state.currentCart?.cartId?this.state.currentCart?.cartId : this.state.changesCart.cartId) === parseInt(cart.parentId ?cart.parentId: cart.cartId) ? 'block': 'none'}}>
                        {cart.items.length > 0 || isMultiStoreNewCartType ? (
                          <div>
                            {parseInt(cart.TypeId)===5 && (!isMultistore || (isMultistore && newCartType)) &&
                              <span>{cart.productName}</span>//for planograms
                            }
                            {!cart?.isLoad && (
                              <CartProductsTable
                              cartTotalcost={carts.totalCost}
                              newCartTypeExist={newCartTypeExist}
                              cart={cart}
                              qtyFocus= {this.state.qtyFocus}
                              products={cart.items}
                              removeItem={this.props.removeProduct}
                              cartType = {newCartType}
                              multiStoreCartId = {multiStoreCartId}
                              getMultiStoreCart = {this.multiStoreCart}
                              getCartSelected = {this.cartSelected}
                              setCartSelected = {this.setCartSelected}
                              updateQuantities = {this.updateQuantities}
                              updatedCart = {this.state.quantites}
                            />  
                            )}                       
                          </div>
                        ) : parseInt(cart.TypeId)===2 && ! this.props.isFetchingData ? <span className='no-promotion-msg'>There are no items in this order.  
                            This may be because this promotion has expired.
                            If it has expired please delete this cart.</span>
                          : parseInt(cart.TypeId) > 100 &&  !this.props.isFetchingData ? <span className='no-promotion-msg'>There are no items in this order.
                            This may be because this event has expired. If it has expired, please delete this cart.</span>
                          : parseInt(cart.TypeId)===4 && this.props.raMarketFlag &&  !this.props.isFetchingData &&
                          <span className='no-promotion-msg'>There are no assortments in this order.  
                            This may be because either no assortments in this cart or assortments have expired.</span> 
                        }
                      </div>
                    </div>
                  );
                })}
                <Confirm
                  className='cart-confirm'
                  open = {this.state.deletionConfirmOpen}
                  onCancel = {this.closeConfirmation}
                  header='Delete Cart'
                  content={'This cart has items. Are you sure you want to delete the cart?'+cartDeletionName}
                  onConfirm = {this.deleteCart}
                />
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  let orders = state.cart.orders;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    getprefer:state.preference.listData,
    isFetchingData:orders.isFetchingData,
    currentCart: orders.currentCart,
    currentOrderType: orders.currentOrderType,
    cartDetails: orders.getAllCarts || [],
    carts: orders.allCarts,
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    raMarketFlag: orders.raMarketFlag,
    marketDate: orders.eventDate,
    multiStoreDetails:state.SessionReducer?.multiStoreDetails,
    address: state.SessionReducer.address,
    favorites: state.FavoritesReducer.favItemNums,
    userName: state.SessionReducer.userName,
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ 
        createCart, 
        deleteCart, 
        chooseCart, 
        notifyCarts, 
        removeProduct, 
        viewAllCarts, 
        setLoader, 
        editCartName, 
        getFavoriteItems ,
        getViewAllCarts,
        getCartInfo,
        checkoutOrderValidation
      },
      dispatch
    )
  );
};

export default connect(mapStateToProps,mapDispatchToProps)(Cart);
