import { SHIP_LATER_API } from '../../urlConstants';
import restUtils from '../../utils/restUtils';
export const OMS_SHIP_LATER_RECEIVED = 'SHIP_LATER_RECEIVED';
export const OMS_UPDATE_SHIP_LATER_SORT_ORDER = 'UPDATE_SHIP_LATER_SORT_ORDER';


const receiveOmsShipLaterItems = response => ({
  type: OMS_SHIP_LATER_RECEIVED,
  payload: { ...response }
});

export const setSortAndOrder = SortAndOrder => dispatch =>
  dispatch({
    type: OMS_UPDATE_SHIP_LATER_SORT_ORDER,
    payload: { ...SortAndOrder }
  });

export const getOMSShipLaterItems = (data, reqObj, handleShipLaters,handleBackButtonChanges) => dispatch => {
  // let createPromise = restUtils
  //   .postData(
  //     `${SHIP_LATER_API}/PostDisplayShipLaterItemsList`,
  //     JSON.stringify(reqObj)
  //   )
  //   .then(res => {
  //     const { data = {} } = res;
      // const {
      //   filters,
      //   shipLaterItemList,
      //   excludesAmmunition,
      //   includesAmmunition
      // } = data;
      
      const shipLaterItemList = data;
      const filters = [];
      const excludesAmmunition = [];
      const includesAmmunition = [];
      if (handleShipLaters) {
        handleShipLaters(!(shipLaterItemList && shipLaterItemList.length));
      }
     return  dispatch(
        receiveOmsShipLaterItems({
          filters,
          shipLaterItemList,
          excludesAmmunition,
          includesAmmunition
        })
      );
    // })
    // .catch(() => {
    //   return dispatch(receiveOmsShipLaterItems({}));
    // });
  //   createPromise.then(() => {
  //     handleBackButtonChanges();
  // });
};
