import React from 'react';
import restUtils from '../../utils/restUtils';
import "./OrderCss.css";
import { connect } from 'react-redux';
import utilities, { shouldHideSUGG_RETAILForPreference, shouldHideCostForPreference } from '../../utils/utilities';
import { GET_ORDER_DETAILS, GET_FULFILLMENT_LIST, GET_FULFILLMENT_DETAILS, UPS_URL, FEDEX_URL } from '../../urlConstants';
import { togglePanel, fetchData } from './actions';
class Panel extends React.Component {
  componentDidMount (){
    const { isPanelOpen, panelId,index, data, fulfillmentNumber, loading, fetchData } = this.props;
     
if(isPanelOpen && !data) {      
      fetchData(panelId, fulfillmentNumber); }
  }
  componentDidUpdate(prevProps) {
    const { isPanelOpen, panelId, data, fulfillmentNumber, loading, fetchData } = this.props;
    if (isPanelOpen && !prevProps.isPanelOpen && !data && !loading ) {
      fetchData(panelId, fulfillmentNumber);
    }
  }
  render() {
    const { isPanelOpen, togglePanel, loading, item, data, error, panelId } = this.props;
    console.log("is Open", panelId, isPanelOpen)
    return (
      <div className="orderPanel panel">
        <div className="panel-header" onClick={() => togglePanel(panelId)}>
          <h2>{item.fulfillmentRequestNumber}</h2>
          <h2>Site : {item.fulfillmentSite}</h2>
          {/* <h2>Total Units: {item.quanity}</h2> */}
          <h2>Statuses: {item.fulfillmentRequestStatus}</h2>
          <span className='tabIcons'>{isPanelOpen ? '-' : '+'}</span>
        </div>
        {isPanelOpen && (
          <div className="panel-content">
            <div className='leftPanelContent'>
              <p>Tracking Numbers : {item?.masterTrackingNumber ? item?.masterTrackingNumber : "Not Available"}</p>
              <p>Carrier : {item.carrierDetails.carrier}</p>
              <p>Carrier Service : {item.carrierDetails.serviceType}</p>
              <p>Release Date : {utilities.getDateFromTimeStampShip(item.fulfillmentRequestDate)}</p>
              <p>Promise Date : {utilities.getDateFromTimeStampShip(item.promisedDeliveryDate)}</p>
              <p>Expected Delivety Date : {utilities.getDateFromTimeStampShip(item.dueDate)} </p>
            </div>
            <div className='rightPanelContent'>
              <div className='innerLineItemTable'>
                {data && data.lineItems.length > 0 ?
                  <>
                    <table className="ui celled table all-wh-table" id='font-size-orders'>
                      <thead>
                        <tr>
                          <th>Line#</th>
                          <th>Item Image</th>
                          <th>Item Number</th>
                          <th>Item Description</th>
                          <th>Line Status</th>
                          <th>Unit</th>
                          <th>Unit Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.lineItems.map((lineData, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td><img src={`https://images.truevalue.com/getimage.asp?ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2&id=${lineData.itemId}`} className="orderImage" /></td>
                            <td>{lineData.itemId}</td>
                            <td>{lineData.itemDescription}</td>
                            <td>{lineData.status}</td>
                            <td>{lineData.itemQty}</td>
                            <td>${lineData.itemUnitPrice}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </> : ""
                }

              </div>

            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const panelState = state.panelReducer.panels[ownProps.panelId] || {};
  return {
    isPanelOpen: panelState.isOpen,
    loading: panelState.loading,
    data: panelState.data,
    error: panelState.error,
  };
};

const mapDispatchToProps = {
  togglePanel,
  fetchData,
};
export default connect(mapStateToProps, mapDispatchToProps)(Panel);
