import React, { Component, createRef } from 'react';
import classnames from 'classnames';
import './styles.css';

class Table extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectAll: false
    }
    this.itemRef = {};
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.data !== nextProps.data) {
       this.itemRef = {};
    }
  }
  updateItemRef = e => {
    this.itemRef[e] = React.createRef()
  }
  handleCellAction = event => {
    const { dataset } = event.target;
    const { action, rowindex } = dataset;
    if (action) {
      switch (action) {
        case 'Remove':
          if (this.props.onAction) {
            this.props.onAction(event, { rowindex, action });
          }
          break;
        default:
          break;
      }
    }
  };
  handleSelectAll = e => {
    for (let index in this.itemRef) {
      this.itemRef[index].current.checked = !this.state.selectAll
    }
    this.setState({selectAll: !this.state.selectAll}, ()=> {this.props.onMultiSelectAction(this.state.selectAll)})
    
 
  }
  render() {
    const { colDefs = [], className, data, shipLaters = 'N', OMSShipLaters = 'N' } = this.props;
    return (
      <table role='table' className={shipLaters === 'Y' ? className : classnames(className, 'data-grid')}>
        <THead colDefs={colDefs} shipLaters = {shipLaters} onAction = {this.handleSelectAll}/>
        <TBody
          colDefs={colDefs}
          data={[...data]}
          onAction={this.handleCellAction}
          shipLaters = {shipLaters}
          OMSShipLaters = {OMSShipLaters}
          itemRefAction = {this.updateItemRef}
          itemRef = {this.itemRef}
        />
      </table>
    );
  }
}

export default Table;

export const THead = props => {
  const { colDefs = [], onAction, shipLaters = 'N' . OMSShipLaters = 'N'} = props;
  return (
    <thead>
      <tr role='row'>
        {colDefs.map((colDef, index) => {
          const actionDetails = colDef.key.split(':');
          if (actionDetails && actionDetails.length && actionDetails[0] === 'short_desc')  {
            return (
              <th style={{'textAlign': 'left'}}> <span>Item Description</span></th>)
          }
          if (actionDetails && actionDetails.length && actionDetails[0] === 'action')  {
            return (
              <th> <span>Remove</span></th>)
          }
          return(
          <th
            role='columnheader'
            className={shipLaters === 'Y' && colDef.header === 'Invoice #' && ('shiplaterHeader') }
            key={colDef.key || colDef.heading || colDef.header|| index}
            dangerouslySetInnerHTML={{ __html: (colDef.heading || colDef.header) }}
          />)
})}
      </tr>
    </thead>
  );
};

export const TBody = props => {
  const { colDefs, data, onAction, shipLaters, OMSShipLaters, itemRefAction, itemRef} = props;
  return (
    <tbody>
      {data.map((rowData, index) => {
        if(shipLaters) {
          itemRefAction(index)
        }
        if(OMSShipLaters) {
          itemRefAction(index)
        }
          return (
            <Row
              rowData={rowData}
              colDefs={colDefs}
              key={rowData.id || index}
              onAction={onAction}
              rowIndex={index}
              shipLaters={shipLaters}
              OMSShipLaters ={OMSShipLaters}
              itemRefAction = {itemRefAction}
              itemRef = {itemRef}
            />
          )
      })}
    </tbody>
  );
};

export const Row = props => {
  const { rowData, colDefs, onAction, rowIndex, shipLaters, OMSShipLaters,itemRef} = props;
  return (
    <>
    <tr role='row' className = {shipLaters === 'Y' ? 'product-row' : ''}>
      {colDefs.map((def, index) => (
        <Cell
          def={def}
          data={rowData}
          key={def.key || def.heading || index}
          rowIndex={rowIndex}
          onAction={onAction}
          itemRef = {itemRef[rowIndex]}
          shipLaters ={shipLaters}
          OMSShipLaters = {OMSShipLaters}
        />
      ))}
    </tr>
    {/*shipLaters === 'Y' &&
      <tr className='even-row'>
        <td colSpan={colDefs.length} className='t-left' >
          <i className='addedBy'>
          {`Added By: ${rowData.addedBy ? rowData.addedBy : ''}`}
          </i>
        </td>
      </tr>
      */}
    </>
  );
};
export const Cell = props => {
  const { def, data, onAction, rowIndex, itemRef, shipLaters,OMSShipLaters } = props;
  const { key, cellRenderer } = def;
  const inlineDataKeys = key.split('+');
  const blockDataKeys = key.split('/');
  const actionDetails = key.split(':');
  const combineDataKeys = key.split(',');
  if (actionDetails && actionDetails.length && actionDetails[0] === 'action') {
    if (shipLaters==='Y') {
     return <td
        role='cell'
        className='action-cell'
      >
        <input type="checkbox"
        data-action={actionDetails[1]}
        checked={data.selection}
        onClick={onAction}
        ref = {itemRef}
        data-rowindex={data.item_no+'#'+data.seq_number}></input>
      </td>
    }
    if (OMSShipLaters==='Y') {
      return <td
         role='cell'
         className='action-cell'
       >
         <input type="checkbox"
         data-action={actionDetails[1]}
         checked={data.selection}
         onClick={onAction}
         ref = {itemRef}
         data-rowindex={data.lineItemId+'#'+data.koctOrderNbr}></input>
       </td>
     }
    return (
      <td
        role='cell'
        onClick={onAction}
        data-rowindex={rowIndex}
        data-action={actionDetails[1]}
        className='action-cell'
      >
        {actionDetails[1]}
      </td>
    );
  }
  if (inlineDataKeys && inlineDataKeys.length > 1) {
    const cellData = getData(inlineDataKeys, data);
    return <td role='cell'>{cellData}</td>;
  }

  if (blockDataKeys && blockDataKeys.length > 1) {
    const cellData = getData(blockDataKeys, data, 'block');
    return <td role='cell'>{cellData}</td>;
  }
  if (combineDataKeys && combineDataKeys.length > 1) {
    return <td role='cell'>{cellRenderer(getCombineData(combineDataKeys, data), key, rowIndex)}</td>;
  }

  if (cellRenderer) {
    return <td role='cell'>{cellRenderer(data[key], key, rowIndex)}</td>;
  }
  return <td role='cell'>{data[key]}</td>;
};

export const getCombineData = (keys, data) => {
  let _data = [];
    keys.forEach(key => {
      _data.push(data[key]);
    });
    return _data;
  }

export const getData = (keys, data, separator) => {
  let _data = '';
  if (separator) {
    _data = keys.map(key => (
      <span className='blocked-span' key={key}>
        {data[key]}
      </span>
    ));
  } else {
    keys.forEach(key => {
      _data += `${data[key]}${separator || ''}`;
    });
  }

  return _data;
};
